let district = [
    {"x":-1,"y":-1,"type":"right","opts":"start", "pickup": false},
    {"x":-3,"y":-2,"type":"righttoleft"},
    {"x":-3,"y":-3,"type":"lefttop"},
    {"x":-4,"y":-4,"type":"top"},
    {"x":-5,"y":-5,"type":"topright"},
    {"x":-6,"y":-5,"type":"right"},
    {"x":-7,"y":-5,"type":"right"},
    {"x":-8,"y":-5,"type":"righttop"},
    {"x":-9,"y":-6,"type":"top"},
    {"x":-10,"y":-7,"type":"topleft"},
    {"x":-10,"y":-8,"type":"left"},
    {"x":-10,"y":-9,"type":"left"},
    {"x":-10,"y":-10,"type":"left"},
    {"x":-10,"y":-11,"type":"voidleft"},
    {"x":-10,"y":-12,"type":"left"},
    {"x":-10,"y":-13,"type":"left"},
    {"x":-10,"y":-14,"type":"voidleft"},
    {"x":-11,"y":-16,"type":"lefttoright"},
    {"x":-20,"y":-20,"type":"plateforme", "pickup": false},

    /* Fake path */
    { "x": -16, "y": -11, "type": "left", "pickup": false }, 
    //{ "x": -16, "y": -23, "type": "leftbottorightbot", "pickup": false },
    { "x": -18, "y": -9, "type": "right", "pickup": false },
    { "x": -19, "y": -9, "type": "right", "pickup": false },
    { "x": -16, "y": -11, "type": "left", "pickup": false }, 
    { "x": -16, "y": -11, "type": "left", "pickup": false }, 

    { "x": -16, "y": -21, "type": "left", "pickup": false },
    { "x": -16, "y": -22, "type": "left", "pickup": false },
    { "x": -16, "y": -23, "type": "left", "pickup": false },
    { "x": -16, "y": -24, "type": "left", "pickup": false },
    { "x": -16, "y": -25, "type": "left", "pickup": false },
    { "x": -16, "y": -26, "type": "left", "pickup": false },
    { "x": -17, "y": -10, "type": "lefttoptorighttop", "pickup": false },
    // { "x": -14, "y": -23, "type": "left", "pickup": false },
    // { "x": -13, "y": -23, "type": "left", "pickup": false },
    /* */

    {
        "x": -29,
        "y": -29,
        "type": "arcdetriomphe",
        "move": false,
        "pickup": false
    },
    {"x":-21,"y":-16,"type":"right", "pickup": false},
    {"x":-23,"y":-17,"type":"righttoleft"},
    {"x":-23,"y":-18,"type":"voidleft"},
    {"x":-23,"y":-19,"type":"left"},
    {"x":-23,"y":-20,"type":"left"},
    {"x":-23,"y":-21,"type":"left"},
    {"x":-24,"y":-23,"type":"lefttoright"},
    {"x":-25,"y":-23,"type":"right"},
    {"x":-27,"y":-24,"type":"righttoleft"},
    {"x":-27,"y":-25,"type":"left"},
    {"x":-27,"y":-26,"type":"left"},
    {"x":-27,"y":-27,"type":"left"},
    {"x":-27,"y":-28,"type":"voidleft"},
    {"x":-27,"y":-29,"type":"left"},
    {"x":-28,"y":-31,"type":"lefttoright"},
    {"x":-29,"y":-31,"type":"right"},
    {"x":-30,"y":-31,"type":"righttop"},
    {"x":-31,"y":-32,"type":"topright", "pickup": false},
    {"x":-32,"y":-32,"type":"right","opts":"end", "pickup": false}];

export default district;