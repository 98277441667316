import { FiktivEngine, FiktivObject3D, update_input_type } from "../../../../fiktivengine_modules/fiktivengine-core";
import { Tiles } from "./Tiles";
import * as THREE from "three";
import { Lendless } from "../../level/Lendless";

export class LevelList extends FiktivObject3D {
    private fe?: FiktivEngine;

    public step: number;
    public current: number;
    public previous: Tiles;
    public actual: Tiles;
    public next: Tiles;
    public nextnext?: Tiles;
    public nextnextnext?: Tiles;
    public cognac?: number;
    public continue: boolean = true;

    constructor(
        fe: FiktivEngine | undefined,
        city: string
    ) {
        super();

        this.fe = fe;
        this.step = -3;
        this.current = 0;
        this.previous = new Tiles(this.fe, new THREE.Vector3(30, 30, -1), this.current - 1);
        this.actual = new Tiles(this.fe, new THREE.Vector3(-1, -1, -1), this.current++);
        this.next = new Tiles(this.fe, new THREE.Vector3(-32, -32, -1), this.current++);

        for (let i = 0; i < this.previous.map.length; i++) {
            this.previous.loadNext();
        }
        for (let i = 0; i < this.actual.map.length; i++) {
            this.actual.loadNext();
        }
        for (let i = 0; i < this.next.map.length; i++) {
            this.next.loadNext();
        }

        this.fe?.scene.add(this.previous.spriteContainer);
        this.fe?.scene.add(this.actual.spriteContainer);
        this.fe?.scene.add(this.next.spriteContainer);

        this.fe?.scene.add(this.actual.decors);
        this.fe?.scene.add(this.next.decors);

        this.update = (args: update_input_type) => {
            let progress = this.step * 100 / this.actual.map.length;

            if (this.step === 0) {
                this.actual.updatePath();
            }
            if (progress > this.next.tilesLoaded * 100 / this.next.map.length) {
                this.next.loadNext()
            }
            if (this.step > this.actual.path.length - 3) {
                console.log("New district : ", this.current)
                this.step = 1

                // Actual become previous
                this.previous.unload();
                this.previous = this.actual;
                this.actual = this.next;
                if (!this.nextnext && this.continue) {;
                    this.next = new Tiles(this.fe, new THREE.Vector3(-32 - 31 * (this.current - 1), -32 - 31 * (this.current - 1), -1), this.current++, undefined, this.cognac !== undefined ? this.cognac++ : undefined);
                } else if (this.nextnext) {
                    this.next = this.nextnext;
                    this.nextnext = this.nextnextnext;
                    this.nextnextnext = undefined;
                    this.cognac = 1;
                }

                this.fe?.scene.add(this.next.spriteContainer);

                this.actual.updatePath();
            }

            for (let pickup of this.actual.pickups) {
                pickup.updateLocal(args);
            }
            for (let pickup of this.next.pickups) {
                pickup.updateLocal(args);
            }
        }
    }

    unload() {
        this.previous.unload();
        this.actual.unload();
        this.next.unload();
        this.nextnext?.unload();
        this.nextnextnext?.unload();
    }
}