import { FiktivObject3D } from "../../../../fiktivengine_modules/fiktivengine-core";

/*
ressources : {
    "name" : {
        "dispo" : [
            ressource1,
            ressource2,
            ressource4
        ],
        "undispo" : [
            ressource3,
            ressource5
        ]
    },
    ...
}
*/


export class PoolManager extends FiktivObject3D {
    private ressources: Map<string, any>

    constructor() {
        super();

        this.ressources = new Map<string, any>();
    }

    createPool = (name: string) => {
        if (!this.ressources.has(name)) {
            this.ressources.set(name, new Map<string, Array<any>>());
            this.ressources.get(name).set("dispo", []);
            this.ressources.get(name).set("undispo", []);

            return this.getEntirePool(name);
        } else {
            return null;
        }
    }

    pushInPool = (namePool: string, ressource: any, dispo: boolean = true) => {
        if (dispo) {
            this.ressources.get(namePool).get("dispo").push(ressource);
        } else {
            this.ressources.get(namePool).get("undispo").push(ressource);
        }
        return ressource;
    }

    removeFromPool = (namePool: string, ressource: any, dispo?: boolean) => {
        if (dispo === true) {
            let deepPool = this.ressources.get(namePool).get("dispo");
            return this.ressources.get(namePool).get("dispo").splice(deepPool.indexOf(ressource), 1)[0];
        } else if (dispo === false) {
            let deepPool = this.ressources.get(namePool).get("undispo");
            return this.ressources.get(namePool).get("undispo").splice(deepPool.indexOf(ressource), 1)[0];
        } else {
            let deepPool = this.ressources.get(namePool).get("dispo");
            let removed = this.ressources.get(namePool).get("dispo").splice(deepPool.indexOf(ressource), 1)[0];
            if (!removed) {
                deepPool = this.ressources.get(namePool).get("undispo");
                removed = this.ressources.get(namePool).get("undispo").splice(deepPool.indexOf(ressource), 1)[0];
            }
            return removed;
        }
    }

    changeStatus = (namePool: string, ressource?: any, dispo?: boolean) => {
        if (dispo === true) {
            let deepPool = this.ressources.get(namePool).get("dispo");
            let removed = ressource !== undefined ? this.ressources.get(namePool).get("dispo").splice(deepPool.indexOf(ressource), 1)[0] : this.ressources.get(namePool).get("dispo").splice(0, 1)[0];

            if (removed) {
                this.pushInPool(namePool, removed, false);
                return removed;
            }
            return null;
        } else if (dispo === false) {
            let deepPool = this.ressources.get(namePool).get("undispo");
            let removed = ressource !== undefined ? this.ressources.get(namePool).get("undispo").splice(deepPool.indexOf(ressource), 1)[0] : this.ressources.get(namePool).get("undispo").splice(0, 1)[0];
            if (removed) {
                this.pushInPool(namePool, removed, true);
                return removed;
            }
            return null;
        } else {
            let deepPool = this.ressources.get(namePool).get("dispo");
            let removed = ressource !== undefined ? this.ressources.get(namePool).get("dispo").splice(deepPool.indexOf(ressource), 1)[0] : this.ressources.get(namePool).get("dispo").splice(0, 1)[0];
            if (removed) {
                this.pushInPool(namePool, removed, false);
                return removed;
            } else {
                deepPool = this.ressources.get(namePool).get("undispo");
                let removed = (ressource !== undefined) ? this.ressources.get(namePool).get("undispo").splice(deepPool.indexOf(ressource), 1)[0] : this.ressources.get(namePool).get("undispo").splice(0, 1)[0];
                if (removed) {
                    this.pushInPool(namePool, removed, true);
                    return removed;
                }
            }
            return null;
        }
    }

    getEntirePool = (namePool: string) => {
        return this.ressources.get(namePool);
    }

    getAll = () => {
        return this.ressources;
    }

    freedom = () => {
        for (let i of this.ressources.entries()) {
            while (i[1] && i[1].length > 0) {
                this.changeStatus(i[0], undefined, false);
            }
        }
    }
}