import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { AnimatedSprite } from "..";
import { FiktivEngine } from "../../../../fiktivengine_modules/fiktivengine-core";
import { tilesTypes } from "../../data/tilesTypes";
import { Lendless } from "../../level/Lendless";

export class Preload {
    private fe?: FiktivEngine;

    public assetList: any;
	public total: number;

    constructor(
        assetList: any,
        fe?: FiktivEngine | undefined
    ) {
        this.fe = fe;
        this.assetList = assetList;
		this.total = 0;

        this.download();
    }

    updateLoading = () => {
        let total = 0;
        for (let a of this.assetList) {
            if (a.ready) {
                total++
            }
        }
        let percent = Math.round((total / this.assetList.length) * 100);

        let element = document.getElementById("loadingPercent");

        if (element) {
            element.innerHTML = percent + " %"
        }

    }

    download = () => {
        let fileLoader = new THREE.FileLoader();
        let fontLoader = new THREE.FontLoader();
        let audioLoader = new THREE.AudioLoader();
        let textureLoader = new THREE.TextureLoader();
        let gltfLoader = new GLTFLoader();

        let _this = this;

		let getSize = function(index: number){
			var xhr = new XMLHttpRequest();
			xhr.open("HEAD", _this.assetList[index].url, true);
			xhr.onreadystatechange = function() {
				if (this.readyState == this.DONE) {
					let size = xhr.getResponseHeader("Content-Length");
					if(size){
						_this.assetList[index].size = parseInt(size);
						_this.total += parseInt(size);
					}else{
						_this.assetList[index].size = 0;
					}
				}
			};
			xhr.send();
		}

        for (let i = 0; i < this.assetList.length; i++) {
            if (this.assetList[i].url === '') {
                this.assetList[i].ready = true;
				this.assetList[i].size = 0;
            }else{
				getSize(i);
			}
            switch (this.assetList[i].target) {
                case 'loading':
                case 'rules':
				case 'utils':
				case 'utilsCognac':
				case 'preshow':
                    // HTML image
                    fileLoader.setResponseType("blob");
                    fileLoader.load(this.assetList[i].url, (buffer: any) => {
                        this.assetList[i].file = buffer;
                        this.assetList[i].ready = true;

                        //console.log(typeof buffer)

                        var reader = new FileReader();
                        reader.readAsDataURL(buffer);
                        reader.onloadend = function () {
                            var base64data = reader.result;

                            if (base64data) {

                                let elementTarget = "loadingImg";
                                let elementFormat = "jpeg";

                                if (_this.assetList[i].target === "rules") {
                                    elementTarget = "rules";
                                    elementFormat = "png";
                                }

                                let newBuffer = "data:image/" + elementFormat + ";base64" + (base64data as string).split("base64")[1];

								if(_this.assetList[i].target === "rules" || _this.assetList[i].target === "loading"){
									let element = document.getElementById(elementTarget);
									if (element) {
										element.style.backgroundImage = "url('" + newBuffer + "')";
										element.style.opacity = "1";
									}
								}else{
									_this.assetList[i].process = newBuffer;
								}
                            }
                        }

                        this.updateLoading();
                    //}, (xhr: any) => { this.assetList[i].size = xhr.total });
					});
                    break;
                /*case 'preshow':
                    // HTML video
                    fileLoader.setResponseType("blob");
                    fileLoader.load(this.assetList[i].url, (buffer: any) => {
                        this.assetList[i].file = buffer;
                        this.assetList[i].ready = true;

                        let element = document.getElementById("preshow") as HTMLVideoElement;

                        if (element) {
                            //element.src = URL.createObjectURL(buffer);
                            //alert("ok")
                            //alert(element.src)
                        }

                        this.updateLoading();
                    });
                    break;
				*/
                case 'musics':
                    // Textures
                    audioLoader.load(this.assetList[i].url, (buffer: any) => {
                        this.assetList[i].file = buffer;
                        this.assetList[i].ready = true;
                        this.updateLoading();
                    });
                    break;
                case 'sounds':
                    // Textures
                    audioLoader.load(this.assetList[i].url, (buffer: any) => {
                        this.assetList[i].file = buffer;
                        this.assetList[i].ready = true;
                        this.updateLoading();
                    });
                    break;
				case 'models':
					// Models
					gltfLoader.load(this.assetList[i].url, (gltf: any) => {
						this.assetList[i].file = gltf;
						this.assetList[i].ready = true;
						this.updateLoading();
                    });
					break;
				case 'fonts':
					// Font
					fontLoader.load(this.assetList[i].url, (font: THREE.Font) => {
						this.assetList[i].file = font;
						this.assetList[i].ready = true;
						this.updateLoading();
                    });
					break;
                case 'tiles':
                case 'tilesCognac':
                case 'spritesheets':
                case 'decors':
                case 'decorsCognac':
                case 'pickups':
                    textureLoader.load(this.assetList[i].url, (texture: THREE.Texture) => {
                        this.assetList[i].file = texture;
                        this.assetList[i].ready = true;
                        this.updateLoading();
                    });
                    break;
                default:
                    console.warn("ERROR in loading")
            }
        }
    }

    preload = (city: string, fe: FiktivEngine) => {
        this.fe = fe;
        if (this.fe) {
            let pool = (this.fe?.map as Lendless).poolManager;
            let textures = this.assetList;
            for (let texture of textures) {
                let slabData;
                if (tilesTypes.get(texture.name)) {
                    slabData = tilesTypes.get(texture.name);
                }

                if (pool) {
                    pool.createPool(texture.name);
                    for (let i = 0; i < (slabData && slabData.preload ? slabData.preload : 2); i++) {
                        if (!texture.name.includes("void")) {
                            let material = new THREE.SpriteMaterial({
                                map: texture.file,
                                opacity: 1
                            });
                            
                            let sprite = new THREE.Sprite(material);
                            sprite.name = texture.name;
                            pool?.pushInPool(texture.name, sprite, true);
                        }
                    }
                }
            }

            // Pickup depop anim
            if (pool) {
                pool.createPool("depop");
                console.log("create pool depop");
            }
            for (let texture of this.assetList) {
                if (texture.name === 'depopSprite') {
                    for (let i = 0; i < 2; i++) {
                        let data = {
                            w: 8,
                            h: 5,
                            currentTile: 0,
                            max: 40
                        };
                        let depop = new AnimatedSprite(this.fe?.syncList, texture.file.clone(), data, 12, 1);

                        depop.name = "depop";
                        let pool = (this.fe?.map as Lendless).poolManager;
                        depop.sprite.position.set(128, 128, 0);
                        pool?.pushInPool("depop", depop, true);
                        this.fe?.scene.add(depop.sprite);
                    }
                }
            }

            // Smoke anim
            if (pool) {
                pool.createPool("smokeAnim");
                console.log("create pool smoke");
            }
            for (let asset of this.assetList) {
                if (asset.name === 'smoke') {
                    let data = {
                        w: 8,
                        h: 4,
                        currentTile: 0,
                        max: 32
                    };

                    let smoke = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data, 12);

                    smoke.name = "smokeAnim";
                    smoke.sprite.position.set(64, 64, 0);
                    pool?.pushInPool("smokeAnim", smoke, true);

                    this.fe?.scene.add(smoke.sprite);

                    smoke.reset();
                }
            }

            if (city === "paris") {
                // Chimney anim
                if (pool) {
                    pool.createPool("chimney");
                    console.log("create pool chimney");
                }

                for (let texture of this.assetList) {
                    if (texture.name === 'chimney') {
                        for (let i = 0; i < 4; i++) {
                            let data = {
                                w: 8,
                                h: 7,
                                currentTile: 0,
                                max: 52
                            };
                            data.currentTile = Math.random() * data.max - 1;
                            let chimney = new AnimatedSprite(this.fe?.syncList, texture.file.clone(), data, 12);

                            chimney.name = "chimney";
                            chimney.sprite.name = "chimney";
                            let pool = (this.fe?.map as Lendless).poolManager;
                            chimney.sprite.position.set(64, 64, 0);
                            pool?.pushInPool("chimney", chimney, true);
                            this.fe?.scene.add(chimney.sprite);
                        }
                    }
                }
            }
        }
    }
}