export function getListAssets(city: string, lang?: string) {

    if (lang && (lang === 'zh-hans' || lang === 'zh-hant')) {
        lang = 'cn';
    } else {
        lang = 'en'
    }

    let list = [
        // HTML videos
        { name: 'loading', url: './preshows/loading_' + city + '_' + lang + '.jpg', target: 'loading', total: 0, progress: 0, ready: false, file: null },
        /*{ name: 'preshow', url: './preshows/preshow_' + city + '_1.mp4', target: 'preshow', total: 0, progress: 0, ready: false, file: null },*/
        { name: 'rules', url: './rules/rules_' + city + '_' + lang + '.png', target: 'rules', total: 0, progress: 0, ready: false, file: null },

        // Models
        { name: 'running', url: './models/' + city + '/running.glb', target: 'models', total: 0, progress: 0, ready: false, file: null },
        { name: 'jumping', url: './models/' + city + '/jumping.glb', target: 'models', total: 0, progress: 0, ready: false, file: null },
        { name: 'doublejumping', url: './models/' + city + '/doublejumping.glb', target: 'models', total: 0, progress: 0, ready: false, file: null },
        { name: 'runningCognac', url: './models/cognac/running.glb', target: 'models', total: 0, progress: 0, ready: false, file: null },
        { name: 'jumpingCognac', url: './models/cognac/jumping.glb', target: 'models', total: 0, progress: 0, ready: false, file: null },
        { name: 'doublejumpingCognac', url: './models/cognac/doublejumping.glb', target: 'models', total: 0, progress: 0, ready: false, file: null },

        // Fonts
        { name: 'font', url: './fonts/Roboto Slab_Regular.json', target: 'fonts', total: 0, progress: 0, ready: false, file: null },

        // Musics
        { name: 'preshowSFX', url: './sounds/xo_hennessy_preshow_sfx_02.mp3', target: 'musics', total: 0, progress: 0, ready: false, file: null },
        { name: 'XOHennessyScore', url: './sounds/xo_hennessy_score.mp3', target: 'musics', total: 0, progress: 0, ready: false, file: null },
        { name: 'XOHennessyGame', url: './sounds/xo_hennessy_in_game.mp3', target: 'musics', total: 0, progress: 0, ready: false, file: null },

        // Sounds
        { name: 'SD_bras', url: './sounds/soundDesign/SD_bras.mp3', target: 'sounds', total: 0, progress: 0, ready: false, file: null },
        { name: 'SD_item', url: './sounds/soundDesign/SD_item.mp3', target: 'sounds', total: 0, progress: 0, ready: false, file: null },
        { name: 'SD_nuage', url: './sounds/soundDesign/SD_nuage.mp3', target: 'sounds', total: 0, progress: 0, ready: false, file: null },
        { name: 'SD_jump', url: './sounds/soundDesign/SD_jump.mp3', target: 'sounds', total: 0, progress: 0, ready: false, file: null },
        { name: 'SD_doublejump', url: './sounds/soundDesign/SD_doublejump.mp3', target: 'sounds', total: 0, progress: 0, ready: false, file: null },

        /* CITY */
        // Normal :
        { name: 'right', url: './assets/' + city + '/tiles/Tile_1x1_BL_TR.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        { name: 'left', url: './assets/' + city + '/tiles/Tile_1x1_BR_TL.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        { name: 'rightdown', url: './assets/' + city + '/tiles/Tile_1x1_TR_BL.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        { name: 'leftdown', url: './assets/' + city + '/tiles/Tile_1x1_TL_BR.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        // Vertical :
        { name: 'righttop', url: './assets/' + city + '/tiles/Tile_1x1_BL_T.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        { name: 'lefttop', url: './assets/' + city + '/tiles/Tile_1x1_BR_T.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        { name: 'topright', url: './assets/' + city + '/tiles/Tile_1x1_B_TR.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        { name: 'topleft', url: './assets/' + city + '/tiles/Tile_1x1_B_TL.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        { name: 'top', url: './assets/' + city + '/tiles/Tile_1x1_B_T.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        // Corner :
        { name: 'righttoleft', url: './assets/' + city + '/tiles/Tile_2x2_BL_TL.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        { name: 'lefttoright', url: './assets/' + city + '/tiles/Tile_2x2_BR_TR.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        { name: 'leftbottorightbot', url: './assets/' + city + '/tiles/Tile_2x2_BL_BR.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        { name: 'rightbottoleftbot', url: './assets/' + city + '/tiles/Tile_2x2_BL_BR.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        { name: 'lefttoptorighttop', url: './assets/' + city + '/tiles/Tile_2x2_TL_TR.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        { name: 'righttoptolefttop', url: './assets/' + city + '/tiles/Tile_2x2_TR_TL.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        // Void :
        { name: 'voidleft', url: '', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        { name: 'voidright', url: '', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        { name: 'voidleftdown', url: '', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        { name: 'voidrightdown', url: '', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        { name: 'voidbottop', url: '', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        // Platforms :
        { name: 'plateforme', url: './assets/' + city + '/tiles/Tile_9x9_platform.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        { name: 'plateforme3214', url: './assets/' + city + '/tiles/Tile_9x9_platform.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        { name: 'plateforme321', url: './assets/' + city + '/tiles/Tile_9x9_platform.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        { name: 'plateforme214', url: './assets/' + city + '/tiles/Tile_9x9_platform.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null },
        // Utils :
        { name: 'cartridge', url: './assets/' + city + '/utils/utils_cartridge.png', target: 'utils', total: 0, progress: 0, ready: false, file: null },

        /* COGNAC */
        // Normal :
        { name: 'rightCognac', url: './assets/cognac/tiles/Tile_1x1_BL_TR.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        { name: 'leftCognac', url: './assets/cognac/tiles/Tile_1x1_BR_TL.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        { name: 'rightdownCognac', url: './assets/cognac/tiles/Tile_1x1_TR_BL.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        { name: 'leftdownCognac', url: './assets/cognac/tiles/Tile_1x1_TL_BR.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        // Vertical :
        { name: 'righttopCognac', url: './assets/cognac/tiles/Tile_1x1_BL_T.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        { name: 'lefttopCognac', url: './assets/cognac/tiles/Tile_1x1_BR_T.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        { name: 'toprightCognac', url: './assets/cognac/tiles/Tile_1x1_B_TR.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        { name: 'topleftCognac', url: './assets/cognac/tiles/Tile_1x1_B_TL.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        { name: 'topCognac', url: './assets/cognac/tiles/Tile_1x1_B_T.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        // Corner :
        { name: 'righttoleftCognac', url: './assets/cognac/tiles/Tile_2x2_BL_TL.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        { name: 'lefttorightCognac', url: './assets/cognac/tiles/Tile_2x2_BR_TR.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        { name: 'leftbottorightbotCognac', url: './assets/cognac/tiles/Tile_2x2_BL_BR.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        { name: 'rightbottoleftbotCognac', url: './assets/cognac/tiles/Tile_2x2_BL_BR.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        { name: 'lefttoptorighttopCognac', url: './assets/cognac/tiles/Tile_2x2_TL_TR.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        { name: 'righttoptolefttopCognac', url: './assets/cognac/tiles/Tile_2x2_TR_TL.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        // Void :
        { name: 'voidleftCognac', url: '', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        { name: 'voidrightCognac', url: '', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        { name: 'voidleftdownCognac', url: '', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        { name: 'voidrightdownCognac', url: '', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        { name: 'voidbottopCognac', url: '', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        // Platforms :
        { name: 'plateformeCognac', url: './assets/cognac/tiles/Tile_9x9_platform.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        { name: 'plateforme3214Cognac', url: './assets/cognac/tiles/Tile_9x9_platform.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        { name: 'plateforme321Cognac', url: './assets/cognac/tiles/Tile_9x9_platform.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        { name: 'plateforme214Cognac', url: './assets/cognac/tiles/Tile_9x9_platform.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null },
        // Utils
        { name: 'cartridgeCognac', url: './assets/cognac/utils/utils_cartridge.png', target: 'utilsCognac', total: 0, progress: 0, ready: false, file: null },
    ];

    // Tiles
    list.push({ name: 'gate1', url: './assets/cognac/monuments/Gate.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null });
    list.push({ name: 'gate2', url: './assets/cognac/monuments/Gate.png', target: 'tilesCognac', total: 0, progress: 0, ready: false, file: null });
    switch (city) {
        case 'paris':
            list.push({ name: 'eiffeltower', url: './assets/paris/monuments/EiffelTower.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'arcdetriomphe', url: './assets/paris/monuments/ArcDeTriomphe.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'sacrecoeur', url: './assets/paris/monuments/SacreCoeur.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'haussmanbuilding', url: './assets/paris/monuments/HaussmanBuilding.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            break;
        case 'hainan':
            list.push({ name: 'haikouarcanedstreets', url: './assets/hainan/monuments/HaikouArcanedStreets.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'haikoucenturybridge', url: './assets/hainan/monuments/HaikouCenturyBridge.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'sanyaphoenixislandresort', url: './assets/hainan/monuments/SanyaPhoenixIslandResort.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'tianyahaijiao', url: './assets/hainan/monuments/TianyaHaijiao.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            break;
        case 'macau':
            list.push({ name: 'lisboahotel', url: './assets/macau/monuments/LisboaHotel.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'macautower', url: './assets/macau/monuments/MacauTower.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'venetianhotel', url: './assets/macau/monuments/VenetianHotel.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'zhuhaibridge', url: './assets/macau/monuments/ZhuhaiBridge.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            break;
        case 'hongkong':
            list.push({ name: 'bankofchina', url: './assets/hongkong/monuments/BankOfChina.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'clocktower', url: './assets/hongkong/monuments/ClockTower.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'junk', url: './assets/hongkong/monuments/Junk.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'tram', url: './assets/hongkong/monuments/Tram.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            break;
        case 'singapore':
            list.push({ name: 'archesvegetales', url: './assets/singapore/monuments/ArchesVegetales.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'imaginarybuilding', url: './assets/singapore/monuments/ImaginaryBuilding.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'shophouse', url: './assets/singapore/monuments/ShopHouse.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'whiteblackhouse', url: './assets/singapore/monuments/WhiteBlackHouse.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            break;
    }

    // MoreTile
    list.push({ name: 'gateExtract', url: './assets/cognac/monuments/GateExtract.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });

    // Spritesheets
    list.push({ name: 'depopSprite', url: './assets/effects/particules.png', target: 'spritesheets', total: 0, progress: 0, ready: false, file: null });
    list.push({ name: 'smoke', url: './assets/effects/disparition.png', target: 'spritesheets', total: 0, progress: 0, ready: false, file: null });
    switch (city) {
        case 'paris':
            list.push({ name: 'chimney', url: './decors/paris/decor_fumee_anim.png', target: 'spritesheets', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'decor_metro_background', url: './decors/paris/decor_metro_background.png', target: 'spritesheets', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'decor_metro_foreground', url: './decors/paris/decor_metro_foreground.png', target: 'spritesheets', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'decor_metro_train', url: './decors/paris/decor_metro_train.png', target: 'spritesheets', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'decor_birds1', url: './decors/paris/decor_bird_anim.png', target: 'spritesheets', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'decor_birds2', url: './decors/paris/decor_bird_anim2.png', target: 'spritesheets', total: 0, progress: 0, ready: false, file: null });
            break;
        case 'hainan':
            list.push({ name: 'palmtree', url: './decors/hainan/decor_palmtree_anim.png', target: 'spritesheets', total: 0, progress: 0, ready: false, file: null });
            //list.push({ name: 'METRO2', url: './decors/hainan/METRO2.png', target: 'spritesheets', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'decor_haikou_bridge_1', url: './decors/hainan/decor_haikou_bridge_1.png', target: 'spritesheets', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'decor_haikou_bridge_2', url: './decors/hainan/decor_haikou_bridge_2.png', target: 'spritesheets', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'decor_birds1', url: './decors/hainan/decor_bird_anim.png', target: 'spritesheets', total: 0, progress: 0, ready: false, file: null });
            break;
        case 'macau':
            list.push({ name: 'palmtree', url: './decors/macau/decor_palmtree_anim.png', target: 'spritesheets', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'decor_lights', url: './decors/macau/decor_lights.png', target: 'spritesheets', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'decor_birds1', url: './decors/macau/decor_bird_anim.png', target: 'spritesheets', total: 0, progress: 0, ready: false, file: null });
            break;
        case 'singapore':
            list.push({ name: 'archesvegetalesforeground', url: './assets/singapore/monuments/ArchesVegetales_foreground.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            break;
        case 'hongkong':
            list.push({ name: 'decor_jonque', url: './decors/hongkong/decor_jonque.png', target: 'tiles', total: 0, progress: 0, ready: false, file: null });
            break;
    }

    list.push({ name: 'background', url: './decors/' + city + '/background.jpg', target: 'decors', total: 0, progress: 0, ready: false, file: null });
    list.push({ name: 'backgroundCognac', url: './decors/cognac/background.jpg', target: 'decorsCognac', total: 0, progress: 0, ready: false, file: null });
    switch (city) {
        case 'paris':
            list.push({ name: 'foreground', url: './decors/paris/clouds.png', target: 'decors', total: 0, progress: 0, ready: false, file: null });
            break;
        case 'hainan':
            list.push({ name: 'foreground', url: '', target: 'decors', total: 0, progress: 0, ready: false, file: null });
            break;
        case 'macau':
            list.push({ name: 'foreground', url: '', target: 'decors', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'background2', url: './decors/macau/decor_lights.png', target: 'decors', total: 0, progress: 0, ready: false, file: null });
            break;
        case 'hongkong':
            list.push({ name: 'foreground', url: '', target: 'decors', total: 0, progress: 0, ready: false, file: null });
            break;
        case 'singapore':
            list.push({ name: 'foreground', url: '', target: 'decors', total: 0, progress: 0, ready: false, file: null });
            break;
    }

    // Items
    list.push({ name: 'halo', url: './assets/effects/halo.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
    list.push({ name: '1765_R', url: './assets/' + city + '/items/pickup_1765_R.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
    list.push({ name: '1765_L', url: './assets/' + city + '/items/pickup_1765_R.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
    list.push({ name: 'feuille_R', url: './assets/' + city + '/items/pickup_feuille_R.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
    list.push({ name: 'feuille_L', url: './assets/' + city + '/items/pickup_feuille_L.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
    list.push({ name: '1765Cognac_R', url: './assets/cognac/items/pickup_1765_R.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
    list.push({ name: '1765Cognac_L', url: './assets/cognac/items/pickup_1765_R.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
    list.push({ name: 'feuilleCognac_R', url: './assets/cognac/items/pickup_feuille_R.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
    list.push({ name: 'feuilleCognac_L', url: './assets/cognac/items/pickup_feuille_L.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
    list.push({ name: 'grapCognac_R', url: './assets/cognac/items/pickup_grap_R.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
    list.push({ name: 'grapCognac_L', url: './assets/cognac/items/pickup_grap_L.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
    switch (city) {
        case 'paris':
            list.push({ name: 'macaron_R', url: './assets/paris/items/pickup_macaron_R.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'macaron_L', url: './assets/paris/items/pickup_macaron_L.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'croissant_R', url: './assets/paris/items/pickup_croissant_R.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'croissant_L', url: './assets/paris/items/pickup_croissant_L.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
            break;
        case 'hainan':
            list.push({ name: 'coco_R', url: './assets/hainan/items/pickup_coco_R.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'coco_L', url: './assets/hainan/items/pickup_coco_L.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'lobster_R', url: './assets/hainan/items/pickup_lobster_R.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'lobster_L', url: './assets/hainan/items/pickup_lobster_L.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
            break;
        case 'macau':
            list.push({ name: 'pastels_R', url: './assets/macau/items/pickup_pastels_R.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'pastels_L', url: './assets/macau/items/pickup_pastels_L.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'eggroll_R', url: './assets/macau/items/pickup_eggroll_R.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'eggroll_L', url: './assets/macau/items/pickup_eggroll_L.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
            break;
        case 'hongkong':
            list.push({ name: 'dimsum_R', url: './assets/hongkong/items/pickup_dimsum_R.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'dimsum_L', url: './assets/hongkong/items/pickup_dimsum_L.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'waffle_R', url: './assets/hongkong/items/pickup_waffle_R.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'waffle_L', url: './assets/hongkong/items/pickup_waffle_L.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
            break;
        case 'singapore':
            list.push({ name: 'crab_R', url: './assets/singapore/items/pickup_crab_R.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'crab_L', url: './assets/singapore/items/pickup_crab_L.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'durian_R', url: './assets/singapore/items/pickup_durian_R.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
            list.push({ name: 'durian_L', url: './assets/singapore/items/pickup_durian_L.png', target: 'pickups', total: 0, progress: 0, ready: false, file: null });
            break;
    }

    // preshow
    for (let i = 0; i < 16; i++) {
        list.push({
            name: 'preshow_' + i,
            url: './preshows/' + city + '/preshow_' + i + '.jpg',
            target: 'preshow',
            total: 0,
            progress: 0,
            ready: false,
            file: null
        });
    }

    return list;
}