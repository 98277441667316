// react
import React, { useEffect, useState } from "react";
//data
const tradData = require("./../../data/trads.json");

export const getTrad = (props) => {
    let traduc = tradData.trads[props.id][props.lang];

    if (props.data != undefined) {
        for (let i = 0; props.data[i]; i++) {
            traduc = traduc.replace(/\%\%/g, props.data[i]);
        }
    }

    if (props.upperCase) {
        traduc = traduc.toUpperCase();
    }

    return traduc;
}

const GetTrad = (props) => {

    const [trad, setTrad] = useState("")

    useEffect(() => {
        if (tradData.trads.hasOwnProperty(props.id)) {
            let traduc = tradData.trads[props.id][props.lang];

            if (props.data != undefined) {
                for (let i = 0; i < props.data.length; i++) {
                    traduc = traduc.replace(/\%\%/, props.data[i]);
                }
            }
        
            if (props.upperCase) {
                traduc = traduc.toUpperCase();
            }

            setTrad(traduc)
        }
    }, [props.lang])

    return (
        <React.Fragment>
            {trad}
        </React.Fragment>
    )
}

export default GetTrad;