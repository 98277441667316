// Monuments Paris
import { default as eiffelTowerPlace } from './eiffelTowerPlace';
import { default as arcDeTriomphePlace } from './arcDeTriomphePlace';
import { default as sacreCoeurPlace } from './sacreCoeurPlace';
import { default as haussmanPlace } from './haussmanPlace';
// Monuments Hainan
import { default as haikouArcanedStreets } from './haikouArcanedStreets';
import { default as haikouCenturyBridge } from './haikouCenturyBridge';
import { default as sanyaPhoenixIslandResort } from './sanyaPhoenixIslandResort';
import { default as tianyaHaijiao } from './tianyaHaijiao';
// Monuments Macau
import { default as lisboaHotel } from './lisboaHotel';
import { default as zhuhaiBridge } from './zhuhaiBridge';
import { default as macauTower } from './macauTower';
import { default as venetianHotel } from './venetianHotel';
// Monuments HongKong
import { default as bankOfChina } from './bankOfChina';
import { default as clockTower } from './clockTower';
import { default as junkPlace } from './junkPlace';
import { default as tramPlace } from './tramPlace';
// Monuments Singapore
import { default as whiteBlackHouse } from './whiteBlackHouse';
import { default as imaginaryBuilding } from './imaginaryBuilding';
import { default as archesVegetalesPlace } from './archesVegetalesPlace';
import { default as shopHouse } from './shopHouse';
// Without
import { default as districtSimple1 } from './districtSimple1';
import { default as districtSimple2 } from './districtSimple2';
import { default as districtSimple3 } from './districtSimple3';
// Tests
//import { default as test } from './test';

let districtsList = new Map<string, Array<any>>();

districtsList.set('paris', [
    eiffelTowerPlace,
    arcDeTriomphePlace,
    sacreCoeurPlace,
    haussmanPlace,
    districtSimple1,
    districtSimple2,
    districtSimple3
]);

districtsList.set('hainan', [
    haikouArcanedStreets,
    haikouCenturyBridge,
    sanyaPhoenixIslandResort,
    tianyaHaijiao,
    districtSimple1,
    districtSimple2,
    districtSimple3
]);

districtsList.set('macau', [
    lisboaHotel,
    macauTower,
    venetianHotel,
    zhuhaiBridge,
    districtSimple1,
    districtSimple2,
    districtSimple3
]);

districtsList.set('hongkong', [
    bankOfChina,
    clockTower,
    junkPlace,
    tramPlace,
    districtSimple1,
    districtSimple2,
    districtSimple3
]);

districtsList.set('singapore', [
    whiteBlackHouse,
    imaginaryBuilding,
    archesVegetalesPlace,
    shopHouse,
    districtSimple1,
    districtSimple2,
    districtSimple3
]);

districtsList.set('cognac', [
    districtSimple1,
    districtSimple2,
    districtSimple3
]);

let districtsName = new Map<string, Array<string>>();

districtsName.set('paris', [
    'eiffelTowerPlace',
    'arcDeTriomphePlace',
    'sacreCoeurPlace',
    'haussmanPlace',
    'districtSimple1',
    'districtSimple2',
    'districtSimple3'
]);

districtsName.set('hainan', [
    'haikouArcanedStreets',
    'haikouCenturyBridge',
    'sanyaPhoenixIslandResort',
    'tianyaHaijiao',
    'districtSimple1',
    'districtSimple2',
    'districtSimple3'
]);

districtsName.set('macau', [
    'lisboaHotel',
    'macauTower',
    'venetianHotel',
    'zhuhaiBridge',
    'districtSimple1',
    'districtSimple2',
    'districtSimple3'
]);

districtsName.set('hongkong', [
    'bankOfChina',
    'clockTower',
    'junkPlace',
    'tramPlace',
    'districtSimple1',
    'districtSimple2',
    'districtSimple3'
]);

districtsName.set('singapore', [
    'whiteBlackHouse',
    'imaginaryBuilding',
    'archesVegetalesPlace',
    'shopHouse',
    'districtSimple1',
    'districtSimple2',
    'districtSimple3'
]);

districtsName.set('cognac', [
    'districtSimple1',
    'districtSimple2',
    'districtSimple3'
]);

export {districtsList, districtsName};