let district = [
    { "x": -1, "y": -1, "type": "right", "opts": "start", "pickup": false },
    { "x": -3, "y": -2, "type": "righttoleft" },
    { "x": -4, "y": -4, "type": "lefttoright" },
    { "x": -6, "y": -5, "type": "righttoleft" },
    { "x": -6, "y": -6, "type": "lefttop" },
    { "x": -7, "y": -7, "type": "top" },
    { "x": -8, "y": -8, "type": "topright" },
    { "x": -9, "y": -8, "type": "righttop" },
    { "x": -10, "y": -9, "type": "top" },
    { "x": -11, "y": -10, "type": "top" },
    { "x": -12, "y": -11, "type": "topright" },
    { "x": -13, "y": -11, "type": "voidright" },
    { "x": -15, "y": -12, "type": "righttoleft" },
    { "x": -16, "y": -14, "type": "lefttoright" },
    { "x": -17, "y": -14, "type": "right" },
    { "x": -19, "y": -15, "type": "righttoleft" },
    { "x": -19, "y": -16, "type": "voidleft" },
    { "x": -19, "y": -17, "type": "left" },
    { "x": -20, "y": -19, "type": "lefttoright" },
    { "x": -21, "y": -19, "type": "righttop" },
    { "x": -22, "y": -20, "type": "top" },
    { "x": -23, "y": -21, "type": "topright" },
    { "x": -24, "y": -21, "type": "right" },
    { "x": -25, "y": -21, "type": "right" },
    { "x": -26, "y": -21, "type": "righttop" },
    { "x": -27, "y": -22, "type": "top" },
    { "x": -28, "y": -23, "type": "topleft" },
    { "x": -28, "y": -24, "type": "left" },
    { "x": -28, "y": -25, "type": "lefttop" },
    { "x": -29, "y": -26, "type": "voidbottop" },
    { "x": -30, "y": -27, "type": "topleft" },
    { "x": -30, "y": -28, "type": "left" },
    { "x": -30, "y": -29, "type": "left" },
    { "x": -30, "y": -30, "type": "voidleft" },
    { "x": -30, "y": -31, "type": "lefttop" },
    { "x": -31, "y": -32, "type": "topright", "pickup": false },
    { "x": -32, "y": -32, "type": "right", "opts": "end", "pickup": false }
];

export default district;