let district = [
    { "x": -1, "y": -1, "type": "right", "opts": "start", "pickup": false },
    { "x": -3, "y": -2, "type": "righttoleft" },
    { "x": -3, "y": -3, "type": "left" },
    { "x": -3, "y": -4, "type": "left" },
    { "x": -3, "y": -5, "type": "left" },
    { "x": -3, "y": -6, "type": "voidleft" },
    { "x": -3, "y": -7, "type": "left" },
    { "x": -4, "y": -9, "type": "lefttoright" },
    { "x": -13, "y": -13, "type": "plateforme321", "pickup": false },

    /* Fake path */
    { "x": -9, "y": -4, "type": "left", "pickup": false }, 
    { "x": -9, "y": -16, "type": "leftbottorightbot", "pickup": false },
    /*{ "x": -11, "y": -2, "type": "right", "pickup": false },
    { "x": -12, "y": -2, "type": "right", "pickup": false },*/

    { "x": -9, "y": -14, "type": "right", "pickup": false },
    { "x": -9, "y": -3, "type": "righttoleft", "pickup": false },
    { "x": -7, "y": -2, "type": "lefttoright", "pickup": false },
    { "x": -6, "y": 0, "type": "left", "pickup": false },
    { "x": -6, "y": 1, "type": "left", "pickup": false },
    { "x": -6, "y": 2, "type": "left", "pickup": false },


    { "x": -7, "y": -16, "type": "left", "pickup": false },
    { "x": -6, "y": -16, "type": "left", "pickup": false },
    /* */

    {
        "x": -21,
        "y": -21,
        "type": "clocktower",
        "move": false,
        "pickup": false
    },
    { "x": -15, "y": -10, "type": "righttoleft" },
    { "x": -15, "y": -11, "type": "voidleft" },
    { "x": -15, "y": -12, "type": "voidleft" },
    { "x": -15, "y": -13, "type": "left" },
    { "x": -16, "y": -15, "type": "lefttoright" },
    { "x": -17, "y": -15, "type": "righttop" },
    { "x": -18, "y": -16, "type": "top" },
    { "x": -19, "y": -17, "type": "topright" },
    { "x": -20, "y": -17, "type": "righttop" },
    { "x": -21, "y": -18, "type": "top" },
    { "x": -22, "y": -19, "type": "top" },
    { "x": -23, "y": -20, "type": "topright" },
    { "x": -24, "y": -20, "type": "right" },
    { "x": -25, "y": -20, "type": "righttop" },
    { "x": -26, "y": -21, "type": "top" },
    { "x": -27, "y": -22, "type": "top" },
    { "x": -28, "y": -23, "type": "voidbottop" },
    { "x": -29, "y": -24, "type": "top" },
    { "x": -30, "y": -25, "type": "topleft" },
    { "x": -30, "y": -26, "type": "left" },
    { "x": -30, "y": -27, "type": "left" },
    { "x": -30, "y": -28, "type": "voidleft" },
    { "x": -30, "y": -29, "type": "left" },
    { "x": -30, "y": -30, "type": "left" },
    { "x": -30, "y": -31, "type": "lefttop" },
    { "x": -31, "y": -32, "type": "topright", "pickup": false },
    { "x": -32, "y": -32, "type": "right", "opts": "end", "pickup": false }
];

export default district;