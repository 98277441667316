import { FiktivEngine } from "../../../../fiktivengine_modules/fiktivengine-core";
import * as THREE from "three";

// Level
import { districtsList, districtsName } from "../../data/districts/districtsList";
import { Lendless } from "../../level/Lendless";

// Datas
import { tilesTypes } from "../../data/tilesTypes";
// Maps
import { default as verticalMap } from "../../data/districts/vertical"
import { Pickup } from "./Pickup";
import { AnimatedSprite } from "../utils/AnimatedSprite";

import { default as gatePlace } from '../../data/districts/gatePlace';
import { default as gateEndPlace } from '../../data/districts/gateEndPlace';

import { getTrad } from '../utils/GetTrad.js';

class Tile {
    public id: number; // id
    public pos: THREE.Vector3; // null object position
    public type: string;
    public size: THREE.Vector2;

    // Children
    public sprite: THREE.Sprite | undefined;
    public spriteCognac: THREE.Sprite | undefined;

    // Local Path
    public pathData: Map<string, any> | undefined;
    public localPath: Array<number | any> | undefined;

    // Options
    public options: string | undefined;
    public move: boolean | undefined;

    // Tiles linked
    public next: Tile | undefined;
    public previous: Tile | undefined;

    constructor(id: number, pos: THREE.Vector3, type: string, size: THREE.Vector2) {
        this.id = id;
        this.pos = pos;
        this.type = type;
        this.size = size;
    }
}

export class Tiles {
    private fe?: FiktivEngine;

    private pos: THREE.Vector3;

    // Containers
    public spriteContainer: THREE.Object3D;
    private linesContainer: THREE.Object3D;

    // Paths
    private mainPath: Array<any>;
    public path: Array<any>;

    // Map
    public city: string;
    public map: Array<any>;
    public districtName: string;
    public slabs: Array<Tile>;
    public tilesLoaded: number;
    private isLoading: boolean;
    private current: number;

    // Display
    //public divName: any;

    // Datas
    private defaultMaterial: THREE.SpriteMaterial;
    public pickups: any;

    public animations?: Array<AnimatedSprite>;
    public decors: THREE.Object3D;
    public textures: any;

    constructor(
        fe: FiktivEngine | undefined,
        pos: THREE.Vector3,
        current: number,
        vert?: boolean,
        cognac?: number
    ) {
        this.fe = fe;

        this.city = (this.fe?.map as Lendless).city || "paris";
        this.current = current;
        //this.divName = null;

        this.pos = new THREE.Vector3();
        this.pos.set(pos.x + 1, pos.y + 1, pos.z + 1);

        // Sprite container
        this.spriteContainer = new THREE.Object3D();
        this.spriteContainer.position.copy(pos);

        // Lines container
        this.linesContainer = new THREE.Object3D();

        // Paths
        this.mainPath = [];
        this.path = [];

        // Map
        let districtNbr = 0;
        let lendless = (this.fe?.map as Lendless);
        let districts = cognac ? districtsList.get('cognac') : districtsList.get(this.city);
        let district = cognac ? districtsName.get('cognac') : districtsName.get(this.city);

        this.animations = new Array<AnimatedSprite>();

        if (districts) {
            if (lendless && lendless.random) {
                districtNbr = Math.floor(lendless.random.random() * districts.length);
            }

            if (cognac === undefined) {
                this.map = (current === -1 || vert === true) ? verticalMap :
                    current === 0 ? districts[0] :
                        current === 1 && districts.length > 1 ? districts[1] :
                            current === 2 && districts.length > 2 ? districts[2] :
                                current === 3 && districts.length > 3 ? districts[3] :
                                    districts[districtNbr];

                if (district) {
                    this.districtName = (current === -1 || vert === true) ? 'verticalMap' :
                        current === 0 ? district[0] :
                            current === 1 && district.length > 1 ? district[1] :
                                current === 2 && district.length > 2 ? district[2] :
                                    current === 3 && district.length > 3 ? district[3] :
                                        district[districtNbr];
                } else {
                    this.districtName = 'verticalMap';
                }
            } else {
                this.map = cognac === 0 ? verticalMap :
                cognac === 1 ? gatePlace :
                    cognac === -10 ? gateEndPlace :
                        districts[districtNbr];

                if (district) {
                    this.districtName = cognac === 0 ? 'verticalMap' :
                    cognac === 1 ? 'gatePlace' :
                        cognac === -10 ? 'gateEndPlace' :
                            district[districtNbr];
                } else {
                    this.districtName = 'verticalMap';
                }
            }
        } else {
            this.map = verticalMap;
            this.districtName = 'verticalMap';
        }

        this.slabs = new Array<Tile>();
        this.tilesLoaded = 0;
        this.isLoading = true;

        this.defaultMaterial = new THREE.SpriteMaterial({ color: 0xff0000 });

        this.decors = new THREE.Object3D();
        this.decors.position.copy(pos);
        this.fe?.scene.add(this.decors);

        this.textures = (this.fe?.map as Lendless).preload.assetList;

        this.pickups = [];
        // Pickup
        if (this.fe && current >= 0) {
            for (let i = 0; i < 3; i++) {
                // Select tile
                let slab = this.map[Math.floor(((this.fe.map as Lendless).random?.random() || 0) * this.map.length)];

                let limit = 10;
                while (slab.pickup === false) {
                    if (limit-- < 0) {
                        break;
                    }
                    slab = this.map[Math.floor(((this.fe.map as Lendless).random?.random() || 0) * this.map.length)];
                }

                if (slab.pickup !== false) {
                    let slabData: any;
                    if (tilesTypes.get(slab.type)) {
                        slabData = tilesTypes.get(slab.type);
                    }

                    let faceto = "right";
                    if (slabData && slabData.faceto) {
                        if (slabData.faceto === "left") {
                            faceto = "left";
                        }
                    }

                    let assets = new Map<string, Array<Array<string>>>();
                    assets.set("paris", [
                        ['macaron_R', 'croissant_R', 'feuille_R', '1765_R'],
                        ['macaron_L', 'croissant_L', 'feuille_L', '1765_R']
                    ]);
                    assets.set("hainan", [
                        ['coco_R', 'lobster_R', 'feuille_R', '1765_R'],
                        ['coco_L', 'lobster_L', 'feuille_L', '1765_R']
                    ]);
                    assets.set("macau", [
                        ['eggroll_R', 'pastels_R', 'feuille_R', '1765_R'],
                        ['eggroll_L', 'pastels_L', 'feuille_L', '1765_R']
                    ]);
                    assets.set("hongkong", [
                        ['waffle_R', 'dimsum_R', 'feuille_R', '1765_R'],
                        ['waffle_L', 'dimsum_L', 'feuille_L', '1765_R']
                    ]);
                    assets.set("singapore", [
                        ['crab_R', 'durian_R', 'feuille_R', '1765_R'],
                        ['crab_L', 'durian_L', 'feuille_L', '1765_R']
                    ]);
                    assets.set("cognac", [
                        ['grapCognac_R', 'feuilleCognac_R', '1765Cognac_R'],
                        ['grapCognac_L', 'feuilleCognac_L', '1765Cognac_R']
                    ]);

                    let assetsCity = assets.get(cognac !== undefined ? 'cognac' : this.city) || [[], []];

                    let voidTiles = ["voidleft", "voidright", "voidleftbot", "voidrightbot", "voidbottop"];
                    let pickup = new Pickup(
                        this,
                        this.fe,
                        new THREE.Vector3(slab.x + this.pos.x, slab.y + this.pos.y, 3),
                        faceto === "right" ? assetsCity[0][Math.floor(((this.fe.map as Lendless).random?.random() || 0) * assetsCity[0].length)] : assetsCity[1][Math.floor(((this.fe.map as Lendless).random?.random() || 0) * assetsCity[1].length)],
                        voidTiles.includes(slab.type))
                    pickup.setPosition({ position: new THREE.Vector3(slab.x + this.pos.x + (slabData.long - 1), slab.y + this.pos.y + (slabData.larg - 1), 3) })
                    this.pickups.push(pickup);
                }
            }
        }

        this.loadNext();
    }

    unload = () => {
        let pool = (this.fe?.map as Lendless).poolManager;
        for (let sprite of this.spriteContainer.children) {
            if (pool) {
                pool.createPool(sprite.name);
                pool.changeStatus(sprite.name, sprite, false);
            }
        }
        this.spriteContainer.clear();
        if (this.animations) {
            while (this.animations.length > 0) {
                let elem = this.animations.splice(0, 1)[0];
                if (pool) {
                    pool.changeStatus(elem.name, elem, false);
                }
                this.decors.remove(elem.sprite);
            }
        }
        for (let pickup of this.pickups) {
            pickup.unload();
        }
        for (let sprite of this.decors.children) {
            if (pool) {
                pool.createPool(sprite.name);
                pool.changeStatus(sprite.name, sprite, false);
            }
        }
        this.decors.clear();
    }

    loadNext = () => {
        if (!this.map[this.tilesLoaded] || !this.isLoading) {
            return;
        }

        let slab = this.map[this.tilesLoaded];

        if (slab.type && slab.type !== "blocked") {
            let tile = new Tile(this.tilesLoaded, new THREE.Vector3(this.pos.x + slab.x, this.pos.y + slab.y, 0), slab.type, new THREE.Vector2(1, 1));
            tile.localPath = [];
            tile.pathData = new Map();
            tile.options = slab.opts;
            tile.move = slab.move;

            let slabData: any;
            if (tilesTypes.get(tile.type)) {
                slabData = tilesTypes.get(tile.type);
            }

            if (slabData && slabData.long && slabData.larg) {
                tile.size = new THREE.Vector2(slabData.long, slabData.larg);
            }

            // Sprite
            let pool = (this.fe?.map as Lendless).poolManager;
            let elem = null;
            let elemCognac = null;
            if (pool) {
                pool.createPool(slab.type);
                pool.createPool(slab.type + 'Cognac');
                elem = pool.changeStatus(slab.type, undefined, true);
                elemCognac = pool.changeStatus(slab.type + 'Cognac', undefined, true);
            }

            if (elem) {
                tile.sprite = elem;
            } else {
                let material = this.defaultMaterial;
                for (let asset of this.textures) {
                    if (asset.name === tile.type) {
                        if (asset.file) {
                            material = new THREE.SpriteMaterial({
                                map: asset.file
                            });
                            if (tile.type.includes("void")) {
                                console.log(tile.type)
                            }
                            tile.sprite = new THREE.Sprite(material);
                            tile.sprite.scale.set(0.5, 0.5, 0.5)
                            tile.sprite.name = slab.type;
                            pool?.pushInPool(slab.type, tile.sprite, false);
                            break;
                        }
                    }
                }
            }

            if (elemCognac) {
                tile.spriteCognac = elemCognac;
            } else {
                let material = this.defaultMaterial;
                for (let asset of this.textures) {
                    if (asset.name === tile.type + 'Cognac') {
                        if (asset.file) {
                            material = new THREE.SpriteMaterial({
                                map: asset.file
                            });
                            if (tile.type.includes("void")) {
                                console.log(tile.type + 'Cognac')
                            }
                            tile.spriteCognac = new THREE.Sprite(material);
                            tile.spriteCognac.scale.set(0.5, 0.5, 0.5)
                            tile.spriteCognac.name = slab.type + 'Cognac';
                            pool?.pushInPool(slab.type + 'Cognac', tile.spriteCognac, false);
                            break;
                        }
                    }
                }
            }

            if (tile.sprite) {
                tile.sprite.renderOrder = 0;
                tile.sprite.scale.set(1.8 * Math.max(slabData.long, slabData.larg), 1.8 * Math.max(slabData.long, slabData.larg), 1.8 * Math.max(slabData.long, slabData.larg));
                if (!slabData.foreground) {
                    tile.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long - 1.5, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg - 1.5, -2);
                } else {
                    tile.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 6, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 6, 6);

                    if (slabData.name) {
                        let elemContainerData = document.getElementById("dataDisplay") as any;
                        if (elemContainerData) {
                            let div = document.createElement("div");
                            let divContent = document.createElement("div");
                            let text = document.createTextNode("" + getTrad({
                                id: slabData.name,
                                lang: (this.fe?.map as Lendless).lang || 'en'
                            }) + "");

                            div.id = this.districtName + "-" + tile.id;

                            //console.log((this.pos.y + tile.sprite.position.y))

                            div.dataset.posX = "" + (this.pos.x + tile.sprite.position.x) + "";
                            div.dataset.posY = "" + (this.pos.y + tile.sprite.position.y) + "";
                            div.dataset.posZ = "" + (tile.sprite.position.z) + "";

                            div.dataset.offsetX = "" + (slabData.offsetH || 0) + "";
                            div.dataset.offsetY = "" + (slabData.offsetV || 0) + "";

                            let lang = (this.fe?.map as Lendless).lang;
                            div.className = "dataPlaceNamePosition";
                            divContent.className = "dataPlaceName " + ((lang === 'zh-hans' || lang === 'zh-hant') ? 'nameHanzi' : 'nameLatin');

							let finalFile = "";
							let fileToFound = "cartridge";

							if((this.fe?.map as Lendless).cognac){
								fileToFound = "cartridgeCognac"
							}

							for(let asset of this.textures){
								if(asset.name === fileToFound){
									finalFile = asset.process;
								}
							}

                            divContent.style.backgroundImage = "url('"+ finalFile + "')"

                            div.style.top = "-20%";

                            divContent.appendChild(text)
                            div.appendChild(divContent);

                            elemContainerData.appendChild(div);
                        }
                        //console.log(tile.id+" "+this.districtName)
                    }
                }

                if ((this.fe?.map as Lendless).cognac === false || !tile.spriteCognac) {
                    this.spriteContainer.add(tile.sprite);
                }

                if (tile.sprite.name === "haussmanbuilding") {
                    for (let i = 0; i < 2; i++) {
                        let elem = null;
                        if (pool) {
                            pool.createPool("chimney");
                            elem = pool.changeStatus("chimney", undefined, true);
                        }
                        if (!elem) {
                            let data = {
                                w: 8,
                                h: 7,
                                currentTile: 0,
                                max: 52
                            };

                            for (let asset of this.textures) {
                                if (asset.name === "chimney") {
                                    data.currentTile = Math.random() * data.max - 1;
                                    elem = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data, 12);
                                    elem.name = "chimney";

                                    if (i === 0) {
                                        elem.sprite.renderOrder = 0
                                        elem.sprite.scale.set(2, 2, 2);
                                        elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 9.8, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 10.6, 10);
                                        this.decors.add(elem.sprite);
                                        this.animations?.push(elem);
                                    }
                                    if (i === 1) {
                                        elem.sprite.renderOrder = 0
                                        elem.sprite.scale.set(2, 2, 2);
                                        elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 9.6, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 9.2, 10);
                                        this.decors.add(elem.sprite);
                                        this.animations?.push(elem);
                                    }

                                    pool?.pushInPool("chimney", elem, false);
                                }
                            }
                        } else {
                            //console.log(elem)
                            if (elem.hasOwnProperty("sprite")) {
                                if (i === 0) {
                                    elem.sprite.renderOrder = 0
                                    elem.sprite.scale.set(2, 2, 2);
                                    elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 9.8, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 10.6, 10);
                                    this.decors.add(elem.sprite);
                                    this.animations?.push(elem);
                                }
                                if (i === 1) {
                                    elem.sprite.renderOrder = 0
                                    elem.sprite.scale.set(2, 2, 2);
                                    elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 9.6, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 9.2, 10);
                                    this.decors.add(elem.sprite);
                                    this.animations?.push(elem);
                                }
                            }
                        }
                    }
                }

                if (tile.sprite.name === "haikouarcanedstreets") {
                    for (let i = 0; i < 3; i++) {
                        // Reverse since the second palmtree
                        let reversed = i < 2 ? false : true;
                        let name = reversed ? "palmtree_reverse" : "palmtree";

                        let elem = null;

                        if (pool) {
                            pool.createPool(name);
                            elem = pool.changeStatus(name, undefined, true);
                        }
                        if (!elem) {
                            let data = {
                                w: 8,
                                h: 8,
                                currentTile: 0,
                                max: 64
                            };

                            for (let asset of this.textures) {
                                if (asset.name === "palmtree") {
                                    data.currentTile = Math.random() * data.max - 1;
                                    elem = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data, 24, undefined, reversed ? 1 : undefined);
                                    elem.name = name;

                                    elem.sprite.renderOrder = 0
                                    elem.sprite.scale.set(2.5, 2.5, 2.5);

                                    switch (i) {
                                        case 0:
                                            elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 8.2, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 9, 2);
                                            break;
                                        case 1:
                                            elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 6, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 3.4, 0);
                                            break;
                                        case 2:
                                            elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 7, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 10.2, 3);
                                            break;
                                    }

                                    this.decors.add(elem.sprite);
                                    this.animations?.push(elem);
                                    pool?.pushInPool(name, elem, false);
                                }
                            }
                        } else {
                            if (elem.sprite) {
                                elem.sprite.renderOrder = 0
                                elem.sprite.scale.set(2.5, 2.5, 2.5);

                                switch (i) {
                                    case 0:
                                        elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 8.2, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 9, 2);
                                        break;
                                    case 1:
                                        elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 6, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 3.4, 0);
                                        break;
                                    case 2:
                                        elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 7, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 10.2, 3);
                                        break;
                                }

                                this.decors.add(elem.sprite);
                                this.animations?.push(elem);
                            }
                        }
                    }
                }

                if (tile.sprite.name === "sanyaphoenixislandresort") {
                    for (let i = 0; i < 3; i++) {
                        // Reverse since the second palmtree
                        let reversed = i < 1 ? false : true;
                        let name = reversed ? "palmtree_reverse" : "palmtree";

                        let elem = null;

                        if (pool) {
                            pool.createPool(name);
                            elem = pool.changeStatus(name, undefined, true);
                        }
                        if (!elem) {
                            let data = {
                                w: 8,
                                h: 8,
                                currentTile: 0,
                                max: 64
                            };

                            for (let asset of this.textures) {
                                if (asset.name === "palmtree") {
                                    data.currentTile = Math.random() * data.max - 1;
                                    elem = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data, 24, undefined, reversed ? 1 : undefined);
                                    elem.name = name;

                                    elem.sprite.renderOrder = 0
                                    elem.sprite.scale.set(2.5, 2.5, 2.5);

                                    switch (i) {
                                        case 0:
                                            elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 9, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 5, 2);
                                            break;
                                        case 1:
                                            elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 8, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 5, 3);
                                            break;
                                        case 2:
                                            elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 12.6, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 17, 10);
                                            break;
                                    }

                                    this.decors.add(elem.sprite);
                                    this.animations?.push(elem);
                                    pool?.pushInPool(name, elem, false);
                                }
                            }
                        } else {
                            if (elem.sprite) {
                                elem.sprite.renderOrder = 0
                                elem.sprite.scale.set(2.5, 2.5, 2.5);

                                switch (i) {
                                    case 0:
                                        elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 9, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 5, 2);
                                        break;
                                    case 1:
                                        elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 8, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 5, 3);
                                        break;
                                    case 2:
                                        elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 12.6, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 17, 10);
                                        break;
                                }

                                this.decors.add(elem.sprite);
                                this.animations?.push(elem);
                            }
                        }
                    }
                }

                if (tile.sprite.name === "tianyahaijiao") {

                    for (let i = 0; i < 3; i++) {
                        let elem = null;
                        let elemReversed = null;
                        if (pool) {
                            pool.createPool("palmtree");
                            pool.createPool("palmtree_reverse");
                            elem = pool.changeStatus("palmtree", undefined, true);
                            elemReversed = pool.changeStatus("palmtree_reverse", undefined, true);
                        }
                        if (!elem || !elemReversed) {
                            let data = {
                                w: 8,
                                h: 8,
                                currentTile: 0,
                                max: 64
                            };

                            for (let asset of this.textures) {
                                if (asset.name === "palmtree") {
                                    if (i === 0) {
                                        data.currentTile = Math.random() * data.max - 1;
                                        elemReversed = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data, 24, undefined, 1);
                                        elemReversed.name = "palmtree_reverse";

                                        elemReversed.sprite.renderOrder = 0
                                        elemReversed.sprite.scale.set(2.5, 2.5, 2.5);
                                        elemReversed.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 7.6, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 5, 3);
                                        this.decors.add(elemReversed.sprite);
                                        this.animations?.push(elemReversed);

                                        pool?.pushInPool("palmtree_reverse", elemReversed, false);
                                    }
                                    if (i === 1) {
                                        data.currentTile = Math.random() * data.max - 1;
                                        elemReversed = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data, 24, undefined, 1);

                                        elemReversed.name = "palmtree_reverse";
                                        elemReversed.sprite.renderOrder = 0
                                        elemReversed.sprite.scale.set(2.5, 2.5, 2.5);
                                        elemReversed.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 15, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 19, 10);
                                        this.decors.add(elemReversed.sprite);
                                        this.animations?.push(elemReversed);

                                        pool?.pushInPool("palmtree_reverse", elemReversed, false);
                                    }
                                    if (i === 2) {
                                        data.currentTile = Math.random() * data.max - 1;
                                        elem = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data, 24);
                                        elem.name = "palmtree";

                                        elem.sprite.renderOrder = 0
                                        elem.sprite.scale.set(2.5, 2.5, 2.5);
                                        elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 9, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 5, 2);
                                        this.decors.add(elem.sprite);
                                        this.animations?.push(elem);

                                        pool?.pushInPool("palmtree", elem, false);
                                    }
                                }
                            }
                        } else {
                            if (i === 0) {
                                elemReversed.sprite.renderOrder = 0
                                elemReversed.sprite.scale.set(2.5, 2.5, 2.5);
                                elemReversed.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 7.6, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 5, 3);
                                this.decors.add(elemReversed.sprite);
                                this.animations?.push(elemReversed);
                            }
                            if (i === 1) {
                                elemReversed.sprite.renderOrder = 0
                                elemReversed.sprite.scale.set(2.5, 2.5, 2.5);
                                elemReversed.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 15, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 19, 10);
                                this.decors.add(elemReversed.sprite);
                                this.animations?.push(elemReversed);
                            }
                            if (i === 2) {
                                elem.sprite.renderOrder = 0
                                elem.sprite.scale.set(2.5, 2.5, 2.5);
                                elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 9, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 5, 2);
                                this.decors.add(elem.sprite);
                                this.animations?.push(elem);
                            }
                        }
                    }
                }

                if (tile.sprite.name === "lisboahotel") {

                    for (let i = 0; i < 3; i++) {
                        // Reverse since the first palmtree
                        let reversed = i < 1 ? false : true;
                        let name = reversed ? "palmtree_reverse" : "palmtree";

                        let elem = null;

                        if (pool) {
                            pool.createPool(name);
                            elem = pool.changeStatus(name, undefined, true);
                        }
                        if (!elem) {
                            let data = {
                                w: 8,
                                h: 8,
                                currentTile: 0,
                                max: 64
                            };

                            for (let asset of this.textures) {
                                if (asset.name === "palmtree") {
                                    data.currentTile = Math.random() * data.max - 1;
                                    elem = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data, 24, undefined, reversed ? 1 : undefined);
                                    elem.name = name;

                                    elem.sprite.renderOrder = 0
                                    elem.sprite.scale.set(2.5, 2.5, 2.5);

                                    switch (i) {
                                        case 0:
                                            elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 6.8, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 2, 0);
                                            break;
                                        case 1:
                                            elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 4, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 8.6, 3);
                                            break;
                                        case 2:
                                            elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 5.8, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 10.4, 3);
                                            break;
                                    }

                                    this.decors.add(elem.sprite);
                                    this.animations?.push(elem);
                                    pool?.pushInPool(name, elem, false);
                                }
                            }
                        } else {
                            if (elem.sprite) {
                                elem.sprite.renderOrder = 0
                                elem.sprite.scale.set(2.5, 2.5, 2.5);

                                switch (i) {
                                    case 0:
                                        elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 6.8, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 2, 0);
                                        break;
                                    case 1:
                                        elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 4, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 8.6, 3);
                                        break;
                                    case 2:
                                        elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 5.8, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 10.4, 3);
                                        break;
                                }

                                this.decors.add(elem.sprite);
                                this.animations?.push(elem);
                            }
                        }
                    }
                }

                if (tile.sprite.name === "macautower") {
                    for (let i = 0; i < 4; i++) {
                        // Reverse since the second palmtree
                        let reversed = i < 2 ? false : true;
                        let name = reversed ? "palmtree_reverse" : "palmtree";

                        let elem = null;

                        if (pool) {
                            pool.createPool(name);
                            elem = pool.changeStatus(name, undefined, true);
                        }
                        if (!elem) {
                            let data = {
                                w: 8,
                                h: 8,
                                currentTile: 0,
                                max: 64
                            };

                            for (let asset of this.textures) {
                                if (asset.name === "palmtree") {
                                    data.currentTile = Math.random() * data.max - 1;
                                    elem = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data, 24, undefined, reversed ? 1 : undefined);
                                    elem.name = name;

                                    elem.sprite.renderOrder = 0
                                    elem.sprite.scale.set(2.5, 2.5, 2.5);

                                    switch (i) {
                                        case 0:
                                            elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 6.4, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 2, 0);
                                            break;
                                        case 1:
                                            elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 7.6, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 4.2, 0);
                                            break;
                                        case 2:
                                            elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 6.2, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 10.2, 3);
                                            break;
                                        case 3:
                                            elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 5.2, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 6.8, 3);
                                            break;
                                    }

                                    this.decors.add(elem.sprite);
                                    this.animations?.push(elem);
                                    pool?.pushInPool(name, elem, false);
                                }
                            }
                        } else {
                            if (elem.sprite) {
                                elem.sprite.renderOrder = 0
                                elem.sprite.scale.set(2.5, 2.5, 2.5);

                                switch (i) {
                                    case 0:
                                        elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 6.4, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 2, 0);
                                        break;
                                    case 1:
                                        elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 7.6, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 4.2, 0);
                                        break;
                                    case 2:
                                        elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 6.2, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 10.2, 3);
                                        break;
                                    case 3:
                                        elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 5.2, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 6.8, 3);
                                        break;
                                }

                                this.decors.add(elem.sprite);
                                this.animations?.push(elem);
                            }
                        }
                    }
                }

                if (tile.sprite.name === "venetianhotel") {
                    for (let i = 0; i < 2; i++) {
                        // Reverse since the second palmtree
                        let reversed = false;
                        let name = reversed ? "palmtree_reverse" : "palmtree";

                        let elem = null;

                        if (pool) {
                            pool.createPool(name);
                            elem = pool.changeStatus(name, undefined, true);
                        }
                        if (!elem) {
                            let data = {
                                w: 8,
                                h: 8,
                                currentTile: 0,
                                max: 64
                            };

                            for (let asset of this.textures) {
                                if (asset.name === "palmtree") {
                                    data.currentTile = Math.random() * data.max - 2;
                                    elem = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data, 24, undefined, reversed ? 1 : undefined);
                                    elem.name = name;

                                    elem.sprite.renderOrder = 0
                                    elem.sprite.scale.set(2.5, 2.5, 2.5);

                                    switch (i) {
                                        case 0:
                                            elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 6.4, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 2.2, 0);
                                            break;
                                        case 1:
                                            elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 8, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 3.8, 0);
                                            break;
                                    }

                                    this.decors.add(elem.sprite);
                                    this.animations?.push(elem);
                                    pool?.pushInPool(name, elem, false);
                                }
                            }
                        } else {
                            if (elem.sprite) {
                                elem.sprite.renderOrder = 0
                                elem.sprite.scale.set(2.5, 2.5, 2.5);

                                switch (i) {
                                    case 0:
                                        elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 6.4, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 2.2, 0);
                                        break;
                                    case 1:
                                        elem.sprite.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 8, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 3.8, 0);
                                        break;
                                }

                                this.decors.add(elem.sprite);
                                this.animations?.push(elem);
                            }
                        }
                    }
                }

                if (tile.sprite.name === "gate1" || tile.sprite.name === "gate2") {


                    let elem = null;
                    if (pool) {
                        pool.createPool("gateExtract");
                        elem = pool.changeStatus("gateExtract", undefined, true);
                    }
                    if (!elem) {
                        for (let asset of this.textures) {
                            if (asset.name === "gateExtract") {

                                let material = new THREE.SpriteMaterial({
                                    map: asset.file
                                });

                                let slabData;
                                if (tilesTypes.get('gate1')) {
                                    slabData = tilesTypes.get('gate1');
                                }

                                elem = new THREE.Sprite(material);
                                elem.name = "gateExtract";

                                elem.renderOrder = 0
                                elem.scale.set(1.8 * Math.max(slabData.long, slabData.larg), 1.8 * Math.max(slabData.long, slabData.larg), 1.8 * Math.max(slabData.long, slabData.larg));
                                elem.position.set(-13.9, -13.9, 10);
                                this.decors.add(elem);

                                pool?.pushInPool("gateExtract", elem, false);
                            }
                        }
                    } else {
                        elem.renderOrder = 0
                        elem.scale.set(1.8 * Math.max(slabData.long, slabData.larg), 1.8 * Math.max(slabData.long, slabData.larg), 1.8 * Math.max(slabData.long, slabData.larg));
                        elem.position.set(-13.9, -13.9, 10);
                        this.decors.add(elem);
                    }
                }

                if (tile.sprite.name === "archesvegetales") {

                    let elem = null;
                    if (pool) {
                        pool.createPool("archesvegetalesforeground");
                        elem = pool.changeStatus("archesvegetalesforeground", undefined, true);
                    }
                    if (!elem) {
                        for (let asset of this.textures) {
                            if (asset.name === "archesvegetalesforeground") {

                                let material = new THREE.SpriteMaterial({
                                    map: asset.file
                                });

                                let slabData;
                                if (tilesTypes.get('archesvegetales')) {
                                    slabData = tilesTypes.get('archesvegetales');
                                }

                                elem = new THREE.Sprite(material);
                                elem.name = "archesvegetalesforeground";

                                elem.renderOrder = 0
                                elem.scale.set(1.8 * Math.max(slabData.long, slabData.larg), 1.8 * Math.max(slabData.long, slabData.larg), 1.8 * Math.max(slabData.long, slabData.larg));
                                elem.position.set(-12, -12, 10);
                                this.decors.add(elem);

                                pool?.pushInPool("archesvegetalesforeground", elem, false);
                            }
                        }
                    } else {
                        elem.renderOrder = 0
                        elem.scale.set(1.8 * Math.max(slabData.long, slabData.larg), 1.8 * Math.max(slabData.long, slabData.larg), 1.8 * Math.max(slabData.long, slabData.larg));
                        elem.position.set(-12, -12, 10);
                        this.decors.add(elem);
                    }
                }
            }

            if (tile.spriteCognac) {
                tile.spriteCognac.renderOrder = 0;
                tile.spriteCognac.scale.set(1.8 * Math.max(slabData.long, slabData.larg), 1.8 * Math.max(slabData.long, slabData.larg), 1.8 * Math.max(slabData.long, slabData.larg));
                if (!slabData.foreground) {
                    tile.spriteCognac.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long - 1.5, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg - 1.5, -2);
                } else {
                    tile.spriteCognac.position.set(slab.x - (slabData.long - 1) / 2 + (slabData.long - 1) + 0.08 * slabData.long + 6, slab.y - (slabData.larg - 1) / 2 + (slabData.larg - 1) + 0.08 * slabData.larg + 6, 6);
                }
                if ((this.fe?.map as Lendless).cognac === true) {
                    if (tile.spriteCognac)
                        this.spriteContainer.add(tile.spriteCognac);
                    else if (tile.sprite)
                        this.spriteContainer.add(tile.sprite)
                }
            }

            this.slabs.push(tile);

            this.updatePath();
        }

        this.tilesLoaded++;
    }

    // ! \\ Old version
    updatePath = () => {
        // Reset Path
        this.mainPath = [];
        this.path = [];

        for (let tile of this.slabs) {
            tile.localPath = [];
            tile.pathData = new Map();
            tile.previous = undefined;
            tile.next = undefined;
            tile.pathData.clear();

            let slabData;
            if (tilesTypes.get(tile.type))
                slabData = tilesTypes.get(tile.type);

            if (slabData.long && slabData.larg)
                tile.size = new THREE.Vector2(slabData.long, slabData.larg);

            // Path
            if (slabData.path) {
                for (let i = 0; i < slabData.path.length; i++) {
                    let s1 = slabData.path[i]

                    if (i === 0) {
                        tile.pathData.set("begin", new THREE.Vector2(s1.x + tile.pos.x, s1.y + tile.pos.y))
                    }
                    tile.pathData.set("end", new THREE.Vector2(s1.x + tile.pos.x, s1.y + tile.pos.y))

                    tile.localPath.push([s1.void === true, new THREE.Vector3(s1.x + tile.pos.x, s1.y + tile.pos.y, 0)])
                }

                for (let mainPathElem of this.mainPath) {
                    if (Math.abs(tile.pathData.get("begin").x - mainPathElem.pathData.get("end").x) < 0.2 &&
                        Math.abs(tile.pathData.get("begin").y - mainPathElem.pathData.get("end").y) < 0.2) {

                        // Set next/previous tile
                        mainPathElem.next = tile.id;
                        tile.previous = mainPathElem.id;
                    }
                    if (Math.abs(tile.pathData.get("end").x - mainPathElem.pathData.get("begin").x) < 0.2 &&
                        Math.abs(tile.pathData.get("end").y - mainPathElem.pathData.get("begin").y) < 0.2) {

                        // Set next/previous tile
                        mainPathElem.previous = tile.id
                        tile.next = mainPathElem.id;
                    }
                }

                this.mainPath.push(tile);
            }
        }

        let mainPathTmp = []
        var idx = 0;
        // First tile
        for (let tile of this.mainPath) {
            if (tile.options === "start") {
                let tmp = tile.localPath//.slice(0, -1);
                mainPathTmp.push(
                    ...tmp
                );
                if (tile.next)
                    idx = tile.next;
                break;
            }
        }
        // Complete next
        for (let i = idx; i !== undefined; i) {
            let next = this.mainPath.find((tile) => { return tile.id === i })
            let tmp = next.localPath;
            if (next.options !== "end") {
                tmp = next.localPath.slice(0, -1);
            }
            mainPathTmp.push(
                ...tmp
            );
            i = next.next;
        }

        for (let i = 0; i < mainPathTmp.length; i++) {
            this.path.push({ "void": mainPathTmp[i][0] ? true : false, "vector": mainPathTmp[i][1] });
        }

        //this.drawPath()
    }

    drawPath = () => {
        this.linesContainer.children = []

        var mReachable = new THREE.LineBasicMaterial({ color: 0x00ff00 }); // Color when reachable
        var mVoid = new THREE.LineBasicMaterial({ color: 0xff0000 }); // Color on void

        for (let tile of this.slabs) {
            let slabData;
            if (tilesTypes.get(tile.type))
                slabData = tilesTypes.get(tile.type);

            if (slabData.path) {
                for (let i = 0; i < this.path.length; i++) {
                    let s1 = slabData.path[i];
                    let s2 = slabData.path[i + 1];

                    if (s2) {
                        let vertices = new Float32Array([
                            s1.x + tile.pos.x - this.pos.x, s1.y + tile.pos.y - this.pos.y, 0,
                            s2.x + tile.pos.x - this.pos.x, s2.y + tile.pos.y - this.pos.y, 0
                        ]);
                        var geometry = new THREE.BufferGeometry();
                        geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
                        var xline = new THREE.Line(geometry, s1.void === true ? mVoid : mReachable);
                        this.linesContainer.add(xline);
                    }
                }
            }
        }
    }
}