import { FiktivEngine, FiktivObject3D } from "../../../../fiktivengine_modules/fiktivengine-core";
import { PHero } from "../../pawn/PHero";

export class Score extends FiktivObject3D {
    private fe: FiktivEngine | undefined;

    // Score
    public total: number;
    public scoreTime: number;
    public scoreDistance: number;
    public scorePickUp: number;

    public addScores: Array<any>;

    // Worker
    public worker: any;

    constructor(
        fe?: FiktivEngine
    ) {
        super();
        this.fe = fe;

        this.total = -1;
        this.scoreTime = 0;
        this.scoreDistance = 0;
        this.scorePickUp = 0;

        this.addScores = [];

        if (window.Worker) {
            this.worker = new window.Worker('./scoreWorker.js');
        }

        this.fe?.syncList.push(this);

        this.update = (args) => {
            let pawn = this.fe?.players.get("me")?.pawn as PHero;
            if (pawn) {
                if (pawn.model.position.x > -1) {
                    this.total = -1
                } else {
                    let elemContainer = document.getElementById("scoreContainer") as any;
                    if (elemContainer) {
                        elemContainer.style.visibility = "visible";
                        this.total = this.scoreDistance + this.scorePickUp;
                        if (this.total) {
                            elemContainer.innerHTML = this.total.toString();
                        }
                    }
                }
            }

            for (let div of this.addScores) {
                let positionTop = parseInt(div.style.top.substring(0, div.style.top.length - 1));
                div.style.top = (positionTop - 0.00005) + "%";
                let newPosTop = parseInt(div.style.top.substring(0, div.style.top.length - 1));

                let currentTime = Date.now() - Number(div.dataset.time);
                //console.log(currentTime)

                if (newPosTop < 20 || currentTime > 1500) {
                    let elemContainer = document.getElementById("addScoreContainer") as any;
                    elemContainer.removeChild(div);
                    this.addScores.splice(this.addScores.indexOf(div), 1);
                }
            }
        }
    }

    add = (amount: number = 10, obj?: PHero) => {
        let elemContainer = document.getElementById("addScoreContainer") as any;
        if (elemContainer) {

            let div = document.createElement("div");
			let divContent = document.createElement("div");
            let text = document.createTextNode("+"+amount);

			divContent.appendChild(text)
            div.appendChild(divContent);
        
            div.style.position = "absolute"
            //div.style.top = (parseInt(elemContainer.style.top.substring(0, elemContainer.style.top.length - 2)) * 2) + "px";
            if (obj) {
                div.style.top = (obj.pos2D.y-10) + "%";
                div.style.left = obj.pos2D.x + "%";
            }
            div.style.color = "#F7DA00";
            div.style.fontFamily = "Hennessy";
            div.style.userSelect = "none";

			divContent.style.width = "200px";
			divContent.style.position = "relative";
			divContent.style.left = "-100px";
			divContent.style.textAlign = "center";
			//divContent.style.backgroundColor = "#FF0000";

            div.dataset.time = ""+Date.now()+"";

            elemContainer.appendChild(div);
            this.addScores.push(div);
        }
    }

    reset = () => {
        if (this.worker) {
            this.worker.postMessage("ScoreStop")
        }

        this.total = -1;
        this.scoreDistance = 0;
        this.scoreTime = 0;
        this.scorePickUp = 0;

        let pawn = this.fe?.players.get("me")?.pawn as PHero;
            if (pawn) {
                if (pawn.model.position.x > -1) {
                    this.total = -1
                } else {
                    let elemContainer = document.getElementById("scoreContainer") as any;
                    if (elemContainer) {
                        this.total = this.scoreDistance + this.scorePickUp;
                        elemContainer.innerHTML = "0";
                    }
                }
            }
    }

    start = () => {
        if (this.worker) {
            this.worker.onmessage = (e: any) => {
                if (e.data.msg === "ScoreScore") {
                    this.scoreTime++
                    this.total = this.scoreDistance + this.scorePickUp;
                }
            }

            this.worker.postMessage("ScoreStart");
        }
    }

    pause = () => {
        if (this.worker) {
            this.worker.postMessage("ScoreStop");
        }
    }
}