let district = [
    { "x": -1, "y": -1, "type": "right", "opts": "start", "pickup": false },
    { "x": -3, "y": -2, "type": "righttoleft", "pickup": false },
    { "x": -3, "y": -3, "type": "left", "pickup": false },
    { "x": -3, "y": -4, "type": "left", "pickup": false },
    { "x": -3, "y": -5, "type": "left", "pickup": false },
    { "x": -4, "y": -7, "type": "lefttoright", "pickup": false },
    { "x": -5, "y": -7, "type": "voidright", "pickup": false },
    { "x": -6, "y": -7, "type": "right", "pickup": false },
    { "x": -7, "y": -7, "type": "righttop", "pickup": false },
    { "x": -8, "y": -8, "type": "top", "pickup": false },
    { "x": -9, "y": -9, "type": "topright", "pickup": false },
    { "x": -10, "y": -9, "type": "voidright", "pickup": false },
    { "x": -11, "y": -9, "type": "righttop", "pickup": false },
    { "x": -12, "y": -10, "type": "topleft", "pickup": false },
    { "x": -12, "y": -11, "type": "left", "pickup": false },
    { "x": -12, "y": -12, "type": "voidleft", "pickup": false },
    { "x": -12, "y": -13, "type": "left", "pickup": false },
    { "x": -12, "y": -14, "type": "left", "pickup": false },
    { "x": -12, "y": -15, "type": "left", "pickup": false },
    { "x": -12, "y": -16, "type": "voidleft", "pickup": false },
    { "x": -13, "y": -18, "type": "lefttoright", "pickup": false },
    { "x": -22, "y": -22, "type": "plateforme", "pickup": false },

    /* Fake path */
    { "x": -18, "y": -13, "type": "left", "pickup": false }, 
    { "x": -18, "y": -25, "type": "leftbottorightbot", "pickup": false },

    { "x": -18, "y": -23, "type": "left", "pickup": false },

    { "x": -18, "y": -8, "type": "left", "pickup": false },
    { "x": -18, "y": -9, "type": "left", "pickup": false },
    { "x": -18, "y": -10, "type": "left", "pickup": false },
    { "x": -18, "y": -11, "type": "left", "pickup": false },
    { "x": -18, "y": -12, "type": "left", "pickup": false },

    { "x": -16, "y": -25, "type": "left", "pickup": false },
    { "x": -15, "y": -25, "type": "left", "pickup": false },
    /* */

    {
        "x": -31,
        "y": -31,
        "type": "gate2",
        "move": false,
        "pickup": false,
    },
    { "x": -23, "y": -18, "type": "right", "pickup": false, "opts": "end" },
];

export default district;