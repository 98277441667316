export const tilesTypes = new Map();

/*
**    TL - T - TR
**    |         |
**    L         R
**    |         |
**    BL - B - BR
*/

tilesTypes.set("right", {
    "long": 1,
    "larg": 1,
    "path": [
        { x: 1, y: 0.5 },
        { x: 0, y: 0.5 },
    ],
    "in":"BL",
    "out":"TR",
    "preload": 5,
    "faceto": "right"
});

tilesTypes.set("left", {
    "long": 1,
    "larg": 1,
    "path": [
        { x: 0.5, y: 1 },
        { x: 0.5, y: 0 },
    ],
    "in":"BR",
    "out":"TL",
    "preload": 5,
    "faceto": "left"
});

tilesTypes.set("rightdown", {
    "long": 1,
    "larg": 1,
    "path": [
        { x: 0, y: 0.5 },
        { x: 1, y: 0.5 },
    ],
    "in":"TL",
    "out":"BR"
});

tilesTypes.set("leftdown", {
    "long": 1,
    "larg": 1,
    "path": [
        { x: 0.5, y: 0 },
        { x: 0.5, y: 1 },
    ],
    "in":"TR",
    "out":"BL"
});

/* Vertical */
tilesTypes.set("righttop", {
    "long": 1,
    "larg": 1,
    "path": [
        { x: 1, y: 0.5 },
        { x: 0.5, y: 0.5 },
        { x: 0, y: 0 },
    ],
    "in":"BL",
    "out":"T",
    "preload": 5,
    "faceto": "left"
});

tilesTypes.set("lefttop", {
    "long": 1,
    "larg": 1,
    "path": [
        { x: 0.5, y: 1 },
        { x: 0.5, y: 0.5 },
        { x: 0, y: 0 },
    ],
    "in":"BR",
    "out":"T",
    "preload": 5,
    "faceto": "right"
});

tilesTypes.set("topright", {
    "long": 1,
    "larg": 1,
    "path": [
        { x: 1, y: 1 },
        { x: 0.5, y: 0.5 },
        { x: 0, y: 0.5 },
    ],
    "in":"B",
    "out":"TR",
    "preload": 5,
    "faceto": "right"
});

tilesTypes.set("topleft", {
    "long": 1,
    "larg": 1,
    "path": [
        { x: 1, y: 1 },
        { x: 0.5, y: 0.5 },
        { x: 0.5, y: 0 },
    ],
    "in":"B",
    "out":"TL",
    "preload": 5,
    "faceto": "left"
});

tilesTypes.set("top", {
    "long": 1,
    "larg": 1,
    "path": [
        { x: 1, y: 1 },
        { x: 0, y: 0 },
    ],
    "in":"B",
    "out":"T",
    "preload": 5,
    "faceto": "left"
});

/* Corner */
tilesTypes.set("righttoleft", { // >
    "long": 2,
    "larg": 2,
    "path": [
        { x: 2, y: 1.5 },
        { x: 1, y: 1.5 },
        { x: 0.5, y: 1 },
        { x: 0.5, y: 0 },
    ],
    "in":"BL",
    "out":"TL",
    "preload": 3,
    "faceto": "right"
});

tilesTypes.set("lefttoright", { // <
    "long": 2,
    "larg": 2,
    "path": [
        { x: 1.5, y: 2 },
        { x: 1.5, y: 1 },
        { x: 1, y: 0.5 },
        { x: 0, y: 0.5 },
    ],
    "in":"BR",
    "out":"TR",
    "preload": 3,
    "faceto": "left"
});

tilesTypes.set("leftbottorightbot", { // /\ => ok
    "long": 2,
    "larg": 2,
    "path": [
        { x: 2, y: 0.5 },
        { x: 1, y: 0.5 },
        { x: 0.5, y: 1 },
        { x: 0.5, y: 2 },
    ],
    "in":"BL",
    "out":"BR",
    "preload": 2,
    "faceto": "right"
});

tilesTypes.set("rightbottoleftbot", { // /\ <= ok
    "long": 2,
    "larg": 2,
    "path": [
        { x: 0.5, y: 2 },
        { x: 0.5, y: 1 },
        { x: 1, y: 0.5 },
        { x: 2, y: 0.5 },
    ],
    "in":"BR",
    "out":"BL",
    "preload": 2,
    "faceto": "left"
});

tilesTypes.set("righttoptolefttop", { // \/ <=
    "long": 2,
    "larg": 2,
    "path": [
        { x: 0, y: 1.5 },
        { x: 1, y: 1.5 },
        { x: 1.5, y: 1 },
        { x: 1.5, y: 0 },        
    ],
    "in":"TR",
    "out":"TL",
    "preload": 2,
    "faceto": "right"
});

tilesTypes.set("lefttoptorighttop", { // \/ =>
    "long": 2,
    "larg": 2,
    "path": [
        { x: 1.5, y: 0 },
        { x: 1.5, y: 1 },
        { x: 1, y: 1.5 },
        { x: 0, y: 1.5 },
    ],
    "in":"TL",
    "out":"TR",
    "preload": 2,
    "faceto": "left"
});

/* Void */
tilesTypes.set("voidleft", {
    "long": 1,
    "larg": 1,
    "path": [
        { x: 0.5, y: 1, void: true },
        { x: 0.5, y: 0, void: true },
    ],
    "in":"BR",
    "out":"TL",
    "preload": 3,
    "faceto": "left"
});

tilesTypes.set("voidright", {
    "long": 1,
    "larg": 1,
    "path": [
        { x: 1, y: 0.5, void: true },
        { x: 0, y: 0.5, void: true },
    ],
    "in":"BL",
    "out":"TR",
    "preload": 3,
    "faceto": "right"
});

tilesTypes.set("voidleftdown", {
    "long": 1,
    "larg": 1,
    "path": [
        { x: 0.5, y: 0, void: true },
        { x: 0.5, y: 1, void: true },
    ],
    "in":"TR",
    "out":"BL"
});

tilesTypes.set("voidrightdown", {
    "long": 1,
    "larg": 1,
    "path": [
        { x: 0, y: 0.5, void: true },
        { x: 1, y: 0.5, void: true },
    ],
    "in":"TL",
    "out":"BR"
});

tilesTypes.set("voidbottop", {
    "long": 1,
    "larg": 1,
    "path": [
        { x: 1, y: 1, void: true },
        { x: 0.3, y: 0.3, void: false },
        { x: 0, y: 0, void: false },
    ],
    "in":"B",
    "out":"T",
    "preload": 3,
    "faceto": "left"
});

tilesTypes.set("plateforme", {
    "long": 9,
    "larg": 9,
    "path": [
        { x: 9, y: 4.5 },
        { x: 0, y: 4.5 },
    ],
    "in":"BL",
    "out":"TR",
    "preload": 1
});

tilesTypes.set("plateforme3214", {
    "long": 9,
    "larg": 9,
    "path": [
        { x: 9, y: 4.5 },
        { x: 7.5, y: 4.5 },
        { x: 7.2, y: 5.5 },
        { x: 6.5, y: 6.5 },
        { x: 5.5, y: 7.2 },
        { x: 4.5, y: 7.5 },
        { x: 3.5, y: 7.2 },
        { x: 2.5, y: 6.5 },
        { x: 1.8, y: 5.5 },
        { x: 1.5, y: 4.5 },
        { x: 1.8, y: 3.5 },
        { x: 2.5, y: 2.5 },
        { x: 3.5, y: 1.8 },
        { x: 4.5, y: 1.5 },
        { x: 4.5, y: 0 }
    ],
    "in":"BL",
    "out":"TL",
    "preload": 1
});

tilesTypes.set("plateforme321", {
    "long": 9,
    "larg": 9,
    "path": [
        { x: 9, y: 4.5 },
        { x: 7.5, y: 4.5 },
        { x: 7.2, y: 5.5 },
        { x: 6.5, y: 6.5 },
        { x: 5.5, y: 7.2 },
        { x: 4.5, y: 7.5 },
        { x: 3.5, y: 7.2 },
        { x: 2.5, y: 6.5 },
        { x: 1.8, y: 5.5 },
        { x: 1.5, y: 4.5 },
        { x: 0, y: 4.5 }
    ],
    "in":"BL",
    "out":"TR",
    "preload": 1
});

tilesTypes.set("plateforme214", {
    "long": 9,
    "larg": 9,
    "path": [
        { x: 4.5, y: 9 },
        { x: 4.5, y: 7.5 },
        { x: 3.5, y: 7.2 },
        { x: 2.5, y: 6.5 },
        { x: 1.8, y: 5.5 },
        { x: 1.5, y: 4.5 },
        { x: 1.8, y: 3.5 },
        { x: 2.5, y: 2.5 },
        { x: 3.5, y: 1.8 },
        { x: 4.5, y: 1.5 },
        { x: 4.5, y: 0 }
    ],
    "in":"BR",
    "out":"TL",
    "preload": 1
});

// Cognac :
tilesTypes.set("gate1", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
    "name":"cognac-welcomeToCognac",
    "offsetV": -15
});

tilesTypes.set("gate2", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
    "name":"cognac-goodGame",
    "offsetV": -15
});

// Paris :
tilesTypes.set("eiffeltower", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
	"name":"paris-eiffelTower",
    "offsetV": -40
});

tilesTypes.set("arcdetriomphe", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
	"name":"paris-arcDeTriomphe",
    "offsetV": -8
});

tilesTypes.set("sacrecoeur", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
	"name":"paris-invalides",
    "offsetV": -12
});

tilesTypes.set("haussmanbuilding", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
	"name":"paris-haussmannBuilding",
    "offsetV": -8
});

// Hainan :
tilesTypes.set("haikouarcanedstreets", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
	"name":"hainan-haikouArcadedStreets",
    "offsetV": -8
});

tilesTypes.set("haikoucenturybridge", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
	"name":"hainan-haikouCenturyBridge",
    "offsetV": -2
});

tilesTypes.set("sanyaphoenixislandresort", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
	"name":"hainan-sanyaPhoenixIslandHotel",
    "offsetV": -20
});

tilesTypes.set("tianyahaijiao", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
	"name":"hainan-tianyaHaijiao",
    "offsetV": -6
});

// Macau :
tilesTypes.set("lisboahotel", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
	"name":"macau-lisboaHotel",
    "offsetV": -18
});

tilesTypes.set("macautower", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
	"name":"macau-macauTower",
    "offsetV": -30
});

tilesTypes.set("venetianhotel", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
	"name":"macau-venetianHotel"
});

tilesTypes.set("zhuhaibridge", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
	"name":"macau-hongkongZhuhaiMacauBridge",
    "offsetV": -10
});

// HongKong :
tilesTypes.set("bankofchina", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
	"name":"hongkong-bankOfChinaTower",
    "offsetV": -33
});

tilesTypes.set("clocktower", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
	"name":"hongkong-cantonRailwayClockTower",
    "offsetV": -33
});

tilesTypes.set("junk", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
	"name":"hongkong-traditionalJunkBoat",
    "offsetV": -8
});

tilesTypes.set("tram", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
	"name":"hongkong-victoriasPeakTram",
    "offsetV": 0
});

// Singapore :
tilesTypes.set("archesvegetales", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
	"name":"singapore-botanicGardens",
    "offsetV": 2
});

tilesTypes.set("imaginarybuilding", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
	"name":"singapore-vegetalBuilding",
    "offsetV": 0
});

tilesTypes.set("shophouse", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
	"name":"singapore-shopHouse",
    "offsetV": -15
});

tilesTypes.set("whiteblackhouse", {
    "long": 13,
    "larg": 13,
    "foreground": true,
    "preload": 2,
	"name":"singapore-whiteBlackHouse",
    "offsetV": 2
});