import * as THREE from "three";
import { FiktivEngine, update_input_type } from "../../../../fiktivengine_modules/fiktivengine-core/fiktiv";
import { Lendless } from "../../level/Lendless";

export class AnimatedJonqueContainer {
    public container: THREE.Object3D;

    public textureJonque?: THREE.Texture;
    public materialJonque?: THREE.SpriteMaterial;

    public jonque?: THREE.Sprite;

    public vertical?: number;

    constructor(
        fe?: FiktivEngine
    ) {
        this.container = new THREE.Object3D();
        let textures = (fe?.map as Lendless).preload.assetList;

        // Background
        for (let asset of textures) {
            if (asset.name === "decor_jonque") {
                if (asset.file) {
                    this.materialJonque = new THREE.SpriteMaterial({
                        map: asset.file,
                        depthWrite: true,
                        depthTest: true,
                    });

                    this.jonque = new THREE.Sprite(this.materialJonque);
                    this.jonque.position.set(-21.4, -21.4, -10);
                    this.jonque.scale.set(16, 16 / 0.187/*1.19*/, 1);
                    this.container.add(this.jonque);

                    this.vertical = 0.006;
                }
            }
        }
    }

    updateLocal = (args: update_input_type) => {
        if (this.jonque && this.jonque.position && this.vertical) {
            if (this.jonque.position.x > -5) {
                this.jonque.position.x = -35;
                this.jonque.position.y = -23;
            }
            this.jonque.position.x += 0.04;
            this.jonque.position.y -= 0.02;

            this.jonque.position.z += this.vertical;

            if (this.jonque.position.z < -10.1 && this.vertical < 0) {
                this.vertical *= -1;
            } else if (this.jonque.position.z > -9.9 && this.vertical > 0) {
                this.vertical *= -1;
            }
        }
    }
}