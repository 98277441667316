import * as THREE from "three";
import { FiktivEngine, update_input_type } from "../../../../fiktivengine_modules/fiktivengine-core/fiktiv";
import { Lendless } from "../../level/Lendless";

export class AnimatedCarsContainer {
    public container: THREE.Object3D;

    public textureCars?: THREE.Texture;
    public materialCars?: THREE.SpriteMaterial;
    public textureForeground1?: THREE.Texture;
    public materialForeground1?: THREE.SpriteMaterial;
    public textureForeground2?: THREE.Texture;
    public materialForeground2?: THREE.SpriteMaterial;

    public rame?: THREE.Sprite;

    constructor(
        fe?: FiktivEngine
    ) {
        this.container = new THREE.Object3D();
        let textures = (fe?.map as Lendless).preload.assetList;

        // Background
        for (let asset of textures) {
            if (asset.name === "METRO2") {
                if (asset.file) {
                    this.materialCars = new THREE.SpriteMaterial({
                        map: asset.file,
                        depthWrite: true,
                        depthTest: true,
                    });

                    this.rame = new THREE.Sprite(this.materialCars);
                    this.rame.position.set(-21.4, -21.4, -10);
                    this.rame.scale.set(16, 16 / 0.187/*1.19*/, 1);
                    this.container.add(this.rame);
                }
            }
        }

        // Foreground
        for (let asset of textures) {
            if (asset.name === "decor_haikou_bridge_1") {
                if (asset.file) {
                    this.materialForeground1 = new THREE.SpriteMaterial({
                        map: asset.file,
                        depthWrite: true,
                        depthTest: true,
                    });

                    let foreground1 = new THREE.Sprite(this.materialForeground1);
                    foreground1.position.set(-16.2, -16.2, -5.1);
                    foreground1.scale.set(80 / 5.34, 80, 1);
                    this.container.add(foreground1);
                }
            }
        }

        for (let asset of textures) {
            if (asset.name === "decor_haikou_bridge_2") {
                if (asset.file) {
                    this.materialForeground2 = new THREE.SpriteMaterial({
                        map: asset.file,
                        depthWrite: true,
                        depthTest: true,
                    });

                    let foreground2 = new THREE.Sprite(this.materialForeground2);
                    foreground2.position.set(-16.2, -16.2, -5.1);
                    foreground2.scale.set(80 / 5.34, 80, 1);
                    this.container.add(foreground2);
                }
            }
        }
    }

    updateLocal = (args: update_input_type) => {
        if (this.rame && this.rame.position) {
            if (this.rame.position.x > -5) {
                this.rame.position.x = -35;
            }
            this.rame.position.x += 0.1;
        }
    }
}