export class KeyBinding {
  public eventCodes: string;
  public isPressed: boolean;
  public justPressed: boolean;
  public justReleased: boolean;
  public pos: any;
  constructor(code: string) {
    this.eventCodes = code;
    this.isPressed = false;
    this.justPressed = false;
    this.justReleased = false;
    this.pos = undefined;
  }
}
