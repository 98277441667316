import * as THREE from "three";
import { FiktivObject3D, update_input_type } from "../../../../fiktivengine_modules/fiktivengine-core";

export class AnimatedSprite extends FiktivObject3D {
    public sprite: THREE.Sprite;
    public currentTile: number;
    public repeat?: number;
    public currentRepeat?: number;
    public lastRefresh: number;
    public duration: number;
    public name: string;
    public syncList: any;

    constructor(
        syncList: any,
        map: THREE.Texture,
        data?: any,
        speed?: number,
        repeat?: number,
        sens: number = -1
    ) {
        super(new THREE.Vector3(0, 0, 0));
        map.needsUpdate = true;

        map.offset.set(1, 0);

        if (data === undefined) {
            data = {
                w: 5,
                h: 4,
                currentTile: 0,
                max: 19
            }
        }

        this.name = "";
        this.currentTile = data.currentTile;
        this.syncList = syncList;

        this.repeat = repeat;
        this.currentRepeat = repeat;

        this.duration = speed || 12;

        map.wrapS = map.wrapT = THREE.RepeatWrapping;
        map.repeat.set(sens / data.w, 1 / data.h);

        let updateT = () => {
            if (sens < 0) {
                let iColumn = this.currentTile % data.w + 1;
                map.offset.x = iColumn / data.w;
                let iRow = Math.floor(this.currentTile / data.w + 1);
                map.offset.y = data.h - (iRow / data.h);
            } else {
                let iColumn = data.w - (this.currentTile % data.w);
                map.offset.x = data.w - iColumn / data.w;
                let iRow = Math.floor(this.currentTile / data.w);
                map.offset.y = data.h - (iRow / data.h);
            }

        }

        let material = new THREE.SpriteMaterial({ map: map });
        this.sprite = new THREE.Sprite(material);

        this.lastRefresh = 0;

        this.syncList.push(this);

        this.update = (args: update_input_type) => {
            this.lastRefresh += args.timestep;
            if (this.lastRefresh > this.duration * 0.001) {
                this.lastRefresh -= this.duration * 0.001;

                if (this.currentRepeat !== 0) {
                    if (this.currentTile > data.max - 1) {
                        this.currentTile = 0;
                        if (this.currentRepeat !== undefined) {
                            this.currentRepeat--;
                        }
                    } else {
                        this.currentTile += 1
                    }
                }
            }
            updateT();
        }
    }

    reset = () => {
        this.currentTile = 0;
        this.currentRepeat = this.repeat;
        this.lastRefresh = 0;
    }

    stop = () => {
        this.syncList.slice(this.syncList.indexOf(this), 1);
    }
}