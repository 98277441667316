// Modules
import * as THREE from "three";
// GJ modules
import { FiktivObject3D, Controller, update_input_type, FiktivEngine } from "../../../../fiktivengine_modules/fiktivengine-core/fiktiv";

import { Lendless } from "../../level/Lendless";
import { AnimatedSprite } from "../../actors/utils/AnimatedSprite";

export class Model extends FiktivObject3D {
    // Bases
    private _fe: FiktivEngine;
    private _controller: Controller | undefined; //Controller must be set

    // Model parts
    public meshPlayerContainer: THREE.Object3D;
    public meshPlayerBodyRun?: THREE.Object3D;
    public meshPlayerBodyJump?: THREE.Object3D;
    public meshPlayerBodyDoubleJump?: THREE.Object3D;
    public meshPlayerCognacBodyRun?: THREE.Object3D;
    public meshPlayerCognacBodyJump?: THREE.Object3D;
    public meshPlayerCognacBodyDoubleJump?: THREE.Object3D;
    public meshShadow: THREE.Mesh;
    public meshDisparition?: THREE.Object3D;
    public meshSmoke?: AnimatedSprite;

    // Movement variables
    public position: THREE.Vector3;
    public angleLook: number;
    public angleTwirl: number;

    // Animation
    public modelRunReady: boolean;
    public modelJumpReady: boolean;
    public modelDoubleJumpReady: boolean;
    public modelCognacRunReady: boolean;
    public modelCognacJumpReady: boolean;
    public modelCognacDoubleJumpReady: boolean;
    public mixerRun?: THREE.AnimationMixer;
    public mixerJump?: THREE.AnimationMixer;
    public mixerDoubleJump?: THREE.AnimationMixer;
    public mixerCognacRun?: THREE.AnimationMixer;
    public mixerCognacJump?: THREE.AnimationMixer;
    public mixerCognacDoubleJump?: THREE.AnimationMixer;
    public activeAction?: THREE.AnimationAction;
    public lastAction?: THREE.AnimationAction;
    public animationActions: THREE.AnimationAction[];

    // Getter/Setter
    set fe(fe: FiktivEngine) { this._fe = fe; }
    get fe(): FiktivEngine { return this._fe; }

    set controller(controller: Controller | undefined) { this._controller = controller; }
    get controller(): Controller | undefined { return this._controller; }

    // Constructor
    constructor(
        fe: FiktivEngine,
        pos: THREE.Vector3,
        quat: THREE.Quaternion,
    ) {
        super(pos, quat, undefined, undefined, false, 5);

        this._fe = fe;

        // Default Material
        var defaultMaterial = new THREE.MeshStandardMaterial({
            color: 0xffffff,
            depthWrite: true,
            depthTest: true,
            metalness: 0.8,
            opacity: 1,
        });

        // Player container
        var shapePlayerContainer = new THREE.BoxGeometry(0.01, 0.01, 0.01, 1, 1, 1);
        shapePlayerContainer.center();
        this.meshPlayerContainer = new THREE.Mesh(shapePlayerContainer, defaultMaterial);
        this.meshPlayerContainer.position.copy(pos);
        this.meshPlayerContainer.quaternion.copy(quat);

        this.meshPlayerContainer.add(new THREE.AmbientLight(0xffffff, 2))

        this.mesh = this.meshPlayerContainer;
        this.fe.scene.add(this.mesh);

        // Player body
        var shapePlayerBody = new THREE.BoxGeometry(0.5, 0.5, 0.5, 1, 1, 1);
        shapePlayerBody.center();

        this.modelRunReady = false;
        this.modelJumpReady = false;
        this.modelDoubleJumpReady = false;
        this.modelCognacRunReady = false;
        this.modelCognacJumpReady = false;
        this.modelCognacDoubleJumpReady = false;
        this.animationActions = [];

        let city = (this.fe?.map as Lendless).city || "paris";

        let assetList = (this.fe.map as Lendless).preload.assetList;
        for (let texture of assetList) {
            if (texture.name === 'running') {
                console.log("model 1")
                let object = texture.file.scene;
                this.mixerRun = new THREE.AnimationMixer(object)

                this.meshPlayerBodyRun = new THREE.Object3D();
                this.meshPlayerBodyRun.add(object);

                this.meshPlayerBodyRun.rotation.set(90 / 180 * Math.PI, 90 / 180 * Math.PI, 0, "XYZ");
                this.meshPlayerBodyRun.scale.set(5.8, 5.8, 5.8);
                this.meshPlayerBodyRun.position.set(0, 0, 0);

                this.meshPlayerContainer.add(this.meshPlayerBodyRun)

                const animationAction = this.mixerRun.clipAction(texture.file.animations[0]);
                animationAction.timeScale = 1.2;
                this.animationActions.push(animationAction)

                this.modelRunReady = true;
                this.activeAction = animationAction;
                this.activeAction.play();
            }

            if (texture.name === 'jumping') {
                console.log("model 2")
                let object = texture.file.scene;
                this.mixerJump = new THREE.AnimationMixer(object)

                this.meshPlayerBodyJump = new THREE.Object3D();
                this.meshPlayerBodyJump.add(object);

                this.meshPlayerBodyJump.rotation.set(90 / 180 * Math.PI, 90 / 180 * Math.PI, 0, "XYZ");
                this.meshPlayerBodyJump.scale.set(5.8, 5.8, 5.8);
                this.meshPlayerBodyJump.position.set(0, 0, 0);

                const animationAction = this.mixerJump.clipAction(texture.file.animations[0])
                animationAction.setLoop(THREE.LoopOnce, 1);
                animationAction.timeScale = 1.8;
                this.mixerJump.addEventListener('finished', (e: any) => {
                    this.jump(false);
                    this.setAction(this.animationActions[0])
                })
                this.animationActions.push(animationAction)

                this.modelJumpReady = true;
            }

            if (texture.name === 'doublejumping') {
                console.log("model 3")
                let object = texture.file.scene;
                this.mixerDoubleJump = new THREE.AnimationMixer(object)

                this.meshPlayerBodyDoubleJump = new THREE.Object3D();
                this.meshPlayerBodyDoubleJump.add(object);

                this.meshPlayerBodyDoubleJump.rotation.set(90 / 180 * Math.PI, 90 / 180 * Math.PI, 0, "XYZ");
                this.meshPlayerBodyDoubleJump.scale.set(5.8, 5.8, 5.8);
                this.meshPlayerBodyDoubleJump.position.set(0, 0, 0);

                const animationAction = this.mixerDoubleJump.clipAction(texture.file.animations[0])
                animationAction.setLoop(THREE.LoopOnce, 1);
                animationAction.timeScale = 1.2;
                this.mixerDoubleJump.addEventListener('finished', (e: any) => {
                    this.doubleJump(false);
                    this.setAction(this.animationActions[0])
                });
                this.animationActions.push(animationAction);

                this.modelDoubleJumpReady = true;
            }

            if (texture.name === 'runningCognac') {
                console.log("model 4")
                let object = texture.file.scene;
                this.mixerCognacRun = new THREE.AnimationMixer(object)

                this.meshPlayerCognacBodyRun = new THREE.Object3D();
                this.meshPlayerCognacBodyRun.add(object);

                this.meshPlayerCognacBodyRun.rotation.set(90 / 180 * Math.PI, 90 / 180 * Math.PI, 0, "XYZ");
                this.meshPlayerCognacBodyRun.scale.set(5.8, 5.8, 5.8);
                this.meshPlayerCognacBodyRun.position.set(0, 0, 0);

                const animationAction = this.mixerCognacRun.clipAction(texture.file.animations[0]);
                animationAction.timeScale = 1.2;
                this.animationActions.push(animationAction)

                this.modelCognacRunReady = true;
            }

            if (texture.name === 'jumpingCognac') {
                console.log("model 5")
                let object = texture.file.scene;
                this.mixerCognacJump = new THREE.AnimationMixer(object)

                this.meshPlayerCognacBodyJump = new THREE.Object3D();
                this.meshPlayerCognacBodyJump.add(object);

                this.meshPlayerCognacBodyJump.rotation.set(90 / 180 * Math.PI, 90 / 180 * Math.PI, 0, "XYZ");
                this.meshPlayerCognacBodyJump.scale.set(5.8, 5.8, 5.8);
                this.meshPlayerCognacBodyJump.position.set(0, 0, 0);

                const animationAction = this.mixerCognacJump.clipAction(texture.file.animations[0])
                animationAction.setLoop(THREE.LoopOnce, 1);
                animationAction.timeScale = 1.8;
                this.mixerCognacJump.addEventListener('finished', (e: any) => {
                    this.jump(false);
                    this.setAction(this.animationActions[3])
                })
                this.animationActions.push(animationAction)

                this.modelCognacJumpReady = true;
            }

            if (texture.name === 'doublejumpingCognac') {
                console.log("model 6")
                let object = texture.file.scene;
                this.mixerCognacDoubleJump = new THREE.AnimationMixer(object)

                this.meshPlayerCognacBodyDoubleJump = new THREE.Object3D();
                this.meshPlayerCognacBodyDoubleJump.add(object);

                this.meshPlayerCognacBodyDoubleJump.rotation.set(90 / 180 * Math.PI, 90 / 180 * Math.PI, 0, "XYZ");
                this.meshPlayerCognacBodyDoubleJump.scale.set(5.8, 5.8, 5.8);
                this.meshPlayerCognacBodyDoubleJump.position.set(0, 0, 0);

                const animationAction = this.mixerCognacDoubleJump.clipAction(texture.file.animations[0])
                animationAction.setLoop(THREE.LoopOnce, 1);
                animationAction.timeScale = 1.2;
                this.mixerCognacDoubleJump.addEventListener('finished', (e: any) => {
                    this.doubleJump(false);
                    this.setAction(this.animationActions[3])
                });
                this.animationActions.push(animationAction);

                this.modelCognacDoubleJumpReady = true;
            }
        }

        // Player shadow
        var materialShadow = new THREE.MeshStandardMaterial({
            color: 0x2C2199,
            depthWrite: true,
            depthTest: true,
            metalness: 1,
            opacity: 0.2,
            transparent: true
        });
        var shapeShadow = new THREE.CircleGeometry(0.2, 16); //new THREE.BoxGeometry(0.5, 0.5, 0.01, 1, 1, 1);
        shapeShadow.center();
        this.meshShadow = new THREE.Mesh(shapeShadow, materialShadow);
        this.fe.scene.add(this.meshShadow);

        // Player disparition
        let pool = (this.fe?.map as Lendless).poolManager;
        if (pool) {
            pool.createPool("smokeAnim");
            this.meshSmoke = pool.changeStatus("smokeAnim", undefined, true);
        }
        if (!this.meshSmoke) {
            let assets = (this.fe.map as Lendless).preload.assetList;
            for (let asset of assets) {
                if (asset.name === "smoke") {
                    let data = {
                        w: 8,
                        h: 4,
                        currentTile: 0,
                        max: 32
                    };
                    this.meshSmoke = new AnimatedSprite(this.fe.syncList, asset.file.clone(), data, 12, 1);
                    this.meshDisparition = new THREE.Object3D();
                    this.meshDisparition.add(this.meshSmoke.sprite);

                    this.meshSmoke.name = "smokeAnim";
                    pool?.pushInPool("smokeAnim", this.meshSmoke, true);
                    break;
                }
            }
        } else {
            this.meshDisparition = new THREE.Object3D();
            this.meshDisparition.add(this.meshSmoke.sprite);
        }

        // Movement variables init
        this.position = pos;
        this.angleLook = 0;
        this.angleTwirl = 0;

        // Update
        this.update = (args: update_input_type) => {
            if (this.mixerRun && this.mixerJump && this.mixerDoubleJump && this.mixerCognacRun && this.mixerCognacJump && this.mixerCognacDoubleJump) {
                this.mixerRun.update(args.timestep)
                this.mixerJump.update(args.timestep)
                this.mixerDoubleJump.update(args.timestep)
                this.mixerCognacRun.update(args.timestep)
                this.mixerCognacJump.update(args.timestep)
                this.mixerCognacDoubleJump.update(args.timestep)
            } else {
                console.warn("Animation don't ready yet !")
            }

            // Looking direction
            this.meshPlayerContainer.rotation.z = this.angleLook;
            this.meshShadow.rotation.z = this.angleLook;

            // Moving
            this.meshPlayerContainer.position.set(this.position.x, this.position.y, this.position.z);
            this.meshShadow.position.set(this.meshPlayerContainer.position.x, this.meshPlayerContainer.position.y, 0);
        }
    }

    // Animation
    setAction(toAction: THREE.AnimationAction) {
        if (toAction !== this.activeAction) {
            this.lastAction = this.activeAction
            this.activeAction = toAction;
            this.lastAction?.stop();
            //lastAction.fadeOut(1)
            this.activeAction.reset();
            //activeAction.fadeIn(1)
            this.activeAction.play();
        }
    }

    jump(isJumping: boolean) {
        let cognac = (this.fe.map as Lendless).cognac;

        if (isJumping) {
            console.log("jump")
            if (this.meshPlayerBodyRun) {
                this.meshPlayerContainer.remove(this.meshPlayerBodyRun)
            }
            if (this.meshPlayerBodyDoubleJump) {
                this.meshPlayerContainer.remove(this.meshPlayerBodyDoubleJump)
            }

            if (this.meshPlayerCognacBodyRun) {
                this.meshPlayerContainer.remove(this.meshPlayerCognacBodyRun)
            }
            if (this.meshPlayerCognacBodyDoubleJump) {
                this.meshPlayerContainer.remove(this.meshPlayerCognacBodyDoubleJump)
            }

            if (this.meshPlayerBodyJump && this.meshPlayerCognacBodyJump) {
                if (cognac) {
                    this.meshPlayerContainer.remove(this.meshPlayerBodyJump);
                    this.meshPlayerContainer.add(this.meshPlayerCognacBodyJump);
                } else {
                    this.meshPlayerContainer.remove(this.meshPlayerCognacBodyJump);
                    this.meshPlayerContainer.add(this.meshPlayerBodyJump);
                }
            }

            if (this.animationActions[1] && this.animationActions[4]) {
                this.setAction(this.animationActions[cognac ? 4 : 1]);
            }

            let sound = (this.fe.map as Lendless).sounddesign?.get('SD_jump');
            if (sound) {
                sound.play();
            }
        } else {
            if (this.meshPlayerBodyJump) {
                this.meshPlayerContainer.remove(this.meshPlayerBodyJump)
            }
            if (this.meshPlayerBodyDoubleJump) {
                this.meshPlayerContainer.remove(this.meshPlayerBodyDoubleJump)
            }

            if (this.meshPlayerCognacBodyJump) {
                this.meshPlayerContainer.remove(this.meshPlayerCognacBodyJump)
            }
            if (this.meshPlayerCognacBodyDoubleJump) {
                this.meshPlayerContainer.remove(this.meshPlayerCognacBodyDoubleJump)
            }

            if (this.meshPlayerBodyRun && this.meshPlayerCognacBodyRun) {
                if (cognac) {
                    this.meshPlayerContainer.remove(this.meshPlayerBodyRun);
                    this.meshPlayerContainer.add(this.meshPlayerCognacBodyRun);
                } else {
                    this.meshPlayerContainer.remove(this.meshPlayerCognacBodyRun);
                    this.meshPlayerContainer.add(this.meshPlayerBodyRun);
                }
            }

            if (this.animationActions[0] && this.animationActions[3]) {
                this.setAction(this.animationActions[cognac ? 3 : 0]);
            }
        }
    }

    doubleJump(isJumping: boolean) {
        let cognac = (this.fe.map as Lendless).cognac;

        if (isJumping) {
            if (this.meshPlayerBodyRun) {
                this.meshPlayerContainer.remove(this.meshPlayerBodyRun)
            }
            if (this.meshPlayerBodyJump) {
                this.meshPlayerContainer.remove(this.meshPlayerBodyJump)
            }

            if (this.meshPlayerCognacBodyRun) {
                this.meshPlayerContainer.remove(this.meshPlayerCognacBodyRun)
            }
            if (this.meshPlayerCognacBodyJump) {
                this.meshPlayerContainer.remove(this.meshPlayerCognacBodyJump)
            }

            if (this.meshPlayerBodyDoubleJump && this.meshPlayerCognacBodyDoubleJump) {
                if (cognac) {
                    this.meshPlayerContainer.remove(this.meshPlayerBodyDoubleJump);
                    this.meshPlayerContainer.add(this.meshPlayerCognacBodyDoubleJump);
                } else {
                    this.meshPlayerContainer.remove(this.meshPlayerCognacBodyDoubleJump);
                    this.meshPlayerContainer.add(this.meshPlayerBodyDoubleJump);
                }
            }

            if (this.animationActions[2] && this.animationActions[5]) {
                this.setAction(this.animationActions[cognac ? 5 : 2]);
            }

            let sound = (this.fe.map as Lendless).sounddesign?.get('SD_doublejump');
            if (sound) {
                sound.play();
            }
        } else {
            if (this.meshPlayerBodyJump) {
                this.meshPlayerContainer.remove(this.meshPlayerBodyJump)
            }
            if (this.meshPlayerBodyDoubleJump) {
                this.meshPlayerContainer.remove(this.meshPlayerBodyDoubleJump)
            }

            if (this.meshPlayerCognacBodyJump) {
                this.meshPlayerContainer.remove(this.meshPlayerCognacBodyJump)
            }
            if (this.meshPlayerCognacBodyDoubleJump) {
                this.meshPlayerContainer.remove(this.meshPlayerCognacBodyDoubleJump)
            }

            if (this.meshPlayerBodyRun && this.meshPlayerCognacBodyRun) {
                if (cognac) {
                    this.meshPlayerContainer.remove(this.meshPlayerBodyRun);
                    this.meshPlayerContainer.add(this.meshPlayerCognacBodyRun);
                } else {
                    this.meshPlayerContainer.remove(this.meshPlayerCognacBodyRun);
                    this.meshPlayerContainer.add(this.meshPlayerBodyRun);
                }
            }

            if (this.animationActions[0] && this.animationActions[3])
                this.setAction(this.animationActions[cognac ? 3 : 0]);
        }
    }

    changeCognac() {
        let cognac = (this.fe.map as Lendless).cognac;

        if (this.meshPlayerBodyRun && this.meshPlayerCognacBodyRun) {
            if (cognac) {
                this.meshPlayerContainer.remove(this.meshPlayerBodyRun);
                this.meshPlayerContainer.add(this.meshPlayerCognacBodyRun);
            } else {
                this.meshPlayerContainer.remove(this.meshPlayerCognacBodyRun);
                this.meshPlayerContainer.add(this.meshPlayerBodyRun);
            }
        }

        if (this.animationActions[0] && this.animationActions[3])
            this.setAction(this.animationActions[cognac ? 3 : 0]);
    }

    lookTo(dir: string, dt: number) {
        switch (dir) {
            case "left":
                this.angleLook += 2 * dt;
                break;
            case "right":
                this.angleLook -= 2 * dt;
                break;
            default:
                console.log("PHero>Model>lookTo> Direction was wrong.");
        }
    }

    // Controller functions
    handleKeyboardEvent(event: any, code: string, pressed: boolean) {
        if (this.controller) {
            for (let binding of this.controller.actions.values()) {
                if (binding.eventCodes === code) {
                    if (binding.isPressed !== pressed) {
                        binding.isPressed = pressed;
                    }
                }
            }
        } else {
            console.warn("[Spectator]: Controller undef ?");
        }
    }
}