import { Pawn } from "../engine/Pawn";
import { FiktivObject3D } from "../engine/Object";
import { KeyBinding } from "../engine/KeyBinding";
import { Controller } from "../engine/Controller";

import { FiktivEngine } from "../index";
import * as THREE from "three";

export class Spectator extends Pawn<FiktivObject3D> {
  public fe: FiktivEngine;
  public clock: THREE.Clock;

  constructor(
    //should not include fe… FIXME
    fe: FiktivEngine,
    material: THREE.MeshStandardMaterial,
    pos: THREE.Vector3,
    quat: THREE.Quaternion
  ) {
    var shape = new THREE.BoxGeometry(2, 2, 2, 1, 1, 1);
    let mesh = new THREE.Mesh(shape, material);
    mesh.position.copy(pos);
    mesh.quaternion.copy(quat);

    super(new FiktivObject3D(pos, quat, mesh, undefined, false, 10));

    this.clock = new THREE.Clock();

    //Init actions
    let actions = new Map([
      ["mouseLeft", new KeyBinding("mouse0")],
      ["mouseRight", new KeyBinding("mouse2")],
      ["throttle", new KeyBinding("KeyW")],
      ["reverse", new KeyBinding("KeyS")],
      ["left", new KeyBinding("KeyA")],
      ["right", new KeyBinding("KeyD")],
    ]);
    this.controller = new Controller(actions);

    // TODO should be done outside
    this.fe = fe;
    //Init mechanics of sphero
    this.fe.scene.add(mesh);
    this.fe.syncList.push(this);

    this.fe.cameraOperator.rotateIso(
      135,
      55 - 20 /* 20 is default phi value */
    );

    //Handle pointlock
    if (this.fe.inputManager) {
      this.fe.inputManager.setPointerLock(false);
    }

    this.controller.handleMouseMove = (e, dx, dy) =>
      this.handleMouseMove(e, dx, dy);
    this.controller.handleKeyboardEvent = (e, c, p) => {
      console.log("KEYBOARD EVENT");
      this.handleKeyboardEvent(e, c, p);
    };

    if (this.feObject) this.feObject.speed = 10;

    this.update = () => {
      let applyImpulse = false;

      if (this.fe.graphic_enabled) {
        let tempTheta = -this.fe.cameraOperator!.theta - 90;

        let actions = this.controller?.actions;

        if (actions?.get("throttle")!.isPressed) {
          applyImpulse = true;
        }
        if (actions?.get("reverse")!.isPressed) {
          applyImpulse = true;
          tempTheta += 180;
        }
        if (actions?.get("left")!.isPressed) {
          applyImpulse = true;
          tempTheta += 90;
        }
        if (actions?.get("right")!.isPressed) {
          applyImpulse = true;
          tempTheta -= 90;
        }

        if (applyImpulse && this.feObject && this.position) {
          let speed = this.feObject.speed;
          let tempX = Math.cos((tempTheta * Math.PI) / 180) * speed * 0.25;
          let tempY = Math.sin((tempTheta * Math.PI) / 180) * speed * 0.25;

          let pos = this.feObject.mesh.position;
          this.setPosition(new THREE.Vector3(pos.x + tempX, pos.y + tempY, pos.z))
          /* this.pos.set(this.pos.x + tempX, this.pos.y + tempY, this.pos.z); */
          /* this.feObject.mesh?.position.set(this.pos.x, this.pos.y, this.pos.z); */
        }
      }
    };
  }

  /* Interactions */
  handleMouseMove(event: any, deltaX: number, deltaY: number) {
    this.fe.cameraOperator.rotate(deltaX, deltaY);
  }

  handleMouseWheel(event: any, value: number) {
    this.fe.cameraOperator!.zoom(value);
  }

  handleMouseButton(event: any, code: string, pressed: boolean) {
    for (const action in this.controller?.actions) {
      if (this.controller?.actions.hasOwnProperty(action)) {
        const binding = this.controller.actions.get(action)!;
        if (binding.eventCodes === code) {
          if (binding.isPressed !== pressed) {
            binding.isPressed = pressed;
          }
        }
      }
    }
  }

  handleKeyboardEvent(event: any, code: string, pressed: boolean) {
    if (this.controller) {
      for (let binding of this.controller.actions.values()) {
        if (binding.eventCodes === code) {
          if (binding.isPressed !== pressed) {
            binding.isPressed = pressed;
          }
        }
      }
    } else {
      console.warn("[Spectator]: Controller undef ?");
    }
  }
}
