import { FiktivEngine, FiktivObject3D, update_input_type } from "../../../../fiktivengine_modules/fiktivengine-core";
import * as THREE from "three";
import { Lendless } from "../../level/Lendless";
import { PHero } from "../../pawn/PHero";
import { AnimatedMetroContainer } from "./AnimatedMetroContainer";
import { AnimatedJonqueContainer } from "./AnimatedJonqueContainer";
import { AnimatedBirdContainer } from "./AnimatedBirdContainer";
import { AnimatedCarsContainer } from "./AnimatedCarsContainer";
import { AnimatedLightsContainer } from "./AnimatedLightsContainer";

export class DecorContainer extends FiktivObject3D {
    private fe: FiktivEngine | undefined;
    private asset: string;
    private defaultPosition: number;
    private offset: number;
    public scale: THREE.Vector3;
    private depth: number;
    public texture?: THREE.Texture;
    public material?: THREE.SpriteMaterial;

    public animations: Array<any>;
    public container: THREE.Object3D;
    public decorList: Array<THREE.Sprite>;
    public index: number;

    private positionTarget: THREE.Vector3;

    private lightModifier: number;

    constructor(
        fe: FiktivEngine | undefined,
        asset: string,
        defaultPosition: number,
        offset: number,
        scale: THREE.Vector3,
        depth: number,
        index?: number
    ) {
        super();
        this.fe = fe;
        this.asset = asset;
        this.defaultPosition = defaultPosition;
        this.offset = offset;
        this.scale = scale;
        this.depth = depth;
        this.index = index || 0;

        this.animations = new Array<any>();
        this.decorList = new Array<THREE.Sprite>();
        this.container = new THREE.Object3D();
        this.fe?.scene.add(this.container);

        this.positionTarget = new THREE.Vector3();

        this.lightModifier = 0.97;

        this.material = new THREE.SpriteMaterial({
            depthWrite: true,
            depthTest: true,
            transparent: true,
            opacity: 0
        });

        if (this.fe) {
            let assets = (this.fe.map as Lendless).preload.assetList;
            for (let asset of assets) {
                if (asset.name === this.asset) {
                    if (asset.file) {
                        this.texture = asset.file;

                        this.material = new THREE.SpriteMaterial({
                            map: this.texture,
                            depthWrite: true,
                            depthTest: true
                        });

                        break;
                    }
                }
            }
        }

        for (let decorLoaded = 0; decorLoaded < 3; decorLoaded++) {
            this.addOne(this.index++);
        }
    }

    unload = () => {
        for (let decor of this.decorList) {
            this.fe?.scene.remove(decor);
        }
        this.decorList.splice(0);
        this.animations.splice(0);
        this.container.clear();
        this.fe?.scene.remove(this.container);
    }

    updateLocal(args: update_input_type, camPos: THREE.Vector3, speedTravelling: number) {
        let toFind = Math.abs(this.offset * (this.index - 2))
        if (this.offset < 0)
            toFind *= -1;

        this.positionTarget.set(this.defaultPosition + speedTravelling, this.defaultPosition + speedTravelling, camPos.z + this.depth)
        this.mesh.position.x = this.mesh.position.x + (this.positionTarget.x - this.mesh.position.x) / 10;
        this.mesh.position.y = this.mesh.position.y + (this.positionTarget.y - this.mesh.position.y) / 10;
        this.mesh.position.z = this.mesh.position.z + (this.positionTarget.z - this.mesh.position.z) / 10;

        for (let decor of this.decorList) {
            decor.position.x = this.mesh.position.x - toFind;
            decor.position.y = this.mesh.position.y - toFind;
            decor.position.z = this.mesh.position.z;
            toFind += this.offset;
        }

        toFind = Math.abs(this.offset * (this.index - 3))
        if (this.offset < 0)
            toFind *= -1;
        this.container.position.x = this.mesh.position.x - toFind + 100 + 12;
        this.container.position.y = this.mesh.position.y - toFind + 100 + 12;
        this.container.position.z = this.mesh.position.z + 100;

        for (let animation of this.animations) {
            animation.updateLocal();
        }

        if (this.asset === "background2" && this.material) {
            this.material.opacity *= this.lightModifier;

            if (this.material.opacity < 0.4 && this.lightModifier < 1) {
                this.lightModifier = 1.03 - Math.random() * 0.02;
            }
            if (this.material.opacity > 1.8 && this.lightModifier > 1) {
                this.lightModifier = 0.97 + Math.random() * 0.02;
            }
        }
    }

    addOne = (index: number) => {
        let decor = new THREE.Sprite(this.material);
        decor.scale.set(this.scale.x, this.scale.y, this.scale.z);

        decor.position.set(
            this.defaultPosition - this.offset * (index),
            this.defaultPosition - this.offset * (index),
            this.depth
        );

        let city = (this.fe?.map as Lendless).city || "paris";

        if (city === "paris") {
            if (this.asset === "background") {
                let metro = new AnimatedMetroContainer(this.fe);
                metro.container.position.set(- this.offset * (this.index), - this.offset * (this.index), 0);
                this.animations.push(metro);
                this.container.add(metro.container);
            }

            if (this.asset === "foreground") {
                let bird = new AnimatedBirdContainer(this.fe);
                bird.container.position.set(- this.offset * (this.index), - this.offset * (this.index), 0);
                this.animations.push(bird);
                this.container.add(bird.container);
            }
        }

        if (city === "hainan") {
            if (this.asset === "background") {
                let cars = new AnimatedCarsContainer(this.fe);
                cars.container.position.set(- this.offset * (this.index), - this.offset * (this.index), 0);
                this.animations.push(cars);
                this.container.add(cars.container);
            }

            if (this.asset === "foreground") {
                let bird = new AnimatedBirdContainer(this.fe);
                bird.container.position.set(- this.offset * (this.index), - this.offset * (this.index), 0);
                this.animations.push(bird);
                this.container.add(bird.container);
            }
        }

        if (city === "macau") {
            if (this.asset === "foreground") {
                let bird = new AnimatedBirdContainer(this.fe);
                bird.container.position.set(- this.offset * (this.index), - this.offset * (this.index), 0);
                this.animations.push(bird);
                this.container.add(bird.container);
            }
        }

        if (city === "hongkong") {
            if (this.asset === "background") {
                let jonque = new AnimatedJonqueContainer(this.fe);
                jonque.container.position.set(- this.offset * (this.index), - this.offset * (this.index), 0);
                this.animations.push(jonque);
                this.container.add(jonque.container);
            }
        }

        this.decorList.push(decor);
        this.fe?.scene.add(decor);
    }

    lastToFirst = () => {
        let elem = this.decorList.splice(0, 1)[0];

        if (elem) {
            elem.scale.set(this.scale.x, this.scale.y, this.scale.z);

            elem.position.set(
                this.defaultPosition - this.offset * (this.index - 2),
                this.defaultPosition - this.offset * (this.index - 2),
                this.defaultPosition
            );
            this.decorList.push(elem);
        }

        this.index++;
    }
}

export class Decors extends FiktivObject3D {
    private fe?: FiktivEngine;
    private decors: Array<DecorContainer>;

    public background: DecorContainer;
    public background2?: DecorContainer;
    public foreground: DecorContainer;

    constructor(
        fe: FiktivEngine | undefined,
        city: string
    ) {
        super();

        this.fe = fe;

        this.decors = [];

        this.background = new DecorContainer(fe, 'background', -360, 98.5, new THREE.Vector3(80 / 5.34, 80, 1), -500);
        if (city === "macau") {
            this.background2 = new DecorContainer(this.fe, 'background2', -360, 98.5, new THREE.Vector3(80 / 5.34, 80, 1), -500);
        }
        this.foreground = new DecorContainer(fe, 'foreground', 360, 198, new THREE.Vector3(160 / 5.34, 160, 1), -100);

        this.fe?.syncList.push(this);

        this.update = (args: update_input_type) => {
            let camPos = this.fe?.camera.position;
            let pawn = (this.fe?.players.get("me")?.pawn as PHero);
            if (camPos && pawn?.pos) {
                if (this.background) {
                    this.background.updateLocal(args, camPos, pawn.distance / 4);
                    // For next two lines :
                    // * -365 is position of first image top limit
                    // * 54 is coef that represent offset between two images (with moves)
                    let toFind = 54
                    if (this.background.mesh.position.x < -365 - toFind * (this.background.index - 2)) {
                        this.background.lastToFirst();
                    }
                }

                if (this.background2) {
                    this.background2.updateLocal(args, camPos, pawn.distance / 4);
                    // For next two lines :
                    // * -365 is position of first image top limit
                    // * 54 is coef that represent offset between two images (with moves)
                    let toFind = 54
                    if (this.background2.mesh.position.x < -365 - toFind * (this.background2.index - 2)) {
                        this.background2.lastToFirst();
                    }
                }

                if (this.foreground) {
                    this.foreground.updateLocal(args, camPos, - pawn.distance * 1.1);
                    // For next two lines :
                    // * 188 is position of first image top limit
                    // * 130 is coef that represent offset between two images (with moves)
                    let toFind = 130
                    if (this.foreground.mesh.position.x > 188 + toFind * (this.foreground.index - 2)) {
                        this.foreground.lastToFirst();
                    }
                }
            }
        }
    }

    unload() {
        this.background.unload();
        this.background2?.unload();
        this.foreground.unload();
    }
}