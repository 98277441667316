// React
import { url } from 'inspector';
import { useLocation } from 'react-router';
import { useEffect, useLayoutEffect, useState } from 'react';

// GJ modules
import { FiktivEngine } from '../../fiktivengine_modules/fiktivengine-core/fiktiv';
// Level
import { Lendless } from './level/Lendless';

import './style/HennessySans1765_W.woff2';
import "./style/main.css"

const BASE_URL = process.env.REACT_APP_BASE_URL;

//let fe: FiktivEngine | undefined;

export const EndlessIxo = (props: any) => {

	let location = useLocation();

	console.log("MyLocation =", location)

	useEffect(() => {

		let isIframe = false;

		window.onmessage = (e: any) => {
			if (e.data && e.data.type === 'itsIframe') {
				isIframe = true;
			}

			if (e.data && e.data.type === 'start') {
				console.log("Hennessy | Start Game");

				let container = document.getElementById("viewport");
				let map = new Lendless(undefined, props.city, props.lang);
				map.showUI = false;
				let args = {
					graphic_enabled: true,
					input_enabled: true,
					physic_enabled: true,
					container: container,
					map: map,
					multi: false,
					orthographic: true
				}
				new FiktivEngine(args);
			}
		};

		let data = {
			type: 'isIframe',
			data: {
			}
		}
		window?.top?.postMessage(data, '*');

		setTimeout(function(){
			if(!isIframe){
				let divDemo = document.getElementById('demo');
				if (divDemo) {
					divDemo.style.display = "inline-block";
				}
			}
		}, 2000);

		let startButton = document.getElementById('demoLaunch');
		if (startButton) {
			startButton.onclick = function () {
				console.log("Hennessy | Start Game");

				let divDemo = document.getElementById('demo');
				if (divDemo)
					divDemo.style.display = "none";

				let container = document.getElementById("viewport");
				let map = new Lendless(undefined, props.city, props.lang, props.nodead);
				let args = {
					graphic_enabled: true,
					input_enabled: true,
					physic_enabled: true,
					container: container,
					map: map,
					multi: false,
					orthographic: true
				}
				new FiktivEngine(args);
			}
		}
	}, [props.city]);

	useEffect(() => {
		
	}, []);

	let finalLang = "en";

	if(props.lang === "zh-hans"){
		finalLang = "zh-hans"
	} else if (props.lang === "zh-hant"){
		finalLang = "zh-hant"
	}

	return (
		<div className="app">
			<div
				id="demo"
				style={{
					position: "absolute",
					color: 'white',
					zIndex: 60000,
					opacity: 1,
					width: "100%",
					marginTop: 100,
					textAlign: "center",
					display:"none"
				}}
			>
				<div id="demoLaunch" style={{cursor:"pointer"}}>Launch demo</div>
				<div id="listLink">
					<ul>
						<li onClick={() => {
							let path = document.location.pathname;
							let split = path.split('#')
							let lang = split[split.length - 1];
							document.location.href = document.location.origin + BASE_URL + '#paris#' + (finalLang ? finalLang : '') + (props.nodead ? '#g' : '');
							document.location.reload();
						}} style={{cursor:"pointer"}}>Paris</li>
						<li onClick={() => {
							let path = document.location.pathname;
							let split = path.split('#')
							let lang = split[split.length - 1];
							document.location.href = document.location.origin + BASE_URL + '#hainan#' + (finalLang ? finalLang : '') + (props.nodead ? '#g' : '');
							document.location.reload();
						}} style={{cursor:"pointer"}}>Hainan</li>
						<li onClick={() => {
							let path = document.location.pathname;
							let split = path.split('#')
							let lang = split[split.length - 1];
							document.location.href = document.location.origin + BASE_URL + '#macau#' + (finalLang ? finalLang : '') + (props.nodead ? '#g' : '');
							document.location.reload();
						}} style={{cursor:"pointer"}}>Macau</li>
						<li onClick={() => {
							let path = document.location.pathname;
							let split = path.split('#')
							let lang = split[split.length - 1];
							document.location.href = document.location.origin + BASE_URL + '#hongkong#' + (finalLang ? finalLang : '') + (props.nodead ? '#g' : '');
							document.location.reload();
						}} style={{cursor:"pointer"}}>Hong Kong</li>
						<li onClick={() => {
							let path = document.location.pathname;
							let split = path.split('#')
							let lang = split[split.length - 1];
							document.location.href = document.location.origin + BASE_URL + '#singapore#' + (finalLang ? finalLang : '') + (props.nodead ? '#g' : '');
							document.location.reload();
						}} style={{cursor:"pointer"}}>Singapore</li>
					</ul>
				</div>
			</div>

			<div
				id="transition"
				style={{
					position: "absolute",
					width: "102%",
					height: "100%",
					backgroundColor: "black",
					zIndex: 10000,
					opacity: 0,
					transition: "opacity 1s",
					pointerEvents: "none"
				}}
			></div>

			{/* <div
				id="progress"
				style={{
					position: "absolute",
					width: "100%",
					height: "100%",
					top: 0,
					left: 0,
					zIndex: 500,
					pointerEvents: "none",
					display: "none"
				}}
			>
				<div
					id="progressBarBackground"
					style={{
						position: "absolute",
						width: "30%",
						height: "1%",
						top: "5%",
						left: "5%",
						backgroundColor: "rgba(255, 255, 255, .2)"
					}}
				>
					<div
						id="progressBar"
						style={{
							position: "absolute",
							width: "0%",
							height: "100%",
							top: 0,
							left: 0,
							backgroundColor: "rgba(255, 255, 255, 1)"
						}}
					></div>
				</div>
			</div> */}

			<div
				id="dataDisplay"
				style={{
					position: "absolute",
					width: "100%",
					height: "100%",
					top: 0,
					left: 0,
					zIndex: 2800,
					userSelect: "none",
					pointerEvents: "none",
					fontSize: 14,
					textShadow: "0px 0px 2px black",
					textTransform: "uppercase",
					transition: "opacity 1s",
				}}
			></div>

			<div
				id="viewport"
				style={{
					width: "100%",
					height: "100%",
					margin: 'auto',
					marginTop: 0,
					pointerEvents: "all"
				}}
			/>

			<div id="progress" className="base-timer" style={{ position: "absolute", top: '20px', right: '20px', height: '100px', width: '100px', zIndex: 500, display: "none" }}>
				<svg className="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" style={{ position: "relative", height: 100, width: 100 }}>
					<g className="base-timer__circle" style={{ fill: 'black', stroke: 'red' }}>
						<circle className="base-timer__path-elapsed" cx="50" cy="50" r="45" style={{ strokeWidth: 8, stroke: '#C3AA90' }} />
						<path
							id="base-timer-path-remaining"
							strokeDasharray="0 283"
							className="base-timer__path-remaining ${remainingPathColor}"
							d="
          M 50, 50
          m -45, 0
          a 45,45 0 1,0 90,0
          a 45,45 0 1,0 -90,0
        "
							style={{
								strokeWidth: '0px',

								/* Rounds the line endings to create a seamless circle */
								strokeLinecap: 'square',

								/* Makes sure the animation starts at the top of the circle */
								transform: 'rotate(270deg) scaleX(-1)',
								transformOrigin: 'center',

								/* One second aligns with the speed of the countdown timer */
								transition: 'all 1s linear, stroke-width 10s linear',

								/* Allows the ring to change color when the color value updates */
								stroke: 'black'
							}}
						></path>
					</g>
				</svg>
				<span id="scoreContainer" style={{
					position: 'absolute',
					width: '100px',
					height: '100px',
					top: 0,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					fontFamily: 'Hennessy',
					fontSize: 20,
					pointerEvents: "none",
					color: 'white',
					textAlign: "right",
					visibility: "visible",
					userSelect: "none"
				}}>
					0
				</span>
			</div>

			{/* <div id="progress" className="base-timer" style={{ position: "absolute", top: '20px', right: '20px', height: '100px', width: '100px', zIndex: 500, display: "none" }}>
				<svg className="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" style={{ position: "relative", height: 100, width: 100 }}>
					<g className="base-timer__circle" style={{ fill: 'black' }}>
					
						<circle className="base-timer__path-elapsed" cx="50" cy="50" r="35" style={{ strokeWidth: 16, stroke: 'black' }} />

						<path
							id="base-timer-path-remaining"
							strokeDasharray="240 240"
							className="base-timer__path-remaining ${remainingPathColor}"
							d="
          M 50, 50
          m 40, 0
          a 10,10 0 1,0 -79,0
          a 10,10 0 1,0 79,0
        "
							style={{
								strokeWidth: '10px',

								strokeDashoffset: '-6px',

								strokeLinecap: 'square',

								transform: 'rotate(270deg)',
								transformOrigin: 'center',

								transition: 'all 1s linear',

								stroke: '#C3AA90'
							}}
						></path>
						
					</g>
				</svg>
				<span id="scoreContainer" style={{
					position: 'absolute',
					width: '100px',
					height: '100px',
					top: 0,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					fontFamily: 'Hennessy',
					fontSize: 20,
					pointerEvents: "none",
					color: 'white',
					textAlign: "right",
					visibility: "visible",
					userSelect: "none"
				}}>
					0
				</span>
			</div> */}

			{/* <button
				id="scoreContainer"
				style={{
					fontFamily: 'Hennessy',
					width: "100px",
					height: "50px",
					fontSize: 20,
					backgroundColor: "white",
					borderRadius: 8,
					margin: 'auto',
					top: "20px",
					right: "20px",
					pointerEvents: "none",
					position: "absolute",
					color: "0x8E99D4",
					textAlign: "right",
					visibility: "hidden",
					userSelect: "none"
				}}
			>0</button> */}

			<div
				id="addScoreContainer"
				style={{
					fontFamily: 'Hennessy',
					fontSize: 20,
					height: "100%",
					width: "100%",
					margin: 'auto',
					top: 0,
					left: 0,
					position: "absolute",
					color: "0xF7DA00",
					textAlign: "right",
					paddingRight: 0,
					pointerEvents: "none"
				}}
			>
			</div>

			<div
				id="preshow_spritesheet"
				style={{
					position:'absolute',
					zIndex:2005,
					top:0,
					left:0,
					width:"100%",
					height:"100%",
					pointerEvents: "none",
					overflow:"hidden"
				}}
			>
			</div>

			{/*<div
				style={{
					position: "absolute",
					zIndex: 2001,
					display: "flex",
					width: "100%",
					height: "100%",
					flexDirection: "column",
					top: 0,
					justifyContent: "center",
					alignItems: "center",
					pointerEvents: "none"
				}}
			>
				<video
					id="preshow"
					muted
					crossOrigin="anonymous"
					playsInline
					style={{
						visibility: "hidden",
						height: "100%",
						width: "100%",
						objectFit: "cover"
					}}
				>
					{props.city ? <source src={"./preshows/preshow_" + props.city + "_1_" + finalLang + ".mp4"} type='video/mp4' /> : <source src={"./preshows/preshow_paris_1_en.mp4"} type='video/mp4' />}
				</video>
			</div>*/}

			<div
				id="rules"
				style={{
					position: "absolute",
					top: 0,
					zIndex: 6000,
					width: "100%",
					height: "100%",
					left: 0,
					pointerEvents: "all",
					//backgroundImage:'url("/preshows/rules_paris.png")',
					backgroundSize: "cover",
					backgroundPosition: "center",
					textAlign: "center",
					visibility: "hidden"
				}}
			>
				<button
					id="buttonContainer"
					style={{
						position: "absolute",
						border: 0,
						backgroundColor: "rgba(100, 100, 100, 0)",
                        //backgroundColor: "rgba(255,0,0,255)",
						fontFamily: 'Hennessy',
						width: "60%",
						height: "20%",
						left: "20%",
						fontSize: 14,
						borderRadius: 8,
						pointerEvents: "all",
						//visibility: "hidden",
						cursor: "pointer",
						bottom: 0
					}}>
				</button>
			</div>

			<button
				id="buttonContainer2"
				style={{
					fontFamily: 'Hennessy',
					width: "30%",
					height: "50px",
					fontSize: 14,
					backgroundColor: "white",
					borderRadius: 8,
					margin: 'auto',
					top: "45%",
					left: "35%",
					pointerEvents: "all",
					position: "absolute",
					color: "0x8E99D4",
					textAlign: "center",
					visibility: "hidden",
					cursor: "pointer"
				}}
			>Play</button>

			<div
				id="loadingPage"
				style={{
					position: "absolute",
					top: 0,
					width: "100%",
					height: "100%",
					pointerEvents: "none",
					backgroundColor: "black"
				}}
			>
				<div
					style={{
						position: "absolute",
						zIndex: 2500,
						left: "50%",
						bottom: "10%",
						textAlign: "center",
						fontSize: "1.6em",
						color: "white"
					}}>
					<div
						id="loadingPercent"
						style={{
							position: "relative",
							width: "100px",
							left: "-50px"
						}}
					>0%</div>
				</div>
				<div
					id="loadingImg"
					style={{
						zIndex: 2100,
						marginLeft: "auto",
						marginRight: "auto",
						width: "100%",
						height: "100%",
						/*backgroundImage: 'url("/preshows/loading.png")',*/
						backgroundRepeat: "no-repeat",
						backgroundPosition: "center",
						backgroundSize: "cover",
						opacity: 0,
						transition: "opacity 1s",
						backgroundColor: "red"
					}}
				></div>
				{/* <video id="video2" muted autoPlay crossOrigin="anonymous" playsInline style={{ width: Math.max(size[0], size[1]), height: Math.max(size[1], size[0]) }}>
                    <source src={"/preshows/loading.mp4"} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2' />
                </video> */}
			</div>

		</div >
	);
}