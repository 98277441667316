// Modules
import * as THREE from "three";
// GJ modules
import { FiktivEngine, FiktivObject3D } from "../../../../fiktivengine_modules/fiktivengine-core";
// Hennessy
import { Lendless } from "../../level/Lendless";

const color = new Map<string, Array<number>>();
color.set("paris", [0x241E5B, 0x8E99D4]);
color.set("hainan", [0x2C5864, 0x69A490]);
color.set("macau", [0x2E3168, 0xEA6B45]);
color.set("hongkong", [0x2E3168, 0xEA6B45]);
color.set("singapore", [0x2E3168, 0xEA6B45]);

export class Loading extends FiktivObject3D {
    private isLoading: boolean;

    private video?: HTMLVideoElement;

    constructor(
        fe?: FiktivEngine
    ) {
        super();

        this.isLoading = true;
        this.video = document.getElementById('preshow') as HTMLVideoElement;
        //this.video.setAttribute('playsinline', 'true');
        /*this.video.onloadeddata = function () {
            this.video.play();
        };*/


        /*let font = (this.fe.map as Lendless).font
        if (font) {
            const geometry = new THREE.BoxGeometry(30, 40, 1);
            let colors = color.get((this.fe?.map as Lendless).city);
            const material = new THREE.MeshBasicMaterial({ color: colors ? colors[0] : 0x241E5B });
            this.screen = new THREE.Mesh(geometry, material);
            this.screen.position.set(0, 0, 10)

            var txtMater = new THREE.MeshBasicMaterial({ color: colors ? colors[1] : 0x8E99D4 });
            let geometryText = new THREE.TextGeometry("Loading", {
                font: font,
                size: 0.5,
                height: 0.5,
                curveSegments: 12,
                bevelEnabled: false,
                bevelThickness: 1,
                bevelSize: 1,
                bevelOffset: 0,
                bevelSegments: 2
            });
            geometryText.center()
            let meshText = new THREE.Mesh(geometryText, txtMater);
            meshText.position.set(0, 0, 12);
            this.screen.add(meshText);
        }*/
    }

    display() {
        this.isLoading = true;
        if (this.video) {
            this.video.style.visibility = "visible";
			/*alert(this.video.readyState)
			alert(this.video.src)
			alert(this.video.srcObject)*/
			this.video.play();
        }
    }

    hide() {
        this.isLoading = false;
        if (this.video) {
            this.video.style.visibility = "hidden";
            this.video.pause();
            this.video.currentTime = 0;
        }
    }

    delete() {

    }
}