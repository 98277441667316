import { Quaternion, Vector3, Object3D } from "three";

import { Actor } from "./Actor";
import { update_input_type } from "./FiktivEngine";
import Ammo from "ammojs-typed";

export class FiktivObject3D extends Actor {
  private _mesh: Object3D;
  private _physic: boolean;
  private _speed: number;
  private _body: Ammo.btRigidBody | undefined;

  get mesh(): Object3D {
      return this._mesh;
  }
  set mesh(mesh: Object3D) {
      this._mesh = mesh;
  }

  get physic(): boolean {
      return this._physic;
  }
  set physic(physic: boolean) {
      this._physic = physic;
  }

  get speed(): number {
      return this._speed;
  }
  set speed(speed: number) {
      this._speed = speed;
  }

  get body(): Ammo.btRigidBody | undefined {
      return this._body;
  }
  set body(body: Ammo.btRigidBody | undefined) {
      this._body = body;
  }

  constructor(
    pos?: Vector3,
    quat?: Quaternion,
    mesh?: Object3D | undefined,
    body?: Ammo.btRigidBody,
    physic: boolean = false,
    speed: number = 0
  ) {
    super();
    this._mesh = mesh ? mesh : new Object3D();
    if (pos) this.mySetPosition(pos);
    if (quat) this.mesh.quaternion.set(quat.x, quat.y, quat.z, quat.w);
    this.body = body;
    this._physic = physic;
    this._speed = speed;

    this.move = (args: {
      pos: Vector3;
      q: Quaternion;
      ammo: any;
      reset: boolean;
    }) => this.myMove(args.pos, args.q, args.ammo, args.reset);

    this.update = (args: update_input_type) => {
      if (this.physic && this.body) {
        var ms = this.body.getMotionState();
        if (ms) {
          ms.getWorldTransform(args.auxTransform!);
          var p = args.auxTransform!.getOrigin();
          var q = args.auxTransform!.getRotation();
          this.mesh.position.set(p.x(), p.y(), p.z());
          this.mesh.quaternion.set(q.x(), q.y(), q.z(), q.w());
        }
      }
    };

    this.setPosition = (args: { position: Vector3 }) =>
      this.mySetPosition(args.position);
  }

  private mySetPosition(p: Vector3) {
    this.mesh.position.set(p.x, p.y, p.z);
  }

  private myMove(pos: Vector3, q: Quaternion, ammo: any, reset: boolean = true) {
    if (this.physic && this.body) {
      var auxTransform = new Ammo.btTransform();
      auxTransform.setIdentity();
      auxTransform.setOrigin(new Ammo.btVector3(pos.x, pos.y, pos.z));
      // auxTransform.setRotation(new ammo.btQuaternion(q.x, q.y, q.z, q.w));
      let body = this.body!;
      body.setWorldTransform(auxTransform);
      let ms = body.getMotionState();
      if (ms) {
        ms.setWorldTransform(auxTransform);

        if (reset) {
          body.setLinearVelocity(new Ammo.btVector3(0, 0, 0));
          body.setAngularVelocity(new Ammo.btVector3(0, 0, 0));
          body.clearForces();
        }

        var p = auxTransform.getOrigin();
        // var quat = auxTransform.getRotation();
        this.mesh.position.set(p.x(), p.y(), p.z());
        // this.pawn.mesh.quaternion.set(quat.x(), quat.y(), quat.z(), quat.w());
      }
    } else {
      this.mesh.position.set(pos.x, pos.y, pos.z);
      // this.feObject.pawn.mesh.quaternion.set(q.x, q.y, q.z, q.w);
    }
  }
}
