// Modules
import * as THREE from "three";
// GJ modules
import { FiktivEngine, FiktivLevel, Actor, Player } from "../../../fiktivengine_modules/fiktivengine-core/fiktiv";
// Hennessy
import { Loading, Preload, Tiles } from "../actors/index";
import { DecorContainer, Decors } from "../actors/Levels/Decors";
import { LevelList } from "../actors/Levels/LevelList";
import { Score } from "../actors/UI/Score";
import { PoolManager, Random } from "../actors";
import { PHero } from "../pawn/PHero";
import { getListAssets } from "../data/list";

export class Lendless implements FiktivLevel {
    fe: FiktivEngine | undefined;
    actors: Map<string, Actor>;
    initPos: THREE.Vector3;
    initQuat: THREE.Quaternion;
    onStart: (args: any) => void;
    onStop: (args: any) => void;
    onLoose(args: any) { };

    // Loaders
    public preload: Preload;
    public fontLoader?: THREE.FontLoader;
    public textureLoader?: THREE.TextureLoader;
    public audioLoader?: THREE.AudioLoader;

    // Ressources
    public font?: THREE.Font;
    public poolManager?: PoolManager;
    public random?: Random;

    // UI
    private loadingScreen?: Loading;
    public button?: any;
    public elementRules?: any;
    public elementTransition?: any;
    public showUI: boolean;

    // Game
    public listener?: THREE.AudioListener;
    public sound?: THREE.Audio;
    public sounddesign?: Map<string, THREE.Audio>;
    public musicActual?: string;

    public city: string;
    public lang: string;
    public levels?: LevelList;
    public decors?: Decors;
    public scoreMod?: Score;
    public distByTime: number;
    public lives: number;
    public stopping: boolean;
    public godmode: boolean;
    public speed: number;

    public transToCognac: boolean;
    public cognac: boolean;
    public transToEnd: boolean;
    public end: boolean;

    public totalTime: number;
    public timerStart: number;
    public pickupList: Map<string, number>;

    ZERO_QUATERNION: THREE.Quaternion = new THREE.Quaternion(0, 0, 0, 1);

    constructor(fe?: FiktivEngine, city?: string, lang?: string, nodead?: boolean) {
        console.log("NODEAD=", nodead)
        this.fe = fe;
        this.actors = new Map<string, Actor>();
        this.initPos = new THREE.Vector3(0, 0, 0);
        this.initQuat = this.ZERO_QUATERNION;
        this.onStart = () => { };
        this.onStop = () => { };

        this.city = city || "paris";
        if (this.city !== "paris" && this.city !== "hainan" && this.city !== "macau" && this.city !== "hongkong" && this.city !== "singapore") {
            this.city = "paris";
        }
        console.log("city =", this.city);

        this.lang = lang || "en";
        if (this.lang !== "en" && this.lang !== "zh-hans" && this.lang !== "zh-hant") {
            this.lang = "en";
        }
        console.log("lang =", this.lang);

        this.distByTime = 5;
        this.lives = 1;
        this.stopping = false;
        this.godmode = nodead !== undefined ? nodead : false;
        this.speed = 0;
        this.transToCognac = false;
        this.cognac = false;
        this.transToEnd = false;
        this.end = false;

        this.showUI = true;

        // City loading
        this.preload = new Preload(getListAssets(this.city, this.lang), this.fe);

        this.totalTime = 0;
        this.timerStart = Date.now();
        this.pickupList = new Map<string, number>();
        this.sounddesign = new Map<string, THREE.Audio>();

        //this.button.display();
    }

    init(fe: FiktivEngine) {
        this.fe = fe;

        // Loaders
        if (this.fe) {

            let data = {
                type: 'eventLoadingStart', // Event
                data: {}
            }
            window?.top?.postMessage(data, '*');
            // Loading screen
            let checkLoadingPageExist = setInterval(() => {
                /*let ready = true;
                for (let asset of this.preload?.assetList) {
                    if (asset.name === 'preshow') {
                        if (asset.ready === false) {
                            ready = false;
                            return;
                        } else {
                            break;
                        }
                    }
                }
                console.log("Loader done !");*/
                clearInterval(checkLoadingPageExist);

                // Loading screen
                this.loadingScreen = new Loading(this.fe);

                // Downloading
                let data = {
                    type: 'eventDownloadingStart', // Event
                    data: {}
                }
                window?.top?.postMessage(data, '*');
                let checkExist = setInterval(() => {

					console.log("Loading check")

					let ready = true;
                    for (let asset of this.preload?.assetList) {
                        if (asset.ready === false) {
                            ready = false;
                            return;
                        }
                    }

					if(ready){
                        let data = {
                            type: 'eventDownloadingEnd', // Event
                            data: {}
                        }
                        window?.top?.postMessage(data, '*');

                        data = {
                            type: 'eventLoadingEnd', // Event
                            data: {}
                        }
                        window?.top?.postMessage(data, '*');
						console.log("Loading total : "+Math.round(this.preload?.total/10000)/100+" mo")
					}

                    /*let elementVideo = document.getElementById("preshow") as HTMLVideoElement;
                    if (elementVideo) {
						//console.log(elementVideo)
						//console.log(elementVideo.readyState)
                        if (elementVideo.readyState === 1 || elementVideo.readyState === 4) {
							console.log("Loading video found")
                        } else {
							console.log("Loading video not found")
                            return;
                        }
                    } else {
						console.log("Loading video not found")
                        return;
                    }*/

                    console.log("Loading done");
                    clearInterval(checkExist);

                    // Utils
                    this.poolManager = new PoolManager();
                    this.random = new Random(0);

                    // Audio / Font
                    this.listener = new THREE.AudioListener();
                    this.fe?.scene.add(this.listener);
                    this.sound = new THREE.Audio(this.listener);
                    for (let asset of this.preload?.assetList) {
                        if (asset.name === 'preshowSFX') {
                            this.musicActual = 'preshowSFX';
                            this.sound.setBuffer(asset.file);
                            this.sound.setLoop(false);
                            this.sound.setVolume(0.5);

                            this.sound.onEnded = (() => {
                                let pawn = this.fe?.players.get("me")?.pawn as PHero;

                                if (pawn && pawn.scrolling) {
                                    for (let asset of this.preload?.assetList) {
                                        if(this.listener){
                                            if (asset.name === 'XOHennessyGame') {
                                                this.musicActual = 'XOHennessyGame';
                                                this.sound = new THREE.Audio(this.listener);

                                                this.sound.setBuffer(asset.file);
                                                this.sound.setLoop(true);
                                                this.sound.setVolume(0.5);

                                                this.sound?.play();
                                            }
                                        }
                                    }
                                } else {
                                    console.log("beginScore")
                                    for (let asset of this.preload?.assetList) {
                                        if(this.listener){
                                            if (asset.name === 'XOHennessyScore') {
                                                this.musicActual = 'XOHennessyScore';
                                                this.sound = new THREE.Audio(this.listener);

                                                this.sound.setBuffer(asset.file);
                                                this.sound.setLoop(true);
                                                this.sound.setVolume(0.5);

                                                this.sound?.play();
                                            }
                                        }
                                    }
                                }
                            }).bind(this);

                            this.sound?.play();


                        }

                        if (asset.name === 'font') {
                            this.font = asset.file;
                        }

                        // Sound Design
                        if (asset.name === 'SD_bras') {
                            let sound = new THREE.Audio(this.listener);
                            sound.setBuffer(asset.file);
                            sound.setLoop(false);
                            sound.setVolume(0.5);
                            this.sounddesign?.set('SD_bras', sound);
                        }
                        if (asset.name === 'SD_item') {
                            let sound = new THREE.Audio(this.listener);
                            sound.setBuffer(asset.file);
                            sound.setLoop(false);
                            sound.setVolume(0.5);
                            this.sounddesign?.set('SD_item', sound);
                        }
                        if (asset.name === 'SD_nuage') {
                            let sound = new THREE.Audio(this.listener);
                            sound.setBuffer(asset.file);
                            sound.setLoop(false);
                            sound.setVolume(0.5);
                            this.sounddesign?.set('SD_nuage', sound);
                        }
                        if (asset.name === 'SD_jump') {
                            let sound = new THREE.Audio(this.listener);
                            sound.setBuffer(asset.file);
                            sound.setLoop(false);
                            sound.setVolume(0.5);
                            this.sounddesign?.set('SD_jump', sound);
                        }
                        if (asset.name === 'SD_doublejump') {
                            let sound = new THREE.Audio(this.listener);
                            sound.setBuffer(asset.file);
                            sound.setLoop(false);
                            sound.setVolume(0.5);
                            this.sounddesign?.set('SD_doublejump', sound);
                        }
                    }

                    // Preload
                    if (this.fe)
                        this.preload?.preload(this.city, this.fe);

                    // Level creation
                    this.levels = new LevelList(this.fe, this.city);
                    this.fe?.syncList.push(this.levels) // When game start ?

                    // Decors loading
                    this.decors = new Decors(this.fe, this.city);

                    // Score loading
                    this.scoreMod = new Score(this.fe);

                    this.button = document.getElementById("buttonContainer");
                    this.elementRules = document.getElementById('rules');
                    this.elementTransition = document.getElementById("transition");

                    //this.button.display();

                    if (this.elementTransition) {
                        this.elementTransition.style.opacity = "1";
                    }

                    setTimeout(() => {
                        if (this.loadingScreen) {

                            if (this.elementTransition) {
                                this.elementTransition.style.opacity = "0";
                            }

                            //this.loadingScreen.display();

							let player = document.getElementById("preshow_spritesheet");

							let playerTotal = 270;
							let playerHeight = 3;
							let playerWidth = 6;
							let playerTilesetTotal = Math.ceil(playerTotal/(playerHeight*playerWidth));

							let innerDivs: HTMLDivElement[] = []
							let innerDivChilds: HTMLDivElement[] = []

							for(let i = 0; i<playerTilesetTotal; i++){
								//console.log("innerdiv "+i)

								innerDivs.push(document.createElement('div'))
								innerDivChilds.push(document.createElement('div'))

								innerDivs[i].appendChild(innerDivChilds[i])

								player?.appendChild(innerDivs[i]);

								innerDivs[i].className = 'preshow-ss-position';

								innerDivChilds[i].className = 'preshow-ss';

								for (let asset of this.preload?.assetList) {
									if (asset.name === 'preshow_'+i) {
										innerDivChilds[i].style.backgroundImage = "url('"+asset.process+"')"
									}
								}
								
								innerDivs[i].style.zIndex = ""+(2100 - i)+"";
								if(i > 1){
									innerDivs[i].style.display = "none";
								}
							}

							//let timeStart = new Date().getTime();
							let frame = 0;

							let myInterval = setInterval(() => {

								let tileset = Math.floor(frame / (playerHeight*playerWidth));

								let positionX = frame % playerWidth;
								let positionY = Math.floor(frame / playerWidth) - tileset*playerHeight;

								//console.log(frame+" - "+tileset+" - "+positionX+" / "+positionY);
								
								innerDivChilds[tileset].style.backgroundPosition = ""+positionX*(100/(playerWidth-1))+"% "+positionY*(100/(playerHeight-1))+"%"

								innerDivs[tileset].style.display = "flex"

								if(tileset > 0){
									innerDivs[tileset-1].style.display = "none"
								}

								if(tileset < (playerTilesetTotal-1)){
									innerDivs[tileset+1].style.display = "flex"
								}

								frame++;

								if(frame === playerTotal){
									clearInterval(myInterval);
									setTimeout(() => {
                                       if(player){
											player.style.display = "none";
										}
									}, 1000);
                                    
                                    let data = {
                                        type: 'eventPreshowEnd', // Event
                                        data: {}
                                    }
                                    window?.top?.postMessage(data, '*');

									if (this.elementRules) {
										this.elementRules.style.visibility = "visible";
									}
								}
							}, 32);

							//console.log("player - "+timeStart)

                            let elem = document.getElementById('loadingPage');
                            if (elem) {
                                elem.style.display = "none";
                            }

							

                            /*setTimeout(() => {
                                //this.loadingScreen?.hide();
                                //if (this.showUI)
                                //this.button.style.visibility = "visible";

                                if (this.elementRules) {
                                    this.elementRules.style.visibility = "visible";
                                }

                            }, 10000);*/

                        }

                    }, 1000);

                    /*setTimeout((() => {
                        if (this.loadingScreen) {
                            this.loadingScreen.display();
                            let elem = document.getElementById('loadingPage');
                            if (elem) {
                                elem.style.display = "none";
                            }
                            setTimeout((() => {
                                //this.loadingScreen?.hide();
                                //if (this.showUI)
                                this.button.style.visibility = "visible";
                            }).bind(this), 10000);
                        }
                    }).bind(this), 5000);*/

                }, 500);
            }, 100);
        }

        // Scene
        if (this.fe) {
            console.log("start")

            this.button = document.getElementById("buttonContainer");

            this.fe.scene.background = new THREE.Color(0xe1e1e1);

            // Lighting
            var ambient = new THREE.AmbientLight(0xffffff, 1);
            this.fe.scene.add(ambient);

            var directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
            this.fe.scene.add(directionalLight);

            // Create character
            /**/

            // Exit loading screen

            let actionButton = () => {

                if (this.elementTransition) {
                    this.elementTransition.style.opacity = "1";
                }

                setTimeout(() => {
                    if (this.elementRules) {
                        this.elementRules.style.visibility = "hidden";
                    }
                    //this.loadingScreen?.hide();

                    let elementProgress = document.getElementById("progress");
                    if (elementProgress) {
                        elementProgress.style.display = "block"
                        elementProgress.style.pointerEvents = "all"
                    }

                    if (this.elementTransition) {
                        this.elementTransition.style.opacity = "0";
                    }
                }, 1500);

                let pawn = new PHero(
                    fe,
                    new THREE.Vector3(0, 0, 0),
                    this.ZERO_QUATERNION
                );

                let currentPlayer = new Player("me", "0", pawn);
                fe.players.set("me", currentPlayer);

                if (currentPlayer.pawn && !this.actors.has("player")) {
                    this.actors.set("player", currentPlayer.pawn);
                }

                if (currentPlayer.pawn?.controller)
                    fe.inputManager.setInputReceiver(currentPlayer.pawn.controller);
                if (fe.graphic_enabled) {
                    fe.cameraOperator.setPlayer(currentPlayer);
                }

                fe.scene.add(fe?.camera);


                //this.sound?.play();
                //this.button.style.visibility = "hidden";
                (this.fe?.players.get("me")?.pawn as PHero).scrolling = true;
                this.timerStart = Date.now();

                let data = {
                    type: 'eventBtnPlay', // Event
                    data: {}
                }
                window?.top?.postMessage(data, '*');

                console.log(this.sound)
                if (this.musicActual === 'XOHennessyScore') {
                    const listener = new THREE.AudioListener();

                    if (pawn && pawn.scrolling) {
                        for (let asset of this.preload?.assetList) {
                            if (asset.name === 'XOHennessyGame') {
                                this.sound?.pause();
                                this.sound = new THREE.Audio(listener);

                                this.sound.setBuffer(asset.file);
                                this.sound.setLoop(true);
                                this.sound.setVolume(0.5);

                                this.sound?.play();
                            }
                        }
                    }
                }
            }

            let _this = this;

            this.button.onclick = () => {

                if(this.listener){



                    this.sound = new THREE.Audio(this.listener);
                    for (let asset of this.preload?.assetList) {
                        if(this.listener){
                            if (asset.name === 'preshowSFX') {
                                this.musicActual = 'preshowSFX';
                                this.sound.setBuffer(asset.file);
                                this.sound.setLoop(false);
                                this.sound.setVolume(0.5);

                                this.sound.onEnded = (() => {
                                    console.log("EndPreshow")
                                    let pawn = this.fe?.players.get("me")?.pawn as PHero;

                                    if (pawn && pawn.scrolling) {
                                        console.log("beginGame")
                                        for (let asset of this.preload?.assetList) {
                                            if(this.listener){
                                                if (asset.name === 'XOHennessyGame') {
                                                    this.musicActual = 'XOHennessyGame';
                                                    this.sound = new THREE.Audio(this.listener);

                                                    this.sound.setBuffer(asset.file);
                                                    this.sound.setLoop(true);
                                                    this.sound.setVolume(0.5);

                                                    this.sound?.play();
                                                }
                                            }
                                        }
                                    } else {
                                        console.log("beginScore")
                                        for (let asset of this.preload?.assetList) {
                                            if(this.listener){
                                                if (asset.name === 'XOHennessyScore') {
                                                    this.musicActual = 'XOHennessyScore';
                                                    this.sound = new THREE.Audio(this.listener);

                                                    this.sound.setBuffer(asset.file);
                                                    this.sound.setLoop(true);
                                                    this.sound.setVolume(0.5);

                                                    this.sound?.play();
                                                }
                                            }
                                        }
                                    }
                                }).bind(this);

                                this.sound?.play();


                            }

                            if (asset.name === 'font') {
                                this.font = asset.file;
                            }

                            // Sound Design
                            if (asset.name === 'SD_bras') {
                                let sound = new THREE.Audio(this.listener);
                                sound.setBuffer(asset.file);
                                sound.setLoop(false);
                                sound.setVolume(0.5);
                                this.sounddesign?.set('SD_bras', sound);
                            }
                            if (asset.name === 'SD_item') {
                                let sound = new THREE.Audio(this.listener);
                                sound.setBuffer(asset.file);
                                sound.setLoop(false);
                                sound.setVolume(0.5);
                                this.sounddesign?.set('SD_item', sound);
                            }
                            if (asset.name === 'SD_nuage') {
                                let sound = new THREE.Audio(this.listener);
                                sound.setBuffer(asset.file);
                                sound.setLoop(false);
                                sound.setVolume(0.5);
                                this.sounddesign?.set('SD_nuage', sound);
                            }
                            if (asset.name === 'SD_jump') {
                                let sound = new THREE.Audio(this.listener);
                                sound.setBuffer(asset.file);
                                sound.setLoop(false);
                                sound.setVolume(0.5);
                                this.sounddesign?.set('SD_jump', sound);
                            }
                            if (asset.name === 'SD_doublejump') {
                                let sound = new THREE.Audio(this.listener);
                                sound.setBuffer(asset.file);
                                sound.setLoop(false);
                                sound.setVolume(0.5);
                                this.sounddesign?.set('SD_doublejump', sound);
                            }
                        }
                    }

                }

                actionButton();
            }

            // Drive from parent window
            window.onmessage = (e: any) => {
                if (e.data && e.data.type === 'itsIframe') {
                    console.log("trigger");
                    this.showUI = false;
                }

                if (e.data && e.data.type === 'start') {
                    actionButton();
                }

                if (e.data && e.data.type === 'restart') {
                    this.restart();
                }
            };

            console.log("Endlessixo | FE - Map loaded.");
        }

    }

    update(dt: number) {
        let pawn = this.fe?.players.get("me")?.pawn as PHero;

        if (pawn) {
            //console.log("Time :: ", Math.floor((this.totalTime + (pawn.scrolling ? Date.now() - this.timerStart : 0)) / 1000));

            if (this.fe && (Date.now() - this.timerStart) > 6000) {
                let dataDisplayElement = document.getElementById("dataDisplay");
                if (dataDisplayElement) {
                    //console.log(dataDisplayElement.children)
                    for (let d of dataDisplayElement.children) {

                        let realD = d as HTMLElement;
                        var vector = new THREE.Vector3();
                        let newObject = new THREE.Object3D();

                        newObject.position.set(
                            Number(realD.dataset.posX),
                            Number(realD.dataset.posY),
                            Number(realD.dataset.posZ)
                        )

                        newObject.updateMatrixWorld();
                        vector.setFromMatrixPosition(newObject.matrixWorld);
                        vector.project(this.fe.camera);

                        vector.x = (vector.x + 1) * 50;
                        vector.y = (- vector.y + 1) * 50;

                        let offsetX = 0;
                        let offsetY = 0;
                        if (realD.dataset.offsetX)
                            offsetX = parseInt(realD.dataset.offsetX);
                        if (realD.dataset.offsetY)
                            offsetY = parseInt(realD.dataset.offsetY);

                        realD.style.transition = "opacity 1s"

                        realD.style.left = "" + (vector.x + offsetX) + "%"
                        realD.style.top = "" + (vector.y + offsetY) + "%"

                        if ((vector.y + offsetY) > 15 && (vector.y + offsetY) < 60) {
                            realD.style.opacity = "1"
                        } else {
                            realD.style.opacity = "0";
                        }

                        if (vector.y > 120) {
                            //console.log("Suppression "+realD.id)
                            //console.log(vector.y)
                            realD.remove();
                        }
                    }
                }
            }

            if (this.cognac === false && this.transToCognac === false && this.totalTime + (pawn.scrolling ? Date.now() - this.timerStart : 0) > 82000) {
                this.transToCognac = true;
                console.log("Changing to COGNAC !!!")
                if (this.levels) {
                    this.levels.nextnext = new Tiles(this.fe, new THREE.Vector3(-32 - 31 * (this.levels.current - 1), -32 - 31 * (this.levels.current - 1), -1), this.levels.current++, true, 0);
                }
            }

            if (this.cognac === false && this.transToCognac === true) {
                if (this.levels?.actual.districtName === 'verticalMap') {
                    setTimeout(() => {
                        //this.button.display();

                        if (this.elementTransition) {
                            this.elementTransition.style.opacity = "1";
                        }

                        setTimeout(() => {
                            setTimeout(() => {
                                if (this.elementTransition) {
                                    this.elementTransition.style.opacity = "0";
                                }
                            }, 2000);

                            this.transToCognac = false;
                            this.cognac = true;

                            pawn.model.changeCognac();

                            if (this.levels?.actual?.slabs && this.levels?.previous?.slabs && this.levels?.next?.slabs) {
                                for (let slab of this.levels?.previous?.slabs) {
                                    if (slab.sprite && slab.spriteCognac) {
                                        this.levels.previous.spriteContainer.remove(slab.sprite);
                                        this.levels.previous.spriteContainer.add(slab.spriteCognac);
                                    }
                                }
                                for (let slab of this.levels?.actual?.slabs) {
                                    if (slab.sprite && slab.spriteCognac) {
                                        this.levels.actual.spriteContainer.remove(slab.sprite);
                                        this.levels.actual.spriteContainer.add(slab.spriteCognac);
                                    }
                                }
                                for (let slab of this.levels?.next?.slabs) {
                                    if (slab.sprite && slab.spriteCognac) {
                                        this.levels.next.spriteContainer.remove(slab.sprite);
                                        this.levels.next.spriteContainer.add(slab.spriteCognac);
                                    }
                                }
                            }

                            if (this.decors?.background) {
                                this.decors.background.material = new THREE.SpriteMaterial({
                                    depthWrite: true,
                                    depthTest: true,
                                    transparent: true,
                                    opacity: 0
                                });

                                if (this.fe) {
                                    let assets = (this.fe.map as Lendless).preload.assetList;
                                    for (let asset of assets) {
                                        if (asset.name === 'backgroundCognac') {
                                            if (asset.file) {
                                                this.decors.background.texture = asset.file;

                                                this.decors.background.material = new THREE.SpriteMaterial({
                                                    map: this.decors.background.texture,
                                                    depthWrite: true,
                                                    depthTest: true
                                                });

                                                break;
                                            }
                                        }
                                    }
                                }

                                this.decors.background.animations.splice(0);

                                for (let decor of this.decors.background.decorList) {
                                    decor.material.map = this.decors.background.material.map;
                                }

                                this.fe?.scene.remove(this.decors.background.container);
                            }
                            if (this.decors?.background2) {
                                this.decors.background2.unload();
                            }
                            if (this.decors?.foreground) {
                                this.decors.foreground.unload();
                            }
                        }, 1000);
                    }, 2000);
                }
            }

            if (this.end === false && this.transToEnd === false && this.totalTime + (pawn.scrolling ? Date.now() - this.timerStart : 0) > 168000) {
                this.transToEnd = true;
                console.log("Changing to END !!!")
                if (this.levels) {
                    this.levels.nextnext = new Tiles(this.fe, new THREE.Vector3(-32 - 31 * (this.levels.current - 1), -32 - 31 * (this.levels.current - 1), -1), this.levels.current++, true, -10);
                    this.levels.continue = false;
                }
            }

            if (this.end === false && this.transToEnd === true) {
                if (this.levels?.actual.slabs[this.levels.step < 0 ? 0 : this.levels.step] && this.levels?.actual.slabs[this.levels.step < 0 ? 0 : this.levels.step].type === "gate2") {
                    this.end = true;
                    this.transToEnd = false;
                    setTimeout(() => {
                        pawn.scrolling = false;
                        this.totalTime += Date.now() - this.timerStart;

                        //this.sound?.stop();
                        if (this.showUI) {
                            this.button = document.getElementById("buttonContainer2") || document.getElementById("buttonContainer");
                            this.button.innerHTML = "Restart";

                            this.button.onclick = () => {
                                this.button.style.visibility = "hidden";

                                this.restart();
                            };

                            setTimeout(() => {
                                this.button.style.visibility = "visible";
                            }, 2000);
                        }

                        let data = {
                            type: 'finish',
                            data: {
                                status: 'win',
                                time: this.totalTime + (pawn.scrolling ? Date.now() - this.timerStart : 0),
                                score: this.scoreMod?.total,
                                pickups: this.pickupList
                            }
                        }
                        window?.top?.postMessage(data, '*');
                    }, 700)
                }
            }

            if (this.stopping) {
                this.distByTime *= 0.6;

                if (this.distByTime < 0.05) {
                    this.distByTime = 0
                    pawn.scrolling = false;
                    this.totalTime += Date.now() - this.timerStart;
                }
            } else {
                this.distByTime = 4 + (this.scoreMod ? (this.scoreMod.scoreDistance / 100 * 0.3) : 0) + this.speed;

                let elementBaseTimerPathRemaining = document.getElementById("base-timer-path-remaining");

                if (elementBaseTimerPathRemaining) {
                    const circleDasharray = `${(
                        ((((this.totalTime + (pawn.scrolling ? Date.now() - this.timerStart : 0)) / (1000 * 180)) * 283))
                    ).toFixed(0)} 283`;

                    elementBaseTimerPathRemaining.setAttribute("stroke-dasharray", circleDasharray);

                    elementBaseTimerPathRemaining.style.strokeWidth = '8px';
                }

                /*
                if (elementBaseTimerPathRemaining) {
                    let time = (this.totalTime + (pawn.scrolling ? Date.now() - this.timerStart : 0)) / 1000;
                    let total = (240 - (time * 240 / 180)) - 10;

                    total = total < 0 ? 0 : total;

                    console.log("total =", total)

                    const circleDasharray = `${(
                        total
                    ).toFixed(0)} 240`;

                    elementBaseTimerPathRemaining.setAttribute("stroke-dasharray", circleDasharray);
                }
                */

                /*let elementProgressBar = document.getElementById("progressBar");
                if (elementProgressBar) {
                    elementProgressBar.style.width = "" + (100-(((this.totalTime + (pawn.scrolling ? Date.now() - this.timerStart : 0)) / (1000 * 180)) * 100)) + "%"
                }*/
            }

            if (this.levels) {
                if (this.levels.step === -3) {
                    pawn.model.position.x = this.levels?.actual.path[0].vector.x + 12.5;
                    pawn.model.position.y = this.levels?.actual.path[0].vector.y + 12;
                    this.levels.step++;

                    let nextPoint = this.levels?.actual.path[0]
                    if (nextPoint) {
                        let dir = new THREE.Vector3(nextPoint.vector.x - pawn.model.position.x, nextPoint.vector.y - pawn.model.position.y, 0)
                        //pawn.model.angleLook = Math.acos(dir.normalize().y) + Math.PI / 2;
                    }
                }
                if (this.levels.step < 0) {
                    let path0 = this.levels?.actual.path[0]
                    let nextPoint = { x: path0.vector.x + 0.5, y: path0.vector.y, z: 0 }

                    if (pawn && pawn.model) {
                        let distToNextPoint = Math.sqrt(Math.pow(nextPoint.x - pawn.model.position.x, 2) + Math.pow(nextPoint.y - pawn.model.position.y, 2))
                        pawn.offsetCam = -16 / 289 * Math.pow(distToNextPoint, 2);
                        pawn.zoom = (2 / 2890) * Math.pow(distToNextPoint, 2) + 0.5;
                        if (this.fe)
                            pawn.updateCam(this.fe);
                    }
                }
                if (pawn.scrolling && this.levels && this.levels.step + 1 < this.levels?.actual.path.length - 1 && this.lives > 0) {
                    let path0 = this.levels?.actual.path[0]
                    let nextPoint = { void: false, vector: { x: 0, y: 0, z: 0 } };
                    if (this.levels.step < -1) {
                        nextPoint = { void: false, vector: { x: path0.vector.x + 0.5, y: path0.vector.y, z: 0 } }
                    } else if (this.levels.step < 0) {
                        nextPoint = { void: false, vector: { x: path0.vector.x, y: path0.vector.y, z: 0 } }
                    } else {
                        nextPoint = this.levels?.actual.path[this.levels.step + 1]
                    }

                    if (this.levels?.actual.path[this.levels.step < 0 ? 0 : this.levels.step].void && pawn.model.position.z < 0.01 && !this.godmode) {
                        //console.log("Loose")
                        this.lives -= 1;
                        //this.sound?.stop();
                        if (this.showUI) {
                            this.button = document.getElementById("buttonContainer2") || document.getElementById("buttonContainer");//new Button(this.fe, new THREE.Vector3(0, 2, 0), new THREE.Vector2(4, 1.2));
                            this.button.innerHTML = "Restart";

                            this.button.onclick = () => {
                                this.button.style.visibility = "hidden";

                                this.restart();
                            };

                            setTimeout(() => {
                                this.button.style.visibility = "visible";
                            }, 2000);
                        }

                        let data = {
                            type: 'finish',
                            data: {
                                status: 'loose',
                                time: this.totalTime + (pawn.scrolling ? Date.now() - this.timerStart : 0),
                                score: this.scoreMod?.total,
                                pickups: this.pickupList
                            }
                        }
                        window?.top?.postMessage(data, '*');
                    }

                    if (pawn.model) {
                        if (pawn.model.meshShadow) {
                            if (this.levels?.actual.path[this.levels.step <= 0 ? 0 : this.levels.step].void) {
                                this.fe?.scene.remove(pawn.model.meshShadow)
                            } else {
                                this.fe?.scene.add(pawn.model.meshShadow)
                            }
                        }

                        let dir = new THREE.Vector3(nextPoint.vector.x - pawn.model.position.x, nextPoint.vector.y - pawn.model.position.y, 0)

                        let normDir = Math.sqrt(Math.pow(dir.x, 2) + Math.pow(dir.y, 2))
                        let normVec = new THREE.Vector3(dir.x / normDir, dir.y / normDir, 0)

                        // Angle
                        if (pawn.model) {
                            pawn.model.angleLook = - Math.acos(dir.x / (Math.sqrt(dir.x * dir.x + dir.y * dir.y) * Math.sqrt(1)));
                            if (dir.y > 0) {
                                pawn.model.angleLook *= -1;
                            }
                        }

                        let rayonNext = this.distByTime / 40;

                        if (Math.abs(pawn.model.position.x + normVec.x * this.distByTime * dt - nextPoint.vector.x) < rayonNext && Math.abs(pawn.model.position.y + normVec.y * this.distByTime * dt - nextPoint.vector.y) < rayonNext) {
                            pawn.model.position.x = nextPoint.vector.x;
                            pawn.model.position.y = nextPoint.vector.y;

                            this.levels.step++;
                            if (this.levels.step === 0)
                                this.levels.step++;

                        } else {
                            pawn.model.position.x += normVec.x * this.distByTime * dt
                            pawn.model.position.y += normVec.y * this.distByTime * dt
                        }

                        if (this.scoreMod)
                            this.scoreMod.scoreDistance = Math.floor(Math.sqrt(pawn.model.position.x * pawn.model.position.x + pawn.model.position.y * pawn.model.position.y))
                    }
                }
            }
        }
    }

    restart = () => {
        if (this.elementTransition) {
            this.elementTransition.style.opacity = "1";
        }

        setTimeout(() => {
            this.poolManager?.freedom();
            this.random = new Random(0);

            for (let asset of this.preload?.assetList) {
                if (asset.name === 'XOHennessyGame') {
                    this.musicActual = 'XOHennessyGame';

                    this.sound?.setBuffer(asset.file);
                    this.sound?.setLoop(true);
                    this.sound?.setVolume(0.5);

                    this.sound?.play();
                }
            }

            // Level creation
            if (this.fe && this.levels) {
                this.fe.syncList.splice(this.fe.syncList.indexOf(this.levels), 1);
                this.levels?.unload();
                for (var i = 0; i < this.fe.syncList.length; i++) {
                    if (this.fe.syncList[i] === this.levels) {
                        this.fe.syncList.splice(i, 1);
                        i--;
                    }
                }
                this.levels = new LevelList(this.fe, this.city);
                this.fe.syncList.push(this.levels);

                // Decors loading
                this.decors?.unload();
                this.decors = new Decors(this.fe, this.city);
            }

            // Score reset
            this.scoreMod?.reset()

            this.button = document.getElementById("buttonContainer");
            this.elementRules = document.getElementById('rules');
            this.elementTransition = document.getElementById("transition");

            setTimeout(() => {
                if (this.elementTransition) {
                    this.elementTransition.style.opacity = "0";
                }

                this.distByTime = 5;
                this.lives = 1;
                this.stopping = false;
                //this.sound?.play();

                let pawn = this.fe?.players.get("me")?.pawn as PHero;
                if (pawn && this.fe) {
                    this.fe.scene.add(pawn.model.meshPlayerContainer);
                    this.totalTime = 0;
                    pawn.scrolling = true;
                    this.timerStart = Date.now();

                    let data = {
                        type: 'begin',
                        data: {}
                    }
                    window?.top?.postMessage(data, '*');

                    this.distByTime = 5;
                    this.lives = 1;
                    this.stopping = false;
                    this.godmode = false;
                    this.transToCognac = false;
                    this.cognac = false;
                    this.transToEnd = false;
                    this.end = false;

                    this.pickupList = new Map<string, number>();

                    pawn.model.changeCognac();
                }

            }, 1000);
        }, 1000)
    }

    replicate() {
        console.log("Endlessixo | FE - Map replicate to peers !");
    }
}

