function getNextRandomNumber(n: number) {

    /*n = n * n;
    let len = Math.log10(n) + 1;
    let index = Math.floor((len - 4) / 2)

    return Number(n.toString().slice(index, index + 4)) / 10000;*/
    /*n = Number(n.toString().slice(2)) % 10000;
    let tmp = '0.' + (16807 * n) % (2 ** 31 - 1)*/
    /*return Number(tmp);*/

    let a = 12;
    let b = 25;
    let m = 1000;

    n = (a * n + b) % m / 1000
    return n;
}

export class Random {
    private number;

    constructor(
        seed: number
    ) {
        this.number = seed;
    }

    random() {
        let rdm = getNextRandomNumber(this.number);
        this.number = Math.floor(rdm * 1000 + 1);

        return rdm;
    }
}