// Modules
import * as THREE from "three";
// GJ modules
import { FiktivEngine, Pawn, KeyBinding, Controller, update_input_type } from "../../../../fiktivengine_modules/fiktivengine-core/fiktiv";
import { Lendless } from "../../level/Lendless";
// Level
import { Model } from './';

export class PHero extends Pawn<Model> {
    public fe: FiktivEngine;
    public model: Model;
    public mouse: THREE.Vector2;
    public mouseState: boolean;
    public mouseMem: boolean;
    public keyboardState: boolean;
    public keyboardMem: boolean;
    public camera: THREE.OrthographicCamera;

    get pos(): THREE.Vector3 | undefined {
        if (this.model)
            return this.model.mesh.position;
        else
            return undefined;
    }

    public distance: number;
    public distCamToHero: number;
    public offsetCam: number;
    public cameraTarget: THREE.Vector3;

    public zoom: number;

    public scrolling: boolean;
    public lastPause: number;

    public pos2D: THREE.Vector2;

    // Jump
    public jumpOk: number;
    private posJump: THREE.Vector3;
    private distJump: number;
    private heightJump: number;

    constructor(
        //should not include fe… FIXME
        fe: FiktivEngine,
        pos: THREE.Vector3,
        quat: THREE.Quaternion,
    ) {
        super(new Model(fe, pos, quat));
        this.model = (this.feObject as Model);

        // Bases
        this.fe = fe; // TODO should be done outside
        this.mouse = new THREE.Vector2(); // Mouse position
        this.mouseState = false;
        this.mouseMem = false;
        this.keyboardState = false;
        this.keyboardMem = false;

        this.distance = 0
        this.distCamToHero = 10;
        this.offsetCam = -5;
        this.zoom = 0.7;
        this.scrolling = false;
        this.lastPause = Date.now(); // Manages doubleclick

        this.pos2D = new THREE.Vector2();

        // Jump
        this.jumpOk = 2;
        this.posJump = new THREE.Vector3(32, 32, 0);
        this.distJump = 2;
        this.heightJump = 0.6;

        // Synclist
        this.fe.syncList.push(this);
        this.fe.syncList.push(this.model);

        // Camera
        this.camera = new THREE.OrthographicCamera(
            -1,
            1,
            1,
            -1,
            -200,
            2000000
        );
        this.camera.position.set(20, 20, 30);
        this.cameraTarget = new THREE.Vector3(20, 20, 30);

        this.fe.cameraOperator.setFunctionUpdate((camera: THREE.PerspectiveCamera | THREE.OrthographicCamera) => {
            camera.rotation.set(0, 0, THREE.MathUtils.degToRad(180));
            camera.rotateOnWorldAxis(new THREE.Vector3(0, 0, 1), THREE.MathUtils.degToRad(-45));
            camera.rotateX(THREE.MathUtils.degToRad(55));
        })

        this.fe.changeCamera(this.camera);
        this.fe.cameraOperator.changeCamera(this.camera);

        this.updateCam(fe);
        this.fe.onWindowResize = (fe: FiktivEngine) => {
            this.updateCam(fe);
        }

        this.PUpdate(); // Overwritting FEObject3D update function
        this.PController(); // Set controller
    }

    jump() {
        let level = (this.fe.map as Lendless);
        if (this.model.position.x < 0 && level.lives > 0) {
            if (this.jumpOk === 2) {
                this.model.jump(true);
                this.jumpOk = 1;
                this.posJump.x = this.model.position.x;
                this.posJump.y = this.model.position.y;
                this.posJump.z = 0;
            } else if (this.jumpOk === 1) {
                this.model.doubleJump(true);
                this.jumpOk = 0;
                this.heightJump += 0.3;

                this.posJump.x = this.model.position.x;
                this.posJump.y = this.model.position.y;
                this.posJump.z = this.model.position.z;
            }
        }
    }

    updateCam(fe: FiktivEngine) {
        if (this.fe.graphic_enabled) {
            var aspect = this.fe.container.clientHeight / this.fe.container.clientWidth;

            let left = -10;
            let right = 10;
            let top = 10 * aspect;
            let bottom = -10 * aspect;

            this.distCamToHero = (top + bottom) * Math.tan(55 / 180 * Math.PI) + 100 + this.offsetCam;
            let tmpTop = top * Math.tan(55 / 180 * Math.PI) * this.zoom;

            (this.camera as THREE.OrthographicCamera).left = left * this.zoom;
            (this.camera as THREE.OrthographicCamera).right = right * this.zoom;
            (this.camera as THREE.OrthographicCamera).top = top * this.zoom;
            (this.camera as THREE.OrthographicCamera).bottom = bottom * this.zoom;

            this.cameraTarget.z = ((tmpTop * Math.cos(55 / 180 * Math.PI) / 2) + 102);

            this.camera.updateProjectionMatrix();
            this.fe.renderer.setSize(this.fe.container.clientWidth, this.fe.container.clientHeight);
        }
    }

    /* Pawn basic functions */
    PController() {
        let actions = new Map([
            ["mouseLeft", new KeyBinding("mouse0")],
            ["mouseRight", new KeyBinding("mouse2")],
            ["throttle", new KeyBinding("KeyW")],
            ["reverse", new KeyBinding("KeyS")],
            ["left", new KeyBinding("KeyA")],
            ["right", new KeyBinding("KeyD")],
            ["space", new KeyBinding("Space")],
            ["changeMode", new KeyBinding("KeyE")],
            ["touch", new KeyBinding("touch")],
            ["option1", new KeyBinding("Numpad1")],
            ["option2", new KeyBinding("Numpad2")],
            ["option3", new KeyBinding("Numpad3")],
            ["option4", new KeyBinding("Numpad4")],
            ["option5", new KeyBinding("Numpad5")]
        ]);
        this.controller = new Controller(actions);

        // Handle pointlock
        if (this.fe.inputManager) {
            this.fe.inputManager.setPointerLock(false);
        }

        this.controller.handleMouseButton = (e, c, p) =>
            this.handleMouseButton(e, c, p);
        this.controller.handleMouseMove = (e, dx, dy) =>
            this.handleMouseMove(e, dx, dy);
        this.controller.handleTouch = (e, c, p) =>
            this.handleTouch(e, c, p);
        this.controller.handleTouchMove = (e, dx, dy) =>
            this.handleTouchMove(e, dx, dy);
        this.controller.handleKeyboardEvent = (e, c, p) => {
            this.handleKeyboardEvent(e, c, p);
        }
    }

    PUpdate() {
        this.update = (args: update_input_type) => {
            if (this.fe.graphic_enabled) {
                let actions = this.controller?.actions;

                // Options
                if (actions?.get("option1")!.justReleased) {
                    if (this.keyboardMem) {
                        this.keyboardMem = false;
                        console.log("Opt. 1 :", (this.fe.map as Lendless).poolManager?.getAll());
                    }
                }

                if (actions?.get("option2")!.justReleased) {
                    if (this.keyboardMem) {
                        this.keyboardMem = false;
                        (this.fe.map as Lendless).godmode = !(this.fe.map as Lendless).godmode;
                        console.log("Opt. 2 : GodMode", (this.fe.map as Lendless).godmode);
                    }
                }

                if (actions?.get("option3")!.justReleased) {
                    if (this.keyboardMem) {
                        this.keyboardMem = false;
                        (this.fe.map as Lendless).cognac = !(this.fe.map as Lendless).cognac;
                        console.log("Opt. 3 : Change with Cognac", (this.fe.map as Lendless).cognac);

                        let map = (this.fe.map as Lendless);

                        if (map) {
                            if(map.levels?.actual?.slabs && map.levels?.previous?.slabs && map.levels?.next?.slabs) {
                                if (map.cognac === true) {
                                    for (let slab of map.levels?.previous?.slabs) {
                                        if (slab.sprite && slab.spriteCognac) {
                                            map.levels.previous.spriteContainer.remove(slab.sprite);
                                            map.levels.previous.spriteContainer.add(slab.spriteCognac);
                                        }
                                    }
                                    for (let slab of map.levels?.actual?.slabs) {
                                        if (slab.sprite && slab.spriteCognac) {
                                            map.levels.actual.spriteContainer.remove(slab.sprite);
                                            map.levels.actual.spriteContainer.add(slab.spriteCognac);
                                        }
                                    }
                                    for (let slab of map.levels?.next?.slabs) {
                                        if (slab.sprite && slab.spriteCognac) {
                                            map.levels.next.spriteContainer.remove(slab.sprite);
                                            map.levels.next.spriteContainer.add(slab.spriteCognac);
                                        }
                                    }
                                } else {
                                    for (let slab of map.levels?.previous?.slabs) {
                                        if (slab.sprite && slab.spriteCognac) {
                                            map.levels.previous.spriteContainer.add(slab.sprite);
                                            map.levels.previous.spriteContainer.remove(slab.spriteCognac);
                                        }
                                    }
                                    for (let slab of map.levels?.actual?.slabs) {
                                        if (slab.sprite && slab.spriteCognac) {
                                            map.levels.actual.spriteContainer.add(slab.sprite);
                                            map.levels.actual.spriteContainer.remove(slab.spriteCognac);
                                        }
                                    }
                                    for (let slab of map.levels?.next?.slabs) {
                                        if (slab.sprite && slab.spriteCognac) {
                                            map.levels.next.spriteContainer.add(slab.sprite);
                                            map.levels.next.spriteContainer.remove(slab.spriteCognac);
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                }

                if (actions?.get("option4")!.justReleased) {
                    if (this.keyboardMem) {
                        this.keyboardMem = false;
                        (this.fe.map as Lendless).speed = (this.fe.map as Lendless).speed ? 0 : 16;
                        console.log("Opt. 4 : Speeeeeed !", (this.fe.map as Lendless).speed);
                    }
                }

                if (actions?.get("option5")!.justReleased) {
                    if (this.keyboardMem) {
                        this.keyboardMem = false;
                        (this.fe.map as Lendless).totalTime += 15000;
                        console.log("Opt. 5 : One more time ! +15s ", (this.fe.map as Lendless).totalTime);
                    }
                }

                // Turn arround
                if (actions?.get("left")!.isPressed) {
                    this.model.lookTo("left", args.timestep);
                }
                if (actions?.get("right")!.isPressed) {
                    this.model.lookTo("right", args.timestep);
                }

                // Jump
                if ((actions?.get("reverse")!.isPressed)) {
                    if (!this.lastPause || Date.now() - this.lastPause > 150) {
                        this.lastPause = Date.now()
                        this.jump();
                    }
                    /*if (this.keyboardMem) {
                        this.keyboardMem = false;
                        this.jump();
                    }*/
                }

                if ((actions?.get("touch")!.isPressed && actions?.get("touch")!.pos.y > this.fe.container.clientHeight / 2)
                    || (actions?.get("mouseLeft")?.isPressed && actions?.get("mouseLeft")!.pos.y > this.fe.container.clientHeight / 2)) {
                    if (!this.lastPause || Date.now() - this.lastPause > 150) {
                        this.lastPause = Date.now()
                        this.jump();
                    }
                    /*if (this.mouseMem) {
                        this.mouseMem = false;
                        this.jump();
                    }*/
                }

                // Play/Pause
                if (actions?.get("space")!.justReleased) {
                    if (this.keyboardMem) {
                        this.keyboardMem = false;
                        this.scrolling = !this.scrolling;
                        if (this.scrolling) {
                            (this.fe.map as Lendless).timerStart = Date.now();
                        } else {
                            (this.fe.map as Lendless).totalTime += Date.now() - (this.fe.map as Lendless).timerStart;
                        }
                    }
                }

                // Game/Editor
                if (actions?.get("changeMode")!.isPressed) {
                    console.log("changeMode")
                }
            }

            // PLAYER JUMP
            let d = this.distJump;
            let h = this.heightJump;
            let a = (-4 * h) / Math.pow(d, 2);
            let b = 4 * h / d;

            let distToPosJump = Math.sqrt(Math.pow(this.model.position.x - this.posJump.x, 2) + Math.pow(this.model.position.y - this.posJump.y, 2));

            if (this.model.position.x < 0)
                this.model.position.z = this.posJump.z + a * Math.pow(distToPosJump, 2) + b * distToPosJump;

            if (this.model.position.z < 0) {
                this.model.position.z = 0;
                if (this.jumpOk < 2) {
                    this.jumpOk = 2;
                    this.posJump.x = 32;
                    this.posJump.y = 32;
                    this.heightJump = 0.4;
                }
            }

            this.model.meshShadow.scale.set(-0.7 / 3 * this.model.position.z + 1, -0.7 / 3 * this.model.position.z + 1, 1);

            if ((this.fe.map as Lendless).lives === 0) {
                let pool = (this.fe?.map as Lendless).poolManager;
                if (pool) {
                    if (this.model.meshSmoke && this.model.meshSmoke.sprite) {
                        this.model.meshSmoke.sprite.position.set(0, 0, 0);
                        this.model.meshDisparition = new THREE.Object3D();
                        this.model.meshDisparition.add(this.model.meshSmoke.sprite);
                    }
                }

                if (this.model.meshDisparition && this.model.meshSmoke) {
                    this.model.meshDisparition.position.set(this.model.meshPlayerContainer.position.x - 0.3, this.model.meshPlayerContainer.position.y - 0.3, this.model.meshPlayerContainer.position.z);
                    this.model.meshSmoke.reset();
                    this.fe.scene.add(this.model.meshDisparition);

                    let sound = (this.fe.map as Lendless).sounddesign?.get('SD_nuage');
                    if (sound) {
                        sound.play();
                    }
                }

                this.model.position.z -= 0.2;
                (this.fe.map as Lendless).stopping = true;
                (this.fe.map as Lendless).lives = -1;

                setTimeout(() => {
                    this.fe.scene.remove(this.model.meshPlayerContainer);
                    setTimeout(() => {
                        if (this.model.meshDisparition) {
                            this.fe.scene.remove(this.model.meshDisparition);
                        }
                    }, 180);
                }, 60);
            }

            // Camera update
            let x = this.model.position.x;
            let y = this.model.position.y;

            if (!x && !y)
                return;

            let hypotenuse = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
            let angleExtX = Math.acos(x / hypotenuse) * 180 / Math.PI; // deg
            let angleExtY = Math.acos(y / hypotenuse) * 180 / Math.PI; // deg
            let angleInt: number

            if (angleExtX > 0 && angleExtY < 90)
                angleInt = 45 - angleExtX; // deg
            else
                angleInt = 45 + angleExtX; // deg

            let dist = Math.cos(angleInt * Math.PI / 180) * hypotenuse;
            let newDist = dist + Math.sqrt(Math.pow(this.distCamToHero, 2) + Math.pow(this.distCamToHero, 2))
            let orthoX = Math.cos(45 * Math.PI / 180) * dist;
            let newCoord = orthoX * newDist / dist;

            this.distance = newDist;

            this.cameraTarget.set(newCoord, newCoord, this.cameraTarget.z);

            let actualPos = this.camera.position;

            this.camera.position.set(actualPos.x + (this.cameraTarget.x - actualPos.x) / (this.model.position.x < 0 ? 40 : 20), actualPos.y + (this.cameraTarget.y - actualPos.y) / (this.model.position.x < 0 ? 40 : 20), actualPos.z + (this.cameraTarget.z - actualPos.z) / 20)

            // Sandbox
            function toScreenPosition(renderer: any, obj: THREE.Object3D, camera: any) {
                var vector = new THREE.Vector3();

                /*var widthHalf = 0.5 * renderer.getContext().canvas.width;
                var heightHalf = 0.5 * renderer.getContext().canvas.height;*/

                obj.updateMatrixWorld();
                vector.setFromMatrixPosition(obj.matrixWorld);
                vector.project(camera);
				
				vector.x = (vector.x + 1) * 50;
                vector.y = (- vector.y + 1) * 50;

                return {
                    x: vector.x,
                    y: vector.y
                };

            };

            let tmp = toScreenPosition(this.fe.renderer, this.model.mesh, this.fe.camera);
            this.pos2D.x = tmp.x;
            this.pos2D.y = tmp.y;
        }
    }

    /* Interactions */
    handleMouseMove(event: any, deltaX: number, deltaY: number) {
        this.fe.cameraOperator.rotate(deltaX, deltaY);
        this.mouse.x = ((event.clientX - this.fe.container.offsetLeft) / (this.fe.container.clientWidth || window.innerWidth)) * 2 - 1;
        this.mouse.y = - ((event.clientY - this.fe.container.offsetTop) / (this.fe.container.clientHeight || window.innerHeight)) * 2 + 1;
    }

    handleMouseWheel(event: any, value: number) {
        this.fe.cameraOperator!.zoom(value);
    }

    handleMouseButton(event: any, code: string, pressed: boolean) {
        this.mouse.x = ((event.clientX - this.fe.container.offsetLeft) / (this.fe.container.clientWidth || window.innerWidth)) * 2 - 1;
        this.mouse.y = - ((event.clientY - this.fe.container.offsetTop) / (this.fe.container.clientHeight || window.innerHeight)) * 2 + 1;
        if (this.controller) {
            for (let binding of this.controller.actions.values()) {
                if (binding.eventCodes === code) {
                    if (binding.isPressed !== pressed) {
                        binding.isPressed = pressed;

                        if (binding.justPressed !== pressed && this.mouseState !== true) {
                            binding.justReleased = pressed;
                            this.mouseState = true;
                            this.mouseMem = true;
                        } else if (binding.justPressed === pressed && this.mouseState === true) {
                            binding.justPressed = pressed;
                            binding.justReleased = !binding.justReleased;
                            this.mouseState = false;
                        }

                        if (!binding.pos)
                            binding.pos = new THREE.Vector2((event.clientX - this.fe.container.offsetLeft), (event.clientY - this.fe.container.offsetTop));
                        else {
                            binding.pos.set((event.clientX - this.fe.container.offsetLeft), (event.clientY - this.fe.container.offsetTop));
                        }
                        this.mouse.x = ((event.clientX - this.fe.container.offsetLeft) / (this.fe.container.clientWidth || window.innerWidth)) * 2 - 1;
                        this.mouse.y = - (((event.clientY - this.fe.container.offsetTop) / (this.fe.container.clientHeight || window.innerHeight)) * 2 - 1);
                    }
                }
            }
        } else {
            console.warn("[Spectator]: Controller undef ?");
        }
    }

    handleTouch(event: any, code: string, pressed: boolean) {
        if (this.controller) {
            for (let binding of this.controller.actions.values()) {
                if (binding.eventCodes === code) {
                    if (binding.isPressed !== pressed) {
                        binding.isPressed = pressed;

                        if (binding.justPressed !== pressed && this.mouseState !== true) {
                            binding.justReleased = pressed;
                            this.mouseState = true;
                            this.mouseMem = true;
                        } else if (binding.justPressed === pressed && this.mouseState === true) {
                            binding.justPressed = pressed;
                            binding.justReleased = !binding.justReleased;
                            this.mouseState = false;
                        }

                        if (!binding.pos)
                            binding.pos = new THREE.Vector2((event.changedTouches[0].clientX - this.fe.container.offsetLeft), (event.changedTouches[0].clientY - this.fe.container.offsetTop));
                        else {
                            binding.pos.set((event.changedTouches[0].clientX - this.fe.container.offsetLeft), (event.changedTouches[0].clientY - this.fe.container.offsetTop));
                        }
                        this.mouse.x = ((event.changedTouches[0].clientX - this.fe.container.offsetLeft) / (this.fe.container.clientWidth || window.innerWidth)) * 2 - 1;
                        this.mouse.y = - (((event.changedTouches[0].clientY - this.fe.container.offsetTop) / (this.fe.container.clientHeight || window.innerHeight)) * 2 - 1);
                    }
                }
            }
        } else {
            console.warn("[Spectator]: Controller undef ?");
        }
    }

    handleTouchMove(event: any, deltaX: number, deltaY: number) {

    }

    handleKeyboardEvent(event: any, code: string, pressed: boolean) {
        //console.log("KeyboardEvent :", event, code, pressed)
        if (this.controller) {
            for (let binding of this.controller.actions.values()) {
                if (binding.eventCodes === code) {
                    if (binding.isPressed !== pressed) {
                        binding.isPressed = pressed;
                    }

                    if (binding.justPressed !== pressed && this.keyboardState !== true) {
                        binding.justReleased = pressed;
                        this.keyboardState = true;
                        this.keyboardMem = true;
                    } else if (binding.justPressed === pressed && this.keyboardState === true) {
                        binding.justPressed = pressed;
                        binding.justReleased = !binding.justReleased;
                        this.keyboardState = false;
                    }
                }
            }
        } else {
            console.warn("[Spectator]: Controller undef ?");
        }
    }
}
