// Game
import { useState } from 'react';
import { useLocation } from 'react-router';
import { EndlessIxo } from './hennessyxo_modules/hennessyxo-endlessixo';

export default function App() {
    console.log("Hennessy | Start WebSite");

    let location = useLocation();

    let queries = location.hash.split('#');

    let [city] = useState(queries[1]);
    let [nodead] = useState(queries[3] && queries[3] === 'g');

	let baseLang = "en"

	if(queries[2] == "zh-hans" || queries[2] == "zh-hant" || queries[2] == "en"){
		baseLang = queries[2]
	}

	let [lang] = useState(baseLang);

	console.log(queries)

    return (
        <EndlessIxo city={city} lang={lang} nodead={nodead} />
    );
}