// Modules
import * as THREE from "three";

import { Sphere_cons_type, Sphere } from "../objects/Sphere";
import { Pawn } from "../engine/Pawn";
import { KeyBinding } from "../engine/KeyBinding";

import { update_input_type, FiktivEngine } from "../index";

export class Sphero extends Pawn<Sphere> {

	public fe: FiktivEngine;
	//public camera: THREE.PerspectiveCamera;

	constructor(
		fe: FiktivEngine,
		material: THREE.MeshStandardMaterial,
		pos: THREE.Vector3,
		quat: THREE.Quaternion,
		r: number = 1,
		mass: number = 0,
		friction: number = 1,
		physic: boolean = true,
		speed: number = 10
	) {
		let actions = new Map([
			["up", new KeyBinding("KeyW")],
			["down", new KeyBinding("KeyS")],
			["jump", new KeyBinding("Space")],
			["left", new KeyBinding("KeyA")],
			["right", new KeyBinding("KeyD")],
		]);
		super(
			new Sphere({
				fe: fe,
				material: material,
				pos: pos,
				quat: quat,
				r: r,
				mass: mass,
				friction: friction,
				physic: physic,
				speed: speed,
			} as Sphere_cons_type),
			actions
		);

		this.fe = fe;

		let _this = this;

		//let temp = ""+_this.fe.renderer.xr.isPresenting+""

		var rootCam = new THREE.Object3D();
		rootCam.add( _this.fe.camera );
		_this.fe.scene.add(rootCam);

		fe.renderer.xr.addEventListener('sessionstart', function () {
			_this.fe.debugxrlog("Start VR")
			rootCam.rotateX(THREE.MathUtils.degToRad(90))
			rootCam.rotateOnWorldAxis(new THREE.Vector3(0, 0, 1), THREE.MathUtils.degToRad(-90))
			rootCam.scale.set(5, 5, 5)
			/*_this.fe.cameraOperator.setFunctionUpdate((camera: THREE.PerspectiveCamera | THREE.OrthographicCamera) => {

			})*/
		});	
		
		fe.renderer.xr.addEventListener('sessionend', function () {
			rootCam.rotateOnWorldAxis(new THREE.Vector3(0, 0, 1), THREE.MathUtils.degToRad(90))
			rootCam.rotateX(THREE.MathUtils.degToRad(-90))
			rootCam.scale.set(1, 1, 1)
			// We should null the functionupdate somewhere in cameraOperator:
			/*_this.fe.cameraOperator.setFunctionUpdate((camera: THREE.PerspectiveCamera | THREE.OrthographicCamera) => {})*/
		});

		this.controller!.handleMouseMove = (e, dx, dy) =>
			this.handleMouseMove(e, dx, dy);
		this.controller!.handleKeyboardEvent = (e, c, p) =>
			this.handleKeyboardEvent(e, c, p);

		this.update = (args: update_input_type) => {
			if (this.feObject && this.feObject.mass > 0) {
				let sphere: Sphere = this.feObject;

				//Deal with input to apply impulse
				let applyImpulse = false;

				if (sphere.fe.graphic_enabled && this.physic) {
					let tempTheta = -sphere.fe.cameraOperator!.theta - 90;

					let tempZ = 0;

					// let change = false;
					let actions = this.controller?.actions;

					if (actions?.get("up")!.isPressed) {
						// this.mesh.position.x = this.mesh.position.x + 0.1;
						// change = true;
						applyImpulse = true;
					}
					if (actions?.get("down")!.isPressed) {
						// this.mesh.position.x = this.mesh.position.x - 0.1;
						// change = true;
						applyImpulse = true;
						tempTheta += 180;
					}
					if (actions?.get("left")!.isPressed) {
						// this.mesh.position.y = this.mesh.position.y + 0.1;
						// change = true;
						applyImpulse = true;
						tempTheta += 90;
					}
					if (actions?.get("right")!.isPressed) {
						// this.mesh.position.y = this.mesh.position.y - 0.1;
						// change = true;
						applyImpulse = true;
						tempTheta -= 90;
					}
					if (actions?.get("jump")!.isPressed) {
						tempZ = 100;
					}

					// if (change) this.fe.natverk.send_pos(this.mesh.position);

					if (
						sphere.fe.physic_enabled &&
						this.physic &&
						this.feObject &&
						sphere.speed
					) {
						let body = this.feObject.body!;
						if (applyImpulse) {
							let tempX = Math.cos((tempTheta * Math.PI) / 180) * sphere.speed;
							let tempY = Math.sin((tempTheta * Math.PI) / 180) * sphere.speed;

							body.applyCentralImpulse(new Ammo.btVector3(tempX, tempY, 0));
						}

						if (tempZ !== 0) {
							body.applyCentralImpulse(new Ammo.btVector3(0, 0, tempZ));
						}

						//Usual physic update
						sphere.update({ timestep: args.timestep });
					}
				}
			}
		};
		this.feObject!.fe.syncList.push(this);
	}

	handleMouseMove(event: any, deltaX: number, deltaY: number) {
		if (this.feObject) {
			this.feObject.fe.cameraOperator.rotate(deltaX, deltaY);
		}
		else console.warn("No object for pawn");
	}

	handleMouseWheel(event: any, value: number) {
		//Nothing for now
	}

	handleMouseButton(event: any, code: string, pressed: boolean) {
		//Nothing for now
	}

	handleKeyboardEvent(event: any, code: string, pressed: boolean) {
		//could be more effecient to only get from code…
		//this.actions.get(code) … up down left right is useless
		if (this.controller) {
			for (let binding of this.controller.actions.values()) {
				if (binding.eventCodes === code) {
					if (binding.isPressed !== pressed) {
						binding.isPressed = pressed;
					}
				}
			}
		} else {
			console.warn("[Sphero]: Controller undef ?");
		}
	}
}
