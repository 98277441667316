import { PerspectiveCamera, OrthographicCamera, Vector2 } from "three";
import { Pawn } from "./Pawn";
import { Player } from "./Player";
import { FiktivObject3D } from "./Object";
import { update_input_type } from "./FiktivEngine";
// import { KeyBinding } from "./KeyBinding";

export class CameraOperator extends Pawn<FiktivObject3D> {
    // public actions: Map<Action, KeyBinding>;
    private camera: PerspectiveCamera | OrthographicCamera;
    private sensitivity: Vector2;
    private _theta: number;
    private phi: number;
    private radius: number;
    private player: Player | undefined;
    private fnUpdate: Function | null;

    get theta(): number {
        return this._theta;
    }

    set theta(t: number) {
        this._theta = t;
    }

    constructor(camera: PerspectiveCamera | OrthographicCamera, params?: any) {
      super();
      this.camera = camera;

      this.sensitivity = new Vector2(0.3, 0.3);
      this._theta = 270;
      this.phi = 20;
      this.radius = 10;
      this.player = undefined;
      this.fnUpdate = null;

      if (params && params.theta) {
        this._theta = params.theta;
      }
      if (params && params.phi) {
        this.phi = params.phi;
      }

      if (this.controller) {
        this.controller.handleMouseMove = this.handleMouseMove;
        this.controller.handleMouseWheel = this.handleMouseWheel;
      }

      this.update = (args:update_input_type) => {
        //let position = vars.player.pos;

        if (this.fnUpdate !== null) {
          this.fnUpdate(this.camera);
        } else if (this.player && this.player.pawn) {
          let pawn_object3D = this.player.pawn;
          if (pawn_object3D) {
            this.camera.position.x =
              pawn_object3D.position.x +
              this.radius *
                Math.sin((this.theta * Math.PI) / 180) *
                Math.cos((this.phi * Math.PI) / 180);
            this.camera.position.y =
              pawn_object3D.position.y +
              this.radius *
                Math.cos((this.theta * Math.PI) / 180) *
                Math.cos((this.phi * Math.PI) / 180);
            this.camera.position.z =
              pawn_object3D.position.z +
              this.radius * Math.sin((this.phi * Math.PI) / 180);
            this.camera.lookAt(pawn_object3D.position);
          }
        }
      };
    }

    changeCamera(camera: THREE.OrthographicCamera | THREE.PerspectiveCamera) {
        this.camera = camera
    }

    setPlayer(player: Player) {
        this.player = player;
    }

    setFunctionUpdate(fnUpdate: Function){
        this.fnUpdate = fnUpdate;
    }

    rotateIso(deltaX: number, deltaY: number) {
        this.theta += deltaX
        this.theta %= 360;

        console.log("this.phi =", this.phi)

        this.phi = deltaY
        this.phi %= 360;
        //this.phi = Math.min(85, Math.max(-85, this.phi));
    }

    rotate(deltaX: number, deltaY: number) {
        this.theta += deltaX * (this.sensitivity.x / 2);
        this.theta %= 360;
        this.phi += deltaY * (this.sensitivity.y / 2);
        this.phi = Math.min(85, Math.max(-85, this.phi));
    }

    zoom(value: number) {
        this.radius += value / 50;
    }

    setRadius(r: number) {
        this.radius = r;
    }

    setTheta(t: number) {
        this.theta = t;
    }

    setPhi(p: number) {
        this.phi = p;
    }

    handleMouseMove(event: any, deltaX: number, deltaY: number) {
        //this.rotate(deltaX, deltaY);
        console.log(event);
    }

    handleMouseWheel(event: any, value: number) {
        this.zoom(value);
        console.log(event);
    }

    handleMouseButton(event: any, code: string, pressed: boolean) {
        //Nothing for now
    }

    handleKeyboardEvent(event: any, code: string, pressed: boolean) {
        //Nothing for now
    }
}
