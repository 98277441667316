import { FiktivObject3D } from "./Object";
import { Actor } from "./Actor";
import { Controller, Action } from "./Controller";
import { KeyBinding } from "./KeyBinding";
import { Vector3 } from "three";

export class Pawn<T extends FiktivObject3D> extends Actor {
  private _controller: Controller | undefined; //Controller must be set
  private _feObject: T | undefined;

  set position(position: Vector3) {
    this.setPosition(position);
  }

  get position(): Vector3 {
    if (this.feObject === undefined) console.error("position not defined ! No Object linked to this pawn.");
      return this.feObject!.mesh.position
  }

  set physic(physic: boolean) {
    this.feObject!.physic = physic
  }

  get physic(): boolean {
    if (this.feObject === undefined) console.error("physic not defined ! No Object linked to this pawn.");
      return this.feObject!.physic
  }

  set controller(controller: Controller | undefined) {
    this._controller = controller;
  }

  get controller(): Controller | undefined {
    return this._controller;
  }

  set feObject(feObject: T | undefined) {
    this._feObject = feObject;
  }

  get feObject(): T | undefined {
    return this._feObject;
  }

  constructor(feObject?: T, actions?: Map<Action, KeyBinding>) {
    super();
    this._feObject = feObject

    if(feObject){
      this.move = feObject.move
      this.update = feObject.update
      this.setPosition = feObject.setPosition

      // Is also one child
      this.addChild(feObject);
    }
    this._controller = new Controller(actions);
  }
}
