import * as THREE from "three";

//Core
import { Player } from "./Player";
import { CameraOperator } from "./CameraOperator";
import { InputManager } from "./InputManager";
import { BulletWorld } from "./BulletWorld";
import { FiktivLevel } from "./FiktivLevel";
// import { NatverkClient } from "../../natverk_modules/natverk-core/index"; // TODON
import { Spectator } from "../pawns/Spectator";
import { Sphero } from "../pawns/Sphero";

import Ammo from "ammojs-typed";
import { Sandbox } from "../content/maps/Sandbox";

import { io, Socket } from "socket.io-client";

//TODO

export type update_input_type = {timestep: number, auxTransform?: Ammo.btTransform}
export type update_type = (args:update_input_type) => void
export type fiktiv_input_type = {
  container?: any; 
  graphic_enabled?: boolean;
  input_enabled?: boolean;
  physic_enabled?: boolean;
  map?: FiktivLevel | string;
  multi?: boolean;
  orthographic?: boolean
}

export interface IUpdatable {
  update(args:update_input_type): void;
}

export class FiktivEngine {
  //Parameters that have to be defined
  private _clock: THREE.Clock;
  private _scene: THREE.Scene;
  private _physic_enabled: boolean;
  private _graphic_enabled: boolean;
  private _input_enabled: boolean;
  private _readyToAnimate: boolean;
  private _pointLock: boolean;
  //FIXME apparemment les objets dans syncList doivent implémenter la "update"
  //updatables ?
  //Ce ne seraient pas des objets THREE.Object3D ?
  private _syncList: IUpdatable[];
  private _players: Map<string, Player>;

  //Parameters that can be undef
  //(if fiktiv is running in server side for instance)
  private _camera:
    | THREE.PerspectiveCamera
    | THREE.OrthographicCamera
    | undefined;
  private _orthographic: boolean | undefined;
  private _renderer: THREE.WebGLRenderer | undefined;
  // private stats: Stats | undefined //FIXME can't be loaded as typescripted
  private _bulletWorld: BulletWorld | undefined;
  private _inputManager: InputManager | undefined;
  private _cameraOperator: CameraOperator | undefined;
  //Container DOM html quelque chose… FIXME
  private _container: HTMLDivElement;
  // private _natverk: typeof NatverkClient | undefined; // TODON

  //Can be undef
  private _map: FiktivLevel | undefined;
  private _ammo: typeof Ammo | undefined;

  private _multi: boolean | undefined;

  private _debugxr: Socket | undefined;

  get multi(): boolean {
    if (this._multi === undefined) console.error("multi not defined !");
    return this._multi!;
  }

  set multi(multi: any) {
    this._multi = multi;
  }

  //Functions ref
  private _functions: Map<string, () => any> | undefined;

  get ammo(): any {
    if (this._ammo === undefined) console.error("ammo not defined !");
    return this._ammo!;
  }

  set ammo(ammo: any) {
    this._ammo = ammo;
  }

  get map(): FiktivLevel {
    if (this._map === undefined) console.error("map not defined !");
    return this._map!;
  }

  set map(map: FiktivLevel) {
    this._map = map;
  }

  /* get natverk(): typeof NatverkClient {
        if (this._natverk === undefined) console.error("natverk not defined !");
        return this._natverk!;
    }

    set natverk(natverk: typeof NatverkClient) {
        this._natverk = natverk;
    } */ // TODON

  get pointLock(): boolean {
    return this._pointLock;
  }

  get readyToAnimate(): boolean {
    return this._readyToAnimate;
  }

  get input_enabled(): boolean {
    return this._input_enabled;
  }

  get graphic_enabled(): boolean {
    return this._graphic_enabled;
  }

  get physic_enabled(): boolean {
    return this._physic_enabled;
  }

  get scene(): THREE.Scene {
    return this._scene;
  }

  set scene(scene: THREE.Scene) {
    this._scene = scene;
  }

  get clock(): THREE.Clock {
    return this._clock;
  }

  set clock(clock: THREE.Clock) {
    this._clock = clock;
  }

  get players(): Map<string, Player> {
    return this._players;
  }

  get syncList(): IUpdatable[] {
    return this._syncList;
  }

  get container(): HTMLDivElement {
    return this._container;
  }

  get bulletWorld(): BulletWorld {
    if (this._bulletWorld === undefined)
      console.error("Puffyland | FE -BulletWorld not defined !");
    return this._bulletWorld!;
  }

  get cameraOperator(): CameraOperator {
    if (this._cameraOperator === undefined)
      console.error("Puffyland | FE -CameraOperator not defined !");
    return this._cameraOperator!;
  }

  get renderer(): THREE.WebGLRenderer {
    if (this._renderer === undefined)
      console.error("Puffyland | FE -renderer not defined !");
    return this._renderer!;
  }

  set renderer(renderer: THREE.WebGLRenderer) {
    this._renderer = renderer;
  }

  get camera(): THREE.PerspectiveCamera | THREE.OrthographicCamera {
    if (this._camera === undefined)
      console.error("Puffyland | FE -camera not defined !");
    return this._camera!;
  }

  set camera(camera: THREE.PerspectiveCamera | THREE.OrthographicCamera) {
    this._camera = camera;
  }

  get inputManager(): InputManager {
    if (this._inputManager === undefined)
      console.error("Puffyland | FE -inputManager not defined !");
    return this._inputManager!;
  }

  constructor({
    graphic_enabled = true,
    input_enabled = true,
    physic_enabled = true,
    container = undefined,
    map = "default",
    // natverk?: typeof NatverkClient, // TODO
    multi = false,
    orthographic = false,
  }: fiktiv_input_type) {
    //Parameters that have to be defined
    this._graphic_enabled = graphic_enabled;
    this._physic_enabled = physic_enabled;
    this._input_enabled = input_enabled;
    this._clock = new THREE.Clock();
    this._scene = new THREE.Scene();
    this._readyToAnimate = false;
    this._syncList = [];
    this._players = new Map<string, Player>();
    this._pointLock = true;
    this._ammo = undefined;
    // this._natverk = natverk; // TODON

    //Parameters that can be undef
    //(if fiktiv is running in server side for instance)
    this._bulletWorld = undefined;
    this._camera = undefined;
    this._orthographic = orthographic;
    this._renderer = undefined;
    // this.stats = undefined; //FIXME can't load stats typed
    this._cameraOperator = undefined;
    this._inputManager = undefined;

    //Only client side ?
    this._container = container;

    //Don't know if it has to be set for any fiktiv instance ?
    //Does a FiktiveEngine can run without map ?
    if (map === "default") this._map = new Sandbox();
    else if (map as FiktivLevel) this._map = map as FiktivLevel;
    else this._map = undefined;

    this.multi = multi;

    console.log("Puffyland | FE - Init");

    if (graphic_enabled) {
      this.initGraphics();
      //the camera should be defined after initGraphics
      this._cameraOperator = new CameraOperator(this.camera);
      //Input can only be enabled if graphic are ? FIXME
      if (input_enabled && this.cameraOperator.controller) {
        this._inputManager = new InputManager(
          this.renderer.domElement,
          this.pointLock
        );
        this.inputManager.setInputReceiver(this.cameraOperator.controller);
      }
    }

    if (physic_enabled) {
      Ammo(Ammo).then(() => {
        this._ammo = Ammo;
        this._bulletWorld = new BulletWorld(this._ammo);
        console.log("Puffyland | FE - Physic enabled");
        console.log("Puffyland | FE - ", this.bulletWorld);
        this._readyToAnimate = true;
        if (this.map) {
          this.map.init(this);
        } else {
          alert("Puffyland | FE - No map loaded !");
        }
        this.map.onStart({});
        this.start();
      });
    } else {
      this._readyToAnimate = true;

      if (this.map) {
        this.map.init(this);
      } else {
        alert("Puffyland | FE - No map loaded !");
      }
      this.map.onStart({});
      this.start();
    }

    // Debug xr
    if (process.env.REACT_APP_DEBUGXR_ENABLED) {
      let url =
        "https://" +
        process.env.REACT_APP_DEBUGXR_URL +
        ":" +
        process.env.REACT_APP_DEBUGXR_PORT;

      this._debugxr = io(url, {
        rejectUnauthorized: false,
        secure: true,
      });

      this._debugxr.on("connect_error", function () {
        console.log("Connection Failed");
      });

      let _this = this;

      this._debugxr.on("who", function () {
        var who = "win64";
        var nAgt = navigator.userAgent;

        if (nAgt.indexOf("Quest") !== -1) {
          who = "Quest";
        }

        if (_this && _this._debugxr) {
          _this._debugxr.emit("who", who);
        }
      });
    }
  }

  public debugxrlog(data: any) {
    if (process.env.REACT_APP_DEBUGXR_ENABLED) {
      if (this._debugxr?.connected) {
        this._debugxr.emit("log", data);
      }
    }
  }

  public start() {
    this.renderer.setAnimationLoop(() => {
      // Update
      this.subanimate();

      // Render
      if (this.graphic_enabled) {
        this.renderer.render(this.scene, this.camera);
      }
    });
  }

  public stop() {
    this.renderer.setAnimationLoop(null);
  }

  private async subanimate() {
    // TODO : Behavior to remove from module :

    let me = this.players.get("me")!;
    if (this.map.winGame && this.map.winGame({player:me})) {
      alert("You won !");
      // this.natverk.callFunc("send_won"); // TODON
      this.map.onStop({});
    } else {
      if (this.map.looseGame && this.map.looseGame({player:me})) {
        this.map.onLoose({ player: me });
      }

      //window.requestAnimationFrame(() => this.subanimate());

      if (this.readyToAnimate) {
        //Animate target by gamepad
        //gamepadd(input);

        this.scene.updateMatrixWorld();

        var dt = this.clock.getDelta();

        this.map.update(dt);
        for (var i = 0; i < this.syncList.length; i++) {
          this.syncList[i].update({ timestep: dt });
        }

        if (this.physic_enabled)
          this.bulletWorld.physicsWorld.stepSimulation(dt, 10);

        if (this.graphic_enabled) this.cameraOperator.update({ timestep: dt });
      }
    }
  }

  private animate() {
    this.subanimate();
    //var delta = clock.getDelta();
  }

  private initGraphics() {
    //Already done
    // this.clock = new THREE.Clock();

    THREE.Object3D.DefaultUp = new THREE.Vector3(0, 0, 1);

    var aspect =
      (this.container.clientHeight || window.innerHeight) /
      (this.container.clientWidth || window.innerWidth);

    //Camera
    if (this._orthographic) {
      this.camera = new THREE.OrthographicCamera(
        -25,
        25,
        25 * aspect,
        -25 * aspect,
        -200,
        2000000
      );
    } else {
      this.camera = new THREE.PerspectiveCamera(
        45,
        (this.container.clientWidth || window.innerWidth) /
          (this.container.clientHeight || window.innerHeight),
        0.01,
        2000000
      );
    }
    // var ISO_QUATERNION: THREE.Quaternion = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0.96, 0, 0.79), Math.PI / 2)
    this.camera.up.set(0, 0, 1);
    this.camera.position.set(30, 30, 30);
    //this.camera.lookAt(new THREE.Vector3(0.96, 0, 0.79));
    /*this.camera.rotation.x = 45 * Math.PI / 180;
        this.camera.rotation.z = 55 * Math.PI / 180;*/

    //Scene already init in constructor
    // this.scene = new THREE.Scene();

    //Renderer
    let paramsRenderer = {
      antialias: true,
    };

    // FIXME fiktivEngine never had a canvas…
    // if (this.canvas !== null) {
    //   paramsRenderer.canvas = this.canvas;
    // }

    this.renderer = new THREE.WebGLRenderer(paramsRenderer);
    console.log("Puffyland | FE - ", this.renderer);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(
      this.container.clientWidth || window.innerWidth,
      this.container.clientHeight || window.innerHeight
    );
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    //renderer.gammaInput = true;
    //renderer.gammaOutput = true;

    this.container.innerHTML = "";
    this.container.appendChild(this.renderer.domElement);
    let _this = this;
    window.addEventListener(
      "resize",
      function () {
        _this.onWindowResize(_this);
      },
      false
    );

    // stats FIXME stats for typescript does not exist…
    // this.stats = new Stats();
    // this.container.appendChild(this.stats.dom);
  }

  public onWindowResize(fe: FiktivEngine) {
    if (this.graphic_enabled) {
      if (!this._orthographic) {
        console.log("Non-orthographic");
        (fe.camera as THREE.PerspectiveCamera).aspect =
          this.container.clientWidth ||
          window.innerWidth / this.container.clientHeight ||
          window.innerHeight;
      } else {
        var aspect =
          (this.container.clientHeight || window.innerHeight) /
          (this.container.clientWidth || window.innerWidth);

        console.log("Orthographic");
        (fe.camera as THREE.OrthographicCamera).left = -25;
        (fe.camera as THREE.OrthographicCamera).right = 25;
        (fe.camera as THREE.OrthographicCamera).top = 25 * aspect;
        (fe.camera as THREE.OrthographicCamera).bottom = -25 * aspect;
      }
      fe.camera.updateProjectionMatrix();
      fe.renderer.setSize(
        this.container.clientWidth || window.innerWidth,
        this.container.clientHeight || window.innerHeight
      );
    }
  }

  public changeCamera(
    camera: THREE.PerspectiveCamera | THREE.OrthographicCamera
  ) {
    this.camera = camera;
  }

  public newPlayer(nick: string, id: string): Player {
    //Maybe using color-hash
    var redMaterial = new THREE.MeshStandardMaterial({
      color: 0xff2222,
      depthWrite: true,
      depthTest: true,
      metalness: 0.8,
      opacity: 1,
    });
    var ZERO_QUATERNION = new THREE.Quaternion(0, 0, 0, 1);
    /* var ISO_QUATERNION = new THREE.Quaternion().setFromEuler(new THREE.Euler( 45 * Math.PI / 180, 0, 55 * Math.PI / 180, 'XYZ' )) */
    let pawn = new Spectator(
      this,
      redMaterial,
      // Ugly ↓  FIXME
      new THREE.Vector3().copy(this.map.initPos),
      ZERO_QUATERNION
    );
    let p = new Player(nick, id, pawn);
    this.players.set(nick, p);
    return p;
  }

  public newPlayerPeer(nick: string, id: string): Player {
    //Maybe using color-hash
    var checkerMaterial = new THREE.MeshStandardMaterial({
      color: 0xffffff,
      depthWrite: true,
      depthTest: true,
      metalness: 0.8,
    });
    var ZERO_QUATERNION = new THREE.Quaternion(0, 0, 0, 1);
    let pawn = new Sphero(
      this,
      checkerMaterial,
      // Ugly ↓  FIXME
      new THREE.Vector3().copy(this.map.initPos),
      ZERO_QUATERNION,
      1,
      100,
      0.95,
      false //with/without physic
    );
    let p = new Player(nick, id, pawn);
    this.players.set(nick, p);
    return p;
  }

  public removePlayer(peerId: string): void {
    this.players.forEach((value) => {
      console.log("value=", value);
      if (value.id === peerId) {
        if (value.pawn?.feObject?.mesh)
          this.scene.remove(value.pawn.feObject.mesh);
        if (value.pawn) {
          /* value.pawn.remove() */
          value.pawn.feObject = undefined;
          value.pawn.controller = undefined;
          value.pawn = undefined;
        }
        this.players.delete(value.nick);
        return;
      }
    });
    console.log("this.players=", this.players);
  }

  private send_update() {
    //Refresh rate of this player position over network
    setTimeout(() => this.send_update(), 20);
    this.send_player_position();
  }

  public send_player_position() {
    //let me = this.players.get("me")!;
    // let mesh = me.pawn?.feObject?.mesh;
    /* if (mesh)
            this.natverk.send_pos(mesh.position, mesh.quaternion); */
    // TODON
  }

  // Functions ref
  public call(name: string, args: any[]): any {
    let tmp = this._functions?.get(name);
    if (tmp) tmp(...(args as []));
  }

  public register(name: string, func: () => any): () => any {
    if (this._functions === undefined) this._functions = new Map();
    this._functions.set(name, func);
    return func;
  }

  public async new_map(map: FiktivLevel) {
    this._readyToAnimate = false;
    this._scene = new THREE.Scene();
    this._map = map;

    if (this.physic_enabled) {
      this._bulletWorld = new BulletWorld(await this._ammo!);
      console.log("Puffyland | FE - Physic enabled");
      console.log("Puffyland | FE - ", this.bulletWorld);
    }

    this._readyToAnimate = true;

    if (this.map) {
      this.map.init(this);
    } else {
      alert("Puffyland | FE - No map loaded !");
    }

    this.map.onStart({});
    this.animate();
    this.send_update();
  }

  /* Create Object */
  public new_object(mesh: THREE.Mesh) {
    this._scene.add(mesh);
  }

  public execFunc(func: any) {
    func();
  }
}


