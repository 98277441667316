import * as THREE from "three";
import { FiktivEngine, FiktivObject3D, update_input_type } from "../../../../fiktivengine_modules/fiktivengine-core/fiktiv";
import { Lendless } from "../../level/Lendless";
import { AnimatedSprite } from "../utils/AnimatedSprite";

export class AnimatedBirdContainer extends FiktivObject3D {
    private fe?: FiktivEngine;
    public container: THREE.Object3D;

    public bird?: AnimatedSprite;
    public speed1?: number;
    public bird2?: AnimatedSprite;
    public speed2?: number;
    public bird3?: AnimatedSprite;
    public speed3?: number;
    public bird4?: AnimatedSprite;
    public speed4?: number;

    constructor(
        fe: FiktivEngine | undefined
    ) {
        super();

        this.fe = fe;
        this.container = new THREE.Object3D();

        let city = (this.fe?.map as Lendless).city || "paris";

        let textures = (fe?.map as Lendless).preload.assetList;

        if (city === "paris") {
            for (let asset of textures) {
                if (asset.name === "decor_birds1") {
                    if (asset.file) {
                        let data = {
                            w: 5,
                            h: 4,
                            currentTile: 0,
                            max: 20
                        };

                        data.currentTile = Math.floor(Math.random() * data.max);
                        this.bird = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data, 24);
                        this.bird.sprite.scale.set(1.2, 1.2, 1);

                        let rdm = Math.random() * 40 + 20;
                        this.bird.sprite.position.set(rdm, rdm - 12, 5);

                        this.container.add(this.bird.sprite);
                        this.speed1 = Math.random() * 0.03 + 0.01;

                        data.currentTile = Math.floor(Math.random() * data.max);
                        this.bird2 = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data, 24);
                        this.bird2.sprite.scale.set(1.2, 1.2, 1);

                        rdm = Math.random() * 40 + 20;
                        this.bird2.sprite.position.set(rdm, rdm - 12, 5);

                        this.container.add(this.bird2.sprite);
                        this.speed2 = Math.random() * 0.03 + 0.01;
                    }
                }
            }

            for (let asset of textures) {
                if (asset.name === "decor_birds2") {
                    if (asset.file) {
                        let data2 = {
                            w: 8,
                            h: 8,
                            currentTile: 0,
                            max: 64
                        };

                        data2.currentTile = Math.floor(Math.random() * data2.max);
                        this.bird3 = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data2, 24);
                        this.bird3.sprite.scale.set(0.8, 0.8, 0.8);

                        let rdm = Math.random() * 80;
                        this.bird3.sprite.position.set(rdm, rdm - 12, 5);

                        this.container.add(this.bird3.sprite);
                        this.speed3 = Math.random() * 0.02 + 0.01;

                        data2.currentTile = Math.floor(Math.random() * data2.max);
                        this.bird4 = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data2, 24);
                        this.bird4.sprite.scale.set(0.8, 0.8, 0.8);

                        rdm = Math.random() * 80;
                        this.bird4.sprite.position.set(rdm, rdm - 12, 5);

                        this.container.add(this.bird4.sprite);
                        this.speed4 = Math.random() * 0.02 + 0.01;
                    }
                }
            }
        } else if (city === "hainan") {
            for (let asset of textures) {
                if (asset.name === "decor_birds1") {
                    if (asset.file) {
                        let data = {
                            w: 10,
                            h: 7,
                            currentTile: 0,
                            max: 63
                        };

                        data.currentTile = Math.floor(Math.random() * data.max);
                        this.bird = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data, 12);
                        this.bird.sprite.scale.set(1.2, 1.2, 1);

                        let rdm = Math.random() * 40 + 20;
                        this.bird.sprite.position.set(rdm, rdm - 12, 5);

                        this.container.add(this.bird.sprite);
                        this.speed1 = Math.random() * 0.03 + 0.01;

                        data.currentTile = Math.floor(Math.random() * data.max);
                        this.bird2 = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data, 12);
                        this.bird2.sprite.scale.set(1.2, 1.2, 1);

                        rdm = Math.random() * 40 + 20;
                        this.bird2.sprite.position.set(rdm, rdm - 12, 5);

                        this.container.add(this.bird2.sprite);
                        this.speed2 = Math.random() * 0.03 + 0.01;

                        data.currentTile = Math.floor(Math.random() * data.max);
                        this.bird3 = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data, 12);
                        this.bird3.sprite.scale.set(0.8, 0.8, 0.8);

                        this.bird.sprite.position.set(rdm, rdm - 12, 5);

                        rdm = Math.random() * 80;
                        this.bird3.sprite.position.set(rdm, rdm - 12, 5);

                        this.container.add(this.bird3.sprite);
                        this.speed3 = Math.random() * 0.02 + 0.01;

                        data.currentTile = Math.floor(Math.random() * data.max);
                        this.bird4 = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data, 12);
                        this.bird4.sprite.scale.set(0.8, 0.8, 0.8);

                        rdm = Math.random() * 80;
                        this.bird4.sprite.position.set(rdm, rdm - 12, 5);

                        this.container.add(this.bird4.sprite);
                        this.speed4 = Math.random() * 0.02 + 0.01;
                    }
                }
            }
        } else if (city === "macau") {
            for (let asset of textures) {
                if (asset.name === "decor_birds1") {
                    if (asset.file) {
                        let data = {
                            w: 10,
                            h: 7,
                            currentTile: 0,
                            max: 63
                        };

                        data.currentTile = Math.floor(Math.random() * data.max);
                        this.bird = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data, 12);
                        this.bird.sprite.scale.set(1.2, 1.2, 1);

                        let rdm = Math.random() * 40 + 20;
                        this.bird.sprite.position.set(rdm, rdm - 12, 5);

                        this.container.add(this.bird.sprite);
                        this.speed1 = Math.random() * 0.03 + 0.01;

                        data.currentTile = Math.floor(Math.random() * data.max);
                        this.bird2 = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data, 12);
                        this.bird2.sprite.scale.set(1.2, 1.2, 1);

                        rdm = Math.random() * 40 + 20;
                        this.bird2.sprite.position.set(rdm, rdm - 12, 5);

                        this.container.add(this.bird2.sprite);
                        this.speed2 = Math.random() * 0.03 + 0.01;

                        data.currentTile = Math.floor(Math.random() * data.max);
                        this.bird3 = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data, 12);
                        this.bird3.sprite.scale.set(0.8, 0.8, 0.8);

                        this.bird.sprite.position.set(rdm, rdm - 12, 5);

                        rdm = Math.random() * 80;
                        this.bird3.sprite.position.set(rdm, rdm - 12, 5);

                        this.container.add(this.bird3.sprite);
                        this.speed3 = Math.random() * 0.02 + 0.01;

                        data.currentTile = Math.floor(Math.random() * data.max);
                        this.bird4 = new AnimatedSprite(this.fe?.syncList, asset.file.clone(), data, 12);
                        this.bird4.sprite.scale.set(0.8, 0.8, 0.8);

                        rdm = Math.random() * 80;
                        this.bird4.sprite.position.set(rdm, rdm - 12, 5);

                        this.container.add(this.bird4.sprite);
                        this.speed4 = Math.random() * 0.02 + 0.01;
                    }
                }
            }
        }
    }

    updateLocal = (args: update_input_type) => {
        if (this.bird && this.speed1) {
            if (this.bird.sprite.position.y < this.bird.sprite.position.x - 12 || this.bird.sprite.position.y > this.bird.sprite.position.x + 12) {
                let rdm = Math.random() * 80 - 80;
                this.bird.sprite.position.set(rdm, rdm - 12, 5);
                this.speed1 = Math.random() * 0.01 + 0.03;
            }
            this.bird.sprite.position.y += this.speed1 * 2;
            this.bird.sprite.position.x += Math.sin(Math.PI * this.bird.sprite.position.y / 16) / 32;
        }

        if (this.bird2 && this.speed2) {
            if (this.bird2.sprite.position.y < this.bird2.sprite.position.x - 12 || this.bird2.sprite.position.y > this.bird2.sprite.position.x + 12) {
                let rdm = Math.random() * 80 - 80;
                this.bird2.sprite.position.set(rdm, rdm - 12, 5);
                this.speed2 = Math.random() * 0.01 + 0.03;
            }
            this.bird2.sprite.position.y += this.speed2 * 2;
            this.bird2.sprite.position.x += Math.sin(Math.PI * this.bird2.sprite.position.y / 16) / 32;
        }

        if (this.bird3 && this.speed3) {
            if (this.bird3.sprite.position.y < this.bird3.sprite.position.x - 12 || this.bird3.sprite.position.y > this.bird3.sprite.position.x + 12) {
                let rdm = Math.random() * 80 - 80;
                this.bird3.sprite.position.set(rdm, rdm - 12, 5);
                this.speed3 = Math.random() * 0.01 + 0.03;
            }
            this.bird3.sprite.position.y += this.speed3 * 2;
            this.bird3.sprite.position.x += Math.sin(Math.PI * this.bird3.sprite.position.y / 16) / 32;
        }

        if (this.bird4 && this.speed4) {
            if (this.bird4.sprite.position.y < this.bird4.sprite.position.x - 12 || this.bird4.sprite.position.y > this.bird4.sprite.position.x + 12) {
                let rdm = Math.random() * 80 - 80;
                this.bird4.sprite.position.set(rdm, rdm - 12, 5);
                this.speed4 = Math.random() * 0.01 + 0.03;
            }
            this.bird4.sprite.position.y += this.speed4 * 2;
            this.bird4.sprite.position.x += Math.sin(Math.PI * this.bird4.sprite.position.y / 16) / 32;
        }
    }
}