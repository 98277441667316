//Map
import { FiktivLevel } from "../../engine/FiktivLevel";
//Objects
import { Box_cons_type, Box } from "../../objects/Box";
import { Actor } from "../../engine/Actor";

import { FiktivEngine } from "../../index";
import { Player } from "../../engine/Player";

import * as THREE from "three";

export class Sandbox implements FiktivLevel {
    actors: Map<string, Actor>;
    fe: FiktivEngine | undefined;

    initPos: THREE.Vector3;
    initQuat: THREE.Quaternion;
    onStart: (args: any) => void;
    onStop: (args: any) => void;

    ZERO_QUATERNION: THREE.Quaternion = new THREE.Quaternion(0, 0, 0, 1);
    ISO_QUATERNION: THREE.Quaternion = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0.96, 0, 0.79), Math.PI / 2)

    constructor(fe?: FiktivEngine) {
        this.actors = new Map<string, Actor>();
        this.fe = fe;
        this.initPos = new THREE.Vector3(0, 0, 3);
        this.initQuat = this.ZERO_QUATERNION;
        this.onStart = () => { };
        this.onStop = () => { };
    }

    //Should be something like PlayerState instead…
    winGame(p: Player): boolean {
        return false
    }

    looseGame(p: Player): boolean {
        if (p.pawn && p.pawn)
            return p.pawn.position.z < -20;
        else
            return false;
    }

    onLoose({p}: {p: Player, ammo: any}): void {
      console.warn("Puffyland | FE - You Loose !");
      p.move(this.initPos);
    }


    init(fe: FiktivEngine) {
      this.fe = fe;
      //Scene
      fe.scene.background = new THREE.Color(0xe1e1e1);
      //scene.fog = new THREE.Fog( 0xe1e1e1, 500, 3000);

      //Lighting
      var ambient = new THREE.AmbientLight(0xffffff, 1);
      fe.scene.add(ambient);

      var directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
      fe.scene.add(directionalLight);

      // ground
      let col = new THREE.Color(0x999999);
      var mesh = new THREE.Mesh(
        new THREE.PlaneBufferGeometry(50, 50),
        new THREE.MeshStandardMaterial({
          color: col,
          depthWrite: true,
          depthTest: true,
          metalness: 0,
        })
      );

      mesh.rotation.x = 0;
      mesh.receiveShadow = true;
      fe.scene.add(mesh);

      var grid = new THREE.GridHelper(50, 10, 0x000000, 0x000000);
      grid.rotation.x = Math.PI / 2;
      //Can be of types Material[] too… so opacity does not always exist
      (grid.material as THREE.Material).opacity = 0.2;
      (grid.material as THREE.Material).transparent = true;
      fe.scene.add(grid);

      //Origin
      /*var material = new THREE.LineBasicMaterial({ color: 0xff0000 });
        var geometry = new THREE.BufferGeometry();
        let vertices = new Float32Array([0, 0, 0, 10, 0, 0]);
        geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
        var xline = new THREE.Line(geometry, material);
        fe.scene.add(xline);

        material = new THREE.LineBasicMaterial({ color: 0x00ff00 });
        geometry = new THREE.BufferGeometry();
        vertices = new Float32Array([0, 0, 0, 0, 10, 0]);
        geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
        var yline = new THREE.Line(geometry, material);
        fe.scene.add(yline);

        material = new THREE.LineBasicMaterial({ color: 0x0000ff });
        geometry = new THREE.BufferGeometry();
        vertices = new Float32Array([0, 0, 0, 0, 0, 10]);
        geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
        var zline = new THREE.Line(geometry, material);
        fe.scene.add(zline);*/

      //Materials
      var blueMaterial = new THREE.MeshStandardMaterial({
        color: 0x2222ff,
        depthWrite: true,
        depthTest: false,
        metalness: 0.8,
        transparent: true,
        opacity: 0,
      });

      // var redMaterial = new THREE.MeshStandardMaterial({
      //   color: 0xff2222,
      //   depthWrite: true,
      //   depthTest: true,
      //   metalness: 0.8,
      // });

      var texture = new THREE.TextureLoader().load("ressources/checker.png");
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(4, 4);

      var ZERO_QUATERNION = new THREE.Quaternion(0, 0, 0, 1);

      //Ground (Transparent)
      this.actors.set(
        "ground",
        new Box({
          fe: fe,
          material: blueMaterial,
          pos: new THREE.Vector3(0, 0, -0.5),
          quat: ZERO_QUATERNION,
          w: 50,
          l: 50,
          h: 1,
          mass: 0,
          friction: 2,
        } as Box_cons_type)
      );

      let currentPlayer = fe.newPlayer("me", "0");

      if (currentPlayer.pawn) this.actors.set("player", currentPlayer.pawn);

      if (currentPlayer.pawn?.controller)
        fe.inputManager.setInputReceiver(currentPlayer.pawn.controller);
      if (fe.graphic_enabled) {
        fe.cameraOperator.setPlayer(currentPlayer);
      }
      console.log("Puffyland | FE - Sandbox loaded");
    }

    update() {
        console.log("Puffyland | FE - Sandbox Update");
    }

    replicate() {
        console.log("Puffyland | FE - [Sandbox] Replicate to peers !");
    }
}
