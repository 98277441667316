import * as THREE from "three";
import Ammo from "ammojs-typed";
import { FiktivEngine } from "../index";
/* import { Vector3 } from "three"; */
import { FiktivObject3D } from "../engine/Object";

export type Sphere_cons_type = {
  fe: FiktivEngine;
  material: THREE.Material;
  pos: THREE.Vector3;
  quat: THREE.Quaternion;
  r?: number;
  mass?: number;
  friction?: number;
  physic?: boolean;
  body?: Ammo.btRigidBody | undefined;
  speed?: number;
  feUpdatable?: boolean;
};

export class Sphere extends FiktivObject3D {
  private _material: THREE.Material;
  private _r: number;

  //physic stuff
  private _mass: number;
  private _friction: number;

  //Don't think it's good to do that ↓ FIXME
  private _fe: FiktivEngine;

  get fe(): FiktivEngine {
    return this._fe;
  }
  set fe(fe: FiktivEngine) {
    this._fe = fe;
  }

  get material(): THREE.Material {
    return this._material;
  }
  set material(material: THREE.Material) {
    this._material = material;
  }

  get r(): number {
    return this._r;
  }
  set r(r: number) {
    this._r = r;
  }

  get mass(): number {
    return this._mass;
  }
  set mass(mass: number) {
    this._mass = mass;
  }

  get friction(): number {
    return this._friction;
  }
  set friction(friction: number) {
    this._friction = friction;
  }

  constructor(
    {
      fe,
      material,
      pos,
      quat,
      r = 1,
      mass = 0,
      friction = 1,
      physic = true,
      body = undefined,
      speed = 0,
      feUpdatable = true,
    }: Sphere_cons_type
  ) {
    var shape = new THREE.SphereGeometry(r, 32, 32);
    let mesh = new THREE.Mesh(shape, material);
    mesh.position.copy(pos);
    if(quat)
      mesh.quaternion.copy(quat);

    //let body = undefined;

    if (physic) {
        let ammo = fe.ammo;
        var geometry = new ammo.btSphereShape(r);
        var transform = new ammo.btTransform();
        transform.setIdentity();
        transform.setOrigin(new ammo.btVector3(pos.x, pos.y, pos.z));
        transform.setRotation(
          new ammo.btQuaternion(quat.x, quat.y, quat.z, quat.w)
        );
        var motionState = new ammo.btDefaultMotionState(transform);

        var localInertia = new ammo.btVector3(0, 0, 0);
        geometry.calculateLocalInertia(mass, localInertia);

        var rbInfo = new ammo.btRigidBodyConstructionInfo(
          mass,
          motionState,
          geometry,
          localInertia
        );
        body = new ammo.btRigidBody(rbInfo);

        if (body) {
          body.setFriction(friction);
          body.setRestitution(0.9);
          //body.setDamping(1, 1);

          fe.bulletWorld.physicsWorld.addRigidBody(body);

          if (mass > 0) {
            body.setActivationState(fe.bulletWorld!.DISABLE_DEACTIVATION);
          }
        }
    }
    super(pos, quat, mesh, body, physic, speed);
    this.feUpdatable = feUpdatable

    this._material = material;
    this._r = r;
    this._mass = mass;
    this._friction = friction;

    let oldupdate = this.update
    this.update = (args: {
      timestep: number;
      auxTransform?: Ammo.btTransform;
    }) => {
      if (fe.bulletWorld)
        oldupdate({
          timestep: args.timestep,
          auxTransform: fe.bulletWorld.TRANSFORM_AUX,
        });
      else
        oldupdate({
          timestep: args.timestep
        });
    };

    //TODO should be done outside…
    this._fe = fe;
    if (this.mesh) this.fe.scene.add(this.mesh);
    if (this.feUpdatable) this.fe.syncList.push(this);
  }
}
