import * as THREE from "three";
import { FiktivEngine, update_input_type } from "../../../../fiktivengine_modules/fiktivengine-core/fiktiv";
import { Lendless } from "../../level/Lendless";

export class AnimatedMetroContainer {
    public container: THREE.Object3D;

    public textureBackground?: THREE.Texture;
    public materialBackground?: THREE.SpriteMaterial;
    public textureRame?: THREE.Texture;
    public materialRame?: THREE.SpriteMaterial;
    public textureForeground?: THREE.Texture;
    public materialForeground?: THREE.SpriteMaterial;

    public rame?: THREE.Sprite;

    constructor(
        fe?: FiktivEngine
    ) {
        this.container = new THREE.Object3D();
        let textures = (fe?.map as Lendless).preload.assetList;

        // Background
        for (let asset of textures) {
            if (asset.name === "decor_metro_background") {
                if (asset.file) {
                    this.materialBackground = new THREE.SpriteMaterial({
                        map: asset.file,
                        depthWrite: true,
                        depthTest: true,
                    });

                    let background = new THREE.Sprite(this.materialBackground);
                    background.position.set(-26.7, -26.7, -15);
                    background.scale.set(16, 16 / 0.187, 1);
                    this.container.add(background);
                }
            }
        }

        // Rame
        for (let asset of textures) {
            if (asset.name === "decor_metro_train") {
                if (asset.file) {
                    this.materialRame = new THREE.SpriteMaterial({
                        map: asset.file,
                        depthWrite: true,
                        depthTest: true,
                    });

                    this.rame = new THREE.Sprite(this.materialRame);
                    this.rame.position.set(-21.4, -21.4, -10);
                    this.rame.scale.set(16, 16 / 0.187/*1.19*/, 1);
                    this.container.add(this.rame);
                }
            }
        }

        // Foreground
        for (let asset of textures) {
            if (asset.name === "decor_metro_foreground") {
                if (asset.file) {
                    this.materialForeground = new THREE.SpriteMaterial({
                        map: asset.file,
                        depthWrite: true,
                        depthTest: true,
                    });

                    let foreground = new THREE.Sprite(this.materialForeground);
        foreground.position.set(-16.2, -16.2, -5);
        foreground.scale.set(16, 16 / 0.187, 1);
        this.container.add(foreground);
                }
            }
        }
    }

    updateLocal = (args: update_input_type) => {
        if (this.rame && this.rame.position) {
            if (this.rame.position.x > -5) {
                this.rame.position.x = -35;
            }
            this.rame.position.x += 0.1;
        }
    }
}