// Modules
import * as THREE from "three";
import { Tiles } from "..";
import { FiktivEngine, FiktivObject3D, update_input_type } from "../../../../fiktivengine_modules/fiktivengine-core";
import { Lendless } from "../../level/Lendless";
import { PHero } from "../../pawn/PHero";
import { AnimatedSprite } from "../utils/AnimatedSprite";

export class Pickup extends FiktivObject3D {

    public fe: FiktivEngine;
    public tiles: Tiles;
    public distance: number;
    public asset: string;

    //public meshShadow: THREE.Mesh;

    public taken: boolean;
    public takenScore: boolean;
    public hiding: boolean;
    public opacity: number;
    public opacityShadow: number;
    public scale: number;
    //public dispTexture?: AnimatedSprite;
    public depop?: AnimatedSprite;

    public sprite?: THREE.Sprite;
    public spriteShadow?: THREE.Sprite;
    public spriteHalo?: THREE.Sprite;

    public value: number;
    public soundName: string;

    constructor(tiles: Tiles, fe: FiktivEngine, pos: THREE.Vector3, asset?: string, isVoid?: boolean) {
        super(undefined, undefined);

        this.tiles = tiles;

        this.taken = false;
        this.takenScore = false;
        this.hiding = false;
        this.opacity = 1;
        this.opacityShadow = 0.2;
        this.scale = 1.6;

        this.distance = 10

        this.asset = asset || "macaron_R";

        this.value = 10;
        this.soundName = 'SD_item'
        if (this.asset.indexOf('1765') > 0) {
            this.soundName = 'SD_bras'
            this.value = 50;
        }

        this.fe = fe
        this.setPosition({ position: pos })

        // Sprite
        let pool = (this.fe?.map as Lendless).poolManager;
        let elem = null;
        if (pool) {
            pool.createPool(this.asset);
            elem = pool.changeStatus(this.asset, undefined, true);
        }
        if (elem) {
            this.sprite = elem;
            if (this.sprite) {
                this.sprite.scale.set(this.scale, this.scale, this.scale);
                this.sprite.name = this.asset;
                this.sprite.center = new THREE.Vector2(0.5, 1);
                this.sprite.position.set(0, 0, -1);
                this.sprite.material.opacity = 1;
            }
        } else {
            let assets = (this.fe.map as Lendless).preload.assetList;
            for (let texture of assets) {
                if (texture.name === this.asset) {
                    let ma = new THREE.SpriteMaterial({
                        map: texture.file,
                        opacity: 1,
                    });

                    this.sprite = new THREE.Sprite(ma);
                    this.sprite.name = this.asset;
                    this.sprite.scale.set(this.scale, this.scale, this.scale);
                    this.sprite.center = new THREE.Vector2(0.5, 1);
                    this.sprite.position.set(0, 0, -1);
                    this.sprite.material.opacity = 1;
                    pool?.pushInPool(this.asset, this.sprite, false);
                    break;
                }
            }
        }
        /*if (this.sprite) {
            this.sprite.name = this.asset;
            this.sprite.scale.set(this.scale, this.scale, this.scale);
            this.sprite.center = new THREE.Vector2(0.5, 1);
            this.sprite.position.set(0, 0, -1);
            this.sprite.material.opacity = 1;
        }*/

        // Halo
        let elemHalo = null;
        if (pool) {
            pool.createPool(this.asset + "_halo");
            elemHalo = pool.changeStatus(this.asset + "_halo", undefined, true);
        }
        if (elemHalo) {
            this.spriteHalo = elemHalo;
            if (this.spriteHalo) {
                this.spriteHalo.scale.set(this.scale, this.scale, this.scale);
                this.spriteHalo.center = new THREE.Vector2(0.5, 1);
                this.spriteHalo.position.set(0, 0, -1.01);
                this.spriteHalo.material.opacity = this.opacity;
            }
        } else {
            let ma = new THREE.SpriteMaterial({});
            let textures = (this.fe?.map as Lendless).preload.assetList;

            for (let asset of textures) {
                if (asset.name === 'halo') {
                    if (asset.file) {
                        ma = new THREE.SpriteMaterial({
                            map: asset.file
                        });

                        this.spriteHalo = new THREE.Sprite(ma);
                        this.spriteHalo.name = this.asset + "_halo";
                        this.spriteHalo.material.opacity = this.opacity;
                        pool?.pushInPool(this.asset + "_halo", this.spriteHalo, false);
                        break;
                    }
                }
            }
        }
        if (this.spriteHalo) {
            this.spriteHalo.scale.set(this.scale, this.scale, this.scale);
            this.spriteHalo.center = new THREE.Vector2(0.5, 1);
            this.spriteHalo.position.set(0, 0, -1.01);
            this.spriteHalo.material.opacity = this.opacity;
        }

        // Sprite Shadow
        let elemShadow = null;
        if (pool) {
            pool.createPool(this.asset + "_shadow");
            elemShadow = pool.changeStatus(this.asset + "_shadow", undefined, true);
        }
        if (elemShadow) {
            this.spriteShadow = elemShadow;
        } else {
            let ma = new THREE.SpriteMaterial({});
            let textures = (this.fe?.map as Lendless).preload.assetList;

            for (let asset of textures) {
                if (asset.name === this.asset) {
                    if (asset.file) {
                        ma = new THREE.SpriteMaterial({
                            map: asset.file,
                            opacity: 0.2,
                            color: 0x2C2199,
                            transparent: true
                        });

                        this.spriteShadow = new THREE.Sprite(ma);
                        this.spriteShadow.name = this.asset + "_shadow";
                        this.spriteShadow.material.opacity = this.opacityShadow;
                        pool?.pushInPool(this.asset + "_shadow", this.spriteShadow, false);
                        break;
                    }
                }
            }
        }
        if (this.spriteShadow) {
            this.spriteShadow.scale.set(0.6, 0.6, 0.6);
            this.spriteShadow.center = new THREE.Vector2(0.5, 1);
            this.spriteShadow.position.set(0, 0, -3.2);
            this.spriteShadow.material.opacity = this.opacityShadow;
        }

        if (this.mesh && this.sprite && this.spriteHalo) {
            this.mesh.add(this.sprite);
            this.mesh.add(this.spriteHalo);
            if (!isVoid && this.spriteShadow) {
                this.mesh.add(this.spriteShadow);
            }
            this.fe.scene.add(this.mesh);
        }
    }

    unload = () => {
        console.log("unload pickup !")
        let pool = (this.fe?.map as Lendless).poolManager;

        if (this.depop) {
            let pool = (this.fe?.map as Lendless).poolManager;
            if (pool) {
                pool.createPool("depop");
                pool.changeStatus("depop", this.depop, false);
            }
            this.fe.scene.remove(this.depop.sprite);
        }

        this.fe.scene.remove(this.mesh);
        if (this.spriteShadow) {
            this.fe.scene.remove(this.spriteShadow);
            this.mesh.remove(this.spriteShadow);
            if (pool) {
                pool.createPool(this.spriteShadow.name);
                pool.changeStatus(this.spriteShadow.name, this.spriteShadow, true);
            }
        }
        if (this.spriteHalo) {
            this.fe.scene.remove(this.spriteHalo);
            this.mesh.remove(this.spriteHalo);
            if (pool) {
                pool.createPool(this.spriteHalo.name);
                pool.changeStatus(this.spriteHalo.name, this.spriteHalo, true);
            }
        }
        if (this.sprite) {
            this.fe.scene.remove(this.sprite);
            this.mesh.remove(this.sprite);
            if (pool) {
                pool.createPool(this.sprite.name);
                pool.changeStatus(this.sprite.name, this.sprite, true);
            }
        }
    }

    updateLocal = (args: update_input_type) => {
        let pawn = this.fe?.players.get("me")?.pawn as PHero

        if (pawn) {
            if (this.hiding) {
                this.opacity = this.opacity * 0.9;
                if (this.spriteShadow) {
                    this.spriteShadow.material.opacity = 0;
                }
                if (this.spriteHalo) {
                    this.spriteHalo.material.opacity = 0;
                }
                if (this.sprite) {
                    this.sprite.material.opacity = this.opacity;
                }
                this.mesh.position.z += 0.05;

                if (this.opacity < 0.2 && this.opacity > 0) {
                    this.opacity = 0;

                    this.unload();
                    return;
                }
                return;
            }

            if (this.mesh && this.distance && pawn.pos) {

                if (pawn.pos.distanceTo(this.mesh.position) < 3 && pawn.jumpOk < 2) {

                    //(this.fe.map as Lendless).replay.push({ time: Date.now() - (this.fe.map as Lendless).gameClock, action: "pickup" });
                    let scoreMod: any = (this.fe.map as Lendless).scoreMod
                    if (scoreMod && !this.takenScore) {
                        scoreMod.scorePickUp += this.value;
                        scoreMod.add(this.value, pawn);
                        this.takenScore = true;

                        let pickupList = (this.fe.map as Lendless).pickupList;
                        if (pickupList) {
                            let tmp = pickupList.get(this.asset);
                            pickupList.set(this.asset, tmp ? tmp + 1 : 1);
                        }
                    }

                    let sound = (this.fe.map as Lendless).sounddesign?.get(this.soundName);
                    if (sound) {
                        sound.play();
                    }

                    // Sprite Shadow
                    let pool = (this.fe?.map as Lendless).poolManager;
                    if (pool) {
                        pool.createPool("depop");
                        this.depop = pool.changeStatus("depop", undefined, true);
                    }
                    if (this.mesh) {
                        if (!this.depop) {
                            for (let texture of (this.fe.map as Lendless).preload.assetList) {
                                if (texture.name === 'depopSprite') {
                                    for (let i = 0; i < 2; i++) {
                                        let data = {
                                            w: 8,
                                            h: 5,
                                            currentTile: 0,
                                            max: 40
                                        };
                                        this.depop = new AnimatedSprite(this.fe?.syncList, texture.file.clone(), data, 12, 1);

                                        this.depop.name = "depop";
                                        let pool = (this.fe?.map as Lendless).poolManager;
                                        pool?.pushInPool("depop", this.depop, false);

                                        this.depop.sprite.position.set(this.mesh.position.x, this.mesh.position.y, this.mesh.position.z - 1);
                                        this.depop.sprite.scale.set(5, 5, 1);

                                        console.log("reset")
                                        this.depop.reset();
                                        this.fe.scene.add(this.depop.sprite);
                                        this.hiding = true;
                                    }
                                }
                            }
                        } else {
                            this.depop.sprite.position.set(this.mesh.position.x, this.mesh.position.y, this.mesh.position.z - 1);
                            this.depop.sprite.scale.set(5, 5, 1);

                            this.depop.reset();
                            this.fe.scene.add(this.depop.sprite);
                            this.hiding = true;
                        }
                    }
                }

                if (pawn.pos.distanceTo(this.mesh.position) > 20 && this.mesh.position.x > pawn.pos.x) {
                    this.unload();
                }
            }

        }

    }
}